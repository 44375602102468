import { Injectable } from "@angular/core";
import { BaseDtoSearchService, IBaseDto } from "./base-dto.service";
import { CompanyClient, SearchCompaniesBasicDataParameters } from "./reporting-api-proxies";

@Injectable({
    providedIn: "root"
})
export class ReportingCompanySearchService extends BaseDtoSearchService {
    private client: CompanyClient;
    constructor() {
        super();
        this.client = new CompanyClient();
    }

    async search(filterText: string): Promise<IBaseDto[]> {
        return await this.client.companySearchBasicData({ filterText } as SearchCompaniesBasicDataParameters);
    }

    override getById(id: number): Promise<IBaseDto> {
        throw new Error("Method not implemented.");
    }

    async getByIds(companyIds: number[]): Promise<IBaseDto[]> {
        return await this.client.companySearchBasicData({
            companyIds
        } as SearchCompaniesBasicDataParameters);
    }
}
