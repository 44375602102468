import { Injectable } from "@angular/core";
import { IUserClient, SearchUserDto, SearchUsersParameters, UserClient, UserDto } from "./reporting-api-proxies";
import { BaseDtoSearchService, IBaseDto } from "./base-dto.service";

@Injectable({
    providedIn: "root"
})
export class ReportingUserService extends BaseDtoSearchService {
    private client: IUserClient;
    constructor() {
        super();
        this.client = new UserClient();
    }

    async search(filterText: string): Promise<IBaseDto[]> {
        const body = {
            filterText
        } as SearchUsersParameters;
        const response = await this.client.userSearchUsers(body);

        return response.map(user => ({
            id: user.id,
            name: user.fullName
        }));
    }

    async searchUsers(body: SearchUsersParameters): Promise<SearchUserDto[]> {
        return await this.client.userSearchUsers(body);
    }

    async getById(id: number): Promise<UserDto> {
        return await this.client.userGetById(id);
    }

    async getByIds(userIds: number[]): Promise<IBaseDto[]> {
        const response = await this.client.userSearchUsers({ userIds } as SearchUsersParameters);
        return response.map(user => ({
            id: user.id,
            name: user.fullName
        }));
    }
}
