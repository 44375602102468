import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss']
})
export class SliderComponent {
    selected: any;
    @Input() min: number;
    @Input() max: number;
    @Input() name: string;
    @Input() label: string;

    @ViewChild('fromSlider', { static: false }) fromSlider?: HTMLElement;
    @ViewChild('toSlider', { static: false }) toSlider?: HTMLElement;

    constructor() {
        this.min = 0;
        this.max = 100;
        this.name = '';
        this.label = '';
    }

    onChange(e: any) {
        this.selected = e.target.value;
    }

    ngAfterViewInit() {
        this.fillSlider(this.fromSlider, this.toSlider, '#C6C6C6', '#25daa5', this.toSlider);
        this.setToggleAccessible(this.toSlider);
    }

    controlFromSlider(fromSlider: any, toSlider: any, ) {
        const [from, to] = this.getParsed(fromSlider, toSlider);
        this.fillSlider(fromSlider, toSlider, '#C6C6C6', '#25daa5', toSlider);
        if (from > to) {
            fromSlider.value = to;
        } else {
            fromSlider.value = from;

        }
    }

    controlToSlider(fromSlider: any, toSlider: any, ) {
        const [from, to] = this.getParsed(fromSlider, toSlider);

        this.fillSlider(fromSlider, toSlider, '#C6C6C6', '#25daa5', toSlider);
        this.setToggleAccessible(toSlider);
        if (from <= to) {
            toSlider.value = to;
        } else {
            toSlider.value = from;
        }
    }

    getParsed(currentFrom: any, currentTo: any) {
      const from = parseInt(currentFrom.value, 10);
      const to = parseInt(currentTo.value, 10);
      return [from, to];
    }

    fillSlider(from: any, to: any, sliderColor: any, rangeColor: any, controlSlider: any) {
        const rangeDistance = to.max-to.min;
        const fromPosition = from.value - to.min;
        const toPosition = to.value - to.min;
        if (controlSlider.style) {
            controlSlider.style.background = `linear-gradient(
                to right,
                ${sliderColor} 0%,
                ${sliderColor} ${(fromPosition)/(rangeDistance)*100}%,
                ${rangeColor} ${((fromPosition)/(rangeDistance))*100}%,
                ${rangeColor} ${(toPosition)/(rangeDistance)*100}%,
                ${sliderColor} ${(toPosition)/(rangeDistance)*100}%,
                ${sliderColor} 100%)`;
        }
    }

    setToggleAccessible(currentTarget: any) {
        if (this.toSlider && this.toSlider.style && Number(currentTarget.value) <= 0 ) {
            this.toSlider.style.zIndex = "2";
        } else if (this.toSlider && this.toSlider.style) {
            this.toSlider.style.zIndex = "0";
        }
    }
}
