import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import {
    CustomReportView,
    ReportTemplateView,
    SearchReportTemplateParameters
} from "projects/app/src/app/services/http/clients/reporting-app/reporting-api-proxies";
import { IListElement } from "../../../shared/titled-list-group/list-element/list-element.component";
import { IReportsSearchService } from "projects/app/src/app/services/http/clients/reporting-app/custom-report/custom-report-search.service";

interface IFilterValueReportsList {
    searchText?: string;
    onlyFavorites?: boolean;
}

interface IReportsList {
    title?: string;
    items: IListElement[];
}

@Component({
    selector: "app-reports-list",
    templateUrl: "./reports-list.component.html",
    styleUrls: ["./reports-list.component.scss"]
})
export class ReportsListComponent implements OnInit {
    @Input()
    service!: IReportsSearchService<CustomReportView | ReportTemplateView>;

    @Input()
    name?: string;

    @Output()
    tabCounterChange: EventEmitter<number>;

    filterValue: IFilterValueReportsList;

    groupedList: IReportsList[];

    searchConfig!: SearchReportTemplateParameters;

    constructor(private readonly awaiter: AwaiterService) {
        this.tabCounterChange = new EventEmitter<number>();
        this.groupedList = [];

        this.filterValue = {
            onlyFavorites: false,
            searchText: ""
        };
    }

    async ngOnInit(): Promise<void> {
        await this.loadData();
    }

    async loadData(): Promise<void> {
        this.buildSearchConfig();
        await this.awaiter.awaitAction("Loading Reports", async () => {
            if (this.service) {
                const reportsList = await this.service.searchReports(this.searchConfig);
                this.groupItems(reportsList);
                this.tabCounterChange.emit(reportsList.length);
            }
        });
    }

    groupItems(data: (CustomReportView | ReportTemplateView)[]): void {
        const groups: { [key: string]: IReportsList } = {};

        data.forEach(item => {
            const groupId = item.reportTemplateGroupId ?? 0;
            const groupName = item.reportTemplateGroupName ?? "No Group Assigned";

            if (!groups[groupId]) {
                groups[groupId] = {
                    title: groupName,
                    items: []
                };
            }

            groups[groupId].items.push({
                id: item.id,
                title: item.name,
                subtitle: (item as CustomReportView).reportTemplateName,
                link: this.getLink(item),
                isFavorite: item.isFavorite ?? false
            });
        });

        this.groupedList = Object.values(groups);
    }

    getLink(item: ReportTemplateView | CustomReportView) {
        return this.name !== "Templates" ? `${item.reportTemplateUrl}/${item.id}` : item.reportTemplateUrl;
    }

    async onFiltersChanged(): Promise<void> {
        await this.loadData();
    }

    async onToggleFavorite(item: IListElement) {
        await this.awaiter.awaitAction("Updating Favorite", async () => {
            const response = await this.service.toggleFavorite(item.id);
            item.isFavorite = response;
        });
    }

    private buildSearchConfig(): void {
        this.searchConfig = {
            filterText: this.filterValue.searchText,
            onlyFavorites: this.filterValue.onlyFavorites
        } as SearchReportTemplateParameters;
    }
}
