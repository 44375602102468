import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "lib-slide-toggle",
    templateUrl: "./slide-toggle.component.html",
    styleUrls: ["./slide-toggle.component.scss"]
})
export class SlideToggleComponent {
    @Input()
    label?: string;

    @Input()
    checked?: boolean;

    @Input()
    slideToggleId?: string;

    @Output()
    checkedChange: EventEmitter<boolean> = new EventEmitter();

    constructor() {
        this.checked = false;
    }

    onToggleChange(): void {
        this.checked = !this.checked;
        this.checkedChange.emit(this.checked);
    }

    onKeyDown(event: KeyboardEvent): void {
        if (event.key === "Enter") {
            this.onToggleChange();
        }
    }
}
