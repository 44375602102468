import { Injectable } from "@angular/core";
import { BaseEstimateClient, IBaseEstimateClient, QuickSearchEstimatesResultDto } from "./reporting-api-proxies";
import { BaseDtoSearchService, IBaseDto } from "./base-dto.service";

@Injectable({
    providedIn: "root"
})
export class BaseEstimatesService extends BaseDtoSearchService {
    private client: IBaseEstimateClient;
    constructor() {
        super();
        this.client = new BaseEstimateClient();
    }

    async search(filterText: string): Promise<QuickSearchEstimatesResultDto[]> {
        const body = {
            filterText: filterText
        };
        return await this.client.baseEstimateQuickSearch(body);
    }

    override getById(id: number): Promise<IBaseDto> {
        throw new Error("Method not implemented.");
    }

    async getByIds(ids: number[]): Promise<QuickSearchEstimatesResultDto[]> {
        return await this.client.baseEstimateGetByIds(ids);
    }
}
