import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { Account } from "projects/app/src/app/models/Account";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { AccountView } from "projects/app/src/app/services/http/clients/api-proxies";
import { AccountService } from "projects/app/src/app/services/http/clients/account.service";
import { ModalComponentBase } from "projects/ngx-lib/src/lib/classes/modal-component-base";
import { EditorItemComponent } from "projects/ngx-lib/src/lib/components/editor-item/editor-item.component";
import { ValidatorType } from "projects/ngx-lib/src/lib/enums/validator-type";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { SnackbarNotificationService } from "projects/ngx-lib/src/lib/services/snackbar-notification.service";

@Component({
    selector: "app-modal-add-account",
    templateUrl: "./modal-add-account.component.html",
    styleUrls: ["./modal-add-account.component.scss"]
})
export class ModalAddAccountComponent extends ModalComponentBase<undefined, AccountView> implements OnInit {
    factory: AppEditorFactory;

    configItems: IEditorItemConfig[];

    account: Account;

    isReadOnly: boolean;

    @ViewChildren("editorItem")
    editors?: QueryList<EditorItemComponent>;

    isLoading: boolean;

    constructor(
        private readonly accountService: AccountService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly awaiter: AwaiterService
    ) {
        super();
        this.factory = new AppEditorFactory();
        this.account = new Account();
        this.isReadOnly = false;
        this.configItems = [];
        this.isLoading = false;
    }

    ngOnInit(): void {
        this.configItems = [
            {
                label: "Name",
                componentId: EditorType.textInput,
                fieldName: "name",
                bindContext: this.account,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.required }, { type: ValidatorType.string, args: { maxLength: 100 } }],
                additionalInfo: {
                    shouldTrimSpaces: true
                }
            },
            {
                label: "Website",
                componentId: EditorType.link,
                fieldName: "webSite",
                bindContext: this.account,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.string, args: { maxLength: 100 } }]
            },
            {
                label: "Linkedin",
                componentId: EditorType.link,
                fieldName: "linkedin",
                bindContext: this.account,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.string, args: { maxLength: 100 } }]
            },
            {
                label: "Type",
                componentId: EditorType.companyType,
                fieldName: "accountTypeId",
                bindContext: this.account,
                isReadOnly: this.isReadOnly
            },
            {
                label: "Legal Status",
                componentId: EditorType.legalStatus,
                fieldName: "legalStatusId",
                bindContext: this.account,
                isReadOnly: this.isReadOnly
            },
            {
                label: "Markets",
                componentId: EditorType.entity,
                fieldName: "accountMarkets",
                bindContext: this.account,
                entityId: "accountMarket",
                isReadOnly: this.isReadOnly
            }
        ];
    }

    private validate(): boolean {
        return this.editors?.map(editorItem => editorItem.validate()).every(editorItem => editorItem) ?? false;
    }

    async saveAccount(): Promise<void> {
        if (!this.validate()) return;

        await this.awaiter.awaitAction(
            "Saving Account...",
            async () => {
                const response = await this.accountService.save(this.account);
                if (response) this.snackbarNotificationService.success("Account created successfully.");
                this.close(response);
            },
            loading => (this.isLoading = loading)
        );
    }
}
