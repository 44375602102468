<lib-generic-type-ahead-dropdown
    [(selectedItem)]="selectedItem"
    (selectedItemChange)="onSelectedItem()"
    placeholder="Add Item"
    [(text)]="searchText"
    [source]="items"
    (textChange)="onTextChanged()"
    [config]="{
        defaultText: 'Add Item',
        itemDisplayKey: 'name'
    }"
>
</lib-generic-type-ahead-dropdown>