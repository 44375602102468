import { Injectable } from "@angular/core";
import { IOrganizationClient, OrganizationClient, OrganizationDto } from "./reporting-api-proxies";
import { BaseDtoService, IBaseDto } from "./base-dto.service";

@Injectable({
    providedIn: "root"
})
export class OrganizationService extends BaseDtoService {
    private client: IOrganizationClient;
    constructor() {
        super();
        this.client = new OrganizationClient();
    }

    async getAll(): Promise<OrganizationDto[]> {
        return await this.client.organizationGetForCurrentUser();
    }

    override getByIds(ids: number[]): Promise<IBaseDto[]> {
        throw new Error("Method not implemented.");
    }

    async getManagedOrganizations(): Promise<OrganizationDto[]> {
        return await this.client.organizationGetManagedOrganizationsForUser();
    }
}
