import { Component, ViewChild } from "@angular/core";
import {
    CustomReportSearchService,
    IReportsSearchService
} from "projects/app/src/app/services/http/clients/reporting-app/custom-report/custom-report-search.service";
import { TabCounterType } from "projects/app/src/app/services/tab-counter.service";
import { ReportTemplateService } from "projects/app/src/app/services/http/clients/reporting-app/report-template.service";
import { TabViewComponent } from "projects/ngx-lib/src/lib/components/tab-view/tab-view.component";
import { CustomReportView, ReportTemplateView } from "projects/app/src/app/services/http/clients/reporting-app/reporting-api-proxies";
import { CatalogReportSearchService } from "projects/app/src/app/services/http/clients/reporting-app/custom-report/catalog-report-search.service";

type IReportsTabConfig<IReportsSearchService> = {
    name: string;
    service: IReportsSearchService;
};

@Component({
    selector: "app-reports-main",
    templateUrl: "./reports-main.component.html",
    styleUrls: ["./reports-main.component.scss"]
})
export class ReportsMainComponent {
    @ViewChild(TabViewComponent)
    tabControl!: TabViewComponent;

    currentTab: string;

    tabConfig: IReportsTabConfig<IReportsSearchService<CustomReportView | ReportTemplateView>>[];

    tabsCounters: TabCounterType = {
        User: 0,
        Catalog: 0,
        Templates: 0
    };

    constructor(
        private readonly customReportSearchService: CustomReportSearchService,
        private readonly catalogReportSearchService: CatalogReportSearchService,
        private readonly reportTemplateService: ReportTemplateService
    ) {
        this.currentTab = "User";

        this.tabConfig = [
            {
                name: "User",
                service: this.customReportSearchService
            },
            {
                name: "Catalog",
                service: this.catalogReportSearchService
            },
            {
                name: "Templates",
                service: this.reportTemplateService
            }
        ];
    }

    async updateCounters(count: number, tabName: string): Promise<void> {
        this.tabsCounters[tabName] = count;
    }
}
