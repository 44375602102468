import { Component } from "@angular/core";
import { MarketService } from "projects/app/src/app/services/http/clients/reporting-app/market.service";
import { MultipleDropdownValuesEditorComponent } from "../multiple-dropdown-values-editor/multiple-dropdown-values-editor.component";
import { ReportsService } from "projects/app/src/app/services/reports.service";

@Component({
    selector: "app-reporting-market-editor",
    templateUrl: "../multiple-dropdown-values-editor/multiple-dropdown-values-editor.component.html",
    styleUrls: ["../multiple-dropdown-values-editor/multiple-dropdown-values-editor.component.scss"]
})
export class ReportingMarketEditorComponent extends MultipleDropdownValuesEditorComponent {
    constructor(
        private readonly reportingMarketService: MarketService,
        reportsService: ReportsService
    ) {
        super(reportingMarketService, reportsService);
        this.defaultText = "Select Markets";
    }
}
