import { Injectable } from "@angular/core";
import {
    BoxResultType,
    BuildingClient,
    GetBuildingLineChartValuesResultType,
    GetBuildingValuesParameters,
    GetBuildingColumnChartValuesResultType,
    GetBuildingMetricsValuesResultType,
    GetBuildingValuesResultType,
    CustomReportBuildingView,
    SharedReportDto
} from "./reporting-api-proxies";
import { CustomReportServiceBase } from "./custom-report/custom-report-base.service";

@Injectable({
    providedIn: "root"
})
export class ReportingBuildingService extends CustomReportServiceBase<BuildingClient, CustomReportBuildingView> {
    constructor() {
        super(new BuildingClient());
    }

    async getBuildingBoxesValues(parameters: GetBuildingValuesParameters): Promise<BoxResultType[]> {
        return await this.client.buildingGetBuildingBoxesValues(parameters);
    }

    async getBuildingColumnChartValues(parameters: GetBuildingValuesParameters): Promise<GetBuildingColumnChartValuesResultType[]> {
        return await this.client.buildingGetBuildingColumnChartValues(parameters);
    }

    async getBuildingLineChartValues(parameters: GetBuildingValuesParameters): Promise<GetBuildingLineChartValuesResultType[]> {
        return await this.client.buildingGetBuildingLineChartValues(parameters);
    }

    async getBuildingMetricsValues(parameters: GetBuildingValuesParameters): Promise<GetBuildingMetricsValuesResultType> {
        return await this.client.buildingGetBuildingMetricsValues(parameters);
    }

    async getBuildingValues(parameters: GetBuildingValuesParameters): Promise<GetBuildingValuesResultType[]> {
        return await this.client.buildingGetBuildingValues(parameters);
    }
    override async saveCustomReport(body: CustomReportBuildingView): Promise<CustomReportBuildingView> {
        if (body.isPublic)
            return await this.client.buildingSaveCatalogReport(body);
        else
            return await this.client.buildingSaveCustomReport(body);
    }


    override async getCustomReport(id: number): Promise<CustomReportBuildingView> {
        return await this.client.buildingGetCustomReport(id);
    }

    override async shareReport(body: CustomReportBuildingView): Promise<SharedReportDto> {
        return await this.client.buildingCreateSharedReport(body);
    }

    override async getSharedReport(id: string): Promise<CustomReportBuildingView> {
        return await this.client.buildingGetSharedReport(id);
    }
}
