import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from "@angular/core";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { ObjectExtensionsService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { CustomFieldsItemComponent } from "../../../../shared/custom-fields-item/custom-fields-item.component";
import { ICustomizationFilters, ICustomizationFiltersOptions } from "../customization-filters/customization-filters.component";
import { Condition } from "projects/app/src/app/models/enums/Condition";
import { CustomFieldsFilterType } from "projects/app/src/app/models/enums/CustomFieldsFilterType";
import { ISearchCustomInfoEntityParameters } from "projects/app/src/app/services/http/clients/search-custom-info-entity-parameters";
import { CustomizableEntityService } from "projects/app/src/app/services/http/clients/customizable-entity-service";
import { ICustomInfoEntity } from "projects/app/src/app/services/http/clients/custom-info-entity";
import { ICustomizationItems } from "../customization.component";
import { EntityServiceFactory } from "projects/app/src/app/factories/entity-service-factory/entity-service-factory";

@Component({
    selector: "app-customization-custom-info",
    templateUrl: "./customization-custom-info.component.html",
    styleUrls: ["./customization-custom-info.component.scss"]
})
export class CustomizationCustomInfoComponent implements OnInit, OnChanges {
    @Input()
    config?: ICustomizationItems;

    @Input()
    customInfo?: ICustomInfoEntity[];

    @Output()
    customInfoChange: EventEmitter<ICustomInfoEntity[]>;

    @Input()
    customInfoInitialState?: ICustomInfoEntity[];

    @Output()
    customInfoInitialStateChange: EventEmitter<ICustomInfoEntity[]>;

    @Input()
    filterOptions?: ICustomizationFiltersOptions;

    @Input()
    isModelEqual?: boolean;

    showActions?: boolean;

    filters: ICustomizationFilters;

    searchConfig: ISearchCustomInfoEntityParameters;

    factory: AppEditorFactory;

    @ViewChildren("customInfoItem")
    customInfoItem?: QueryList<CustomFieldsItemComponent>;

    archiveStatus: Condition;

    reservedFilterType: CustomFieldsFilterType;

    service?: CustomizableEntityService;

    constructor(
        private readonly objectExtensionService: ObjectExtensionsService,
        private readonly awaiter: AwaiterService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly entityServiceFactory: EntityServiceFactory
    ) {
        this.searchConfig = {
            isActive: true
        } as ISearchCustomInfoEntityParameters;
        this.filters = {};
        this.customInfo = [];
        this.customInfoChange = new EventEmitter<ICustomInfoEntity[]>();
        this.customInfoInitialState = [];
        this.customInfoInitialStateChange = new EventEmitter<ICustomInfoEntity[]>();
        this.factory = new AppEditorFactory();
        this.archiveStatus = Condition.Archived;
        this.reservedFilterType = CustomFieldsFilterType.Reserved;
    }

    ngOnInit(): void {
        this.service = this.entityServiceFactory.getService(this.config?.id ?? 0);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["isModelEqual"]) {
            this.showActions = this.isModelEqual;
        }
    }

    onAddNewField(): void {
        if (this.filters.condition?.id === Condition.Archived || this.filters.type?.id === CustomFieldsFilterType.Reserved) return;

        this.customInfo?.unshift({
            id: 0,
            isActive: true
        } as ICustomInfoEntity);
        this.customInfoChange.emit(this.customInfo);
    }

    async onToggleCondition(item: ICustomInfoEntity, index: number, archive: boolean): Promise<void> {
        const action = archive ? "Archiving" : "Restoring";
        const message = archive ? `${item.name ?? "Item"} successfully archived.` : `${item.name ?? "Item"} successfully restored.`;
        const serviceAction = archive ? this.service?.deactivate(item.id) : this.service?.activate(item.id);

        await this.awaiter.awaitAction(`${action} ${this.config?.title ?? "Item"}`, async () => {
            if (this.customInfo && index > -1 && this.customInfo[index]) {
                await serviceAction;
                item.isActive = !archive;
                if ((archive && this.filters.condition?.id === Condition.Active) || (!archive && this.filters.condition?.id === Condition.Archived))
                    this.customInfo.splice(index, 1);
                this.snackbarNotificationService.success(message);
            }
        });
    }

    onRemove(index: number): void {
        if (this.customInfo && index > -1 && this.customInfo[index]) {
            this.customInfo.splice(index, 1);
            this.customInfoChange.emit(this.customInfo);
        }
    }

    onItemsChange(): void {
        this.customInfoChange.emit(this.customInfo);
    }

    async onFiltersChange(): Promise<void> {
        await this.refreshListView();
    }

    private async refreshListView(): Promise<void> {
        this.buildSearchConfig();

        await this.awaiter.awaitAction("Getting Custom Info", async () => {
            this.customInfo = await this.service?.search(this.searchConfig);
            this.customInfoInitialState = this.customInfo?.map(item => this.objectExtensionService.clone(item) as ICustomInfoEntity);
            this.customInfoInitialStateChange.emit(this.customInfoInitialState);
            this.customInfoChange.emit(this.customInfo);
        });
    }

    private buildSearchConfig() {
        this.searchConfig = {
            isActive: this.filters.condition?.value,
            isReserved: this.filters.type?.value
        } as ISearchCustomInfoEntityParameters;
    }
}
