import { Component, Input, OnInit } from "@angular/core";

export type MessageBoxType = "warning" | "danger" | "info" | "success" | null;

@Component({
    selector: "lib-message-box",
    templateUrl: "./message-box.component.html",
    styleUrls: ["./message-box.component.scss"]
})
export class MessageBoxComponent implements OnInit {
    @Input() message: string;
    @Input() type: MessageBoxType;
    @Input() backgroundColor?: string;
    @Input() textColor?: string;

    styles: { [key: string]: string } = {};

    private readonly predefinedStyles: {
        [key in "warning" | "danger" | "info" | "success"]: { backgroundColor: string; color: string };
    } = {
        warning: { backgroundColor: "#fff3cd", color: "#856404" },
        danger: { backgroundColor: "#fbe7e7", color: "#e06363" },
        info: { backgroundColor: "#e3ecf2", color: "#175c89" },
        success: { backgroundColor: "#d4edda", color: "#155724" }
    };

    constructor() {
        this.message = "";
        this.type = null;
    }

    ngOnInit(): void {
        if (this.type && this.predefinedStyles[this.type]) {
            this.styles = this.predefinedStyles[this.type];
        } else {
            this.styles = {
                backgroundColor: this.backgroundColor ?? "#ffffff",
                color: this.textColor ?? "#000000"
            };
        }
    }
}
