import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { A11yModule } from "@angular/cdk/a11y";
import { provideAnimations } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PlaygroundComponent } from "./components/pages/playground/playground.component";
import { NgxLibModule } from "projects/ngx-lib/src/public-api";
import { HeaderComponent } from "./components/header/header.component";
import { UserPanelComponent } from "./components/header/user-panel/user-panel.component";
import { NotificationsPanelComponent } from "./components/header/notifications-panel/notifications-panel.component";
import { UserAppsPanelComponent } from "./components/header/user-apps-panel/user-apps-panel.component";
import { BuildingsMainComponent } from "./components/pages/buildings/buildings-main/buildings-main.component";
import { CompaniesMainComponent } from "./components/pages/companies/companies-main/companies-main.component";
import { ContactsMainComponent } from "./components/pages/contacts/contacts-main/contacts-main.component";
import { OpportunitiesMainComponent } from "./components/pages/opportunities/opportunities-main/opportunities-main.component";
import { CellFavoriteComponent } from "./components/shared/cell-favorite/cell-favorite.component";
import { OpportunitiesListComponent } from "./components/pages/opportunities/opportunities-list/opportunities-list.component";
import { ContactsListComponent } from "./components/pages/contacts/contacts-list/contacts-list.component";
import { BuildingsListComponent } from "./components/pages/buildings/buildings-list/buildings-list.component";
import { CompaniesListComponent } from "./components/pages/companies/companies-list/companies-list.component";
import { BuildingsSingleComponent } from "./components/pages/buildings/buildings-single/buildings-single.component";
import { CompaniesSingleComponent } from "./components/pages/companies/companies-single/companies-single.component";
import { CompaniesSingleOverviewComponent } from "./components/pages/companies/companies-single/companies-single-overview/companies-single-overview.component";
import { ListItemComponent } from "./components/shared/list-item/list-item.component";
import { ContactsSingleComponent } from "./components/pages/contacts/contacts-single/contacts-single.component";
import { AddressDisplayComponent } from "./components/shared/address-display/address-display.component";
import { ContactsSingleOverviewComponent } from "./components/pages/contacts/contacts-single/contacts-single-overview/contacts-single-overview.component";
import { BuildingsSingleOverviewComponent } from "./components/pages/buildings/buildings-single/buildings-single-overview/buildings-single-overview.component";
import { BuildingsSingleContactsComponent } from "./components/pages/buildings/buildings-single/buildings-single-contacts/buildings-single-contacts.component";
import { ContactsSingleBuildingsComponent } from "./components/pages/contacts/contacts-single/contacts-single-buildings/contacts-single-buildings.component";
import { CompaniesSingleBuildingsComponent } from "./components/pages/companies/companies-single/companies-single-buildings/companies-single-buildings.component";
import { CompaniesSingleContactsComponent } from "./components/pages/companies/companies-single/companies-single-contacts/companies-single-contacts.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { KebabMenuComponent } from "./components/shared/kebab-menu/kebab-menu.component";
import { OrganizationSwitcherComponent } from "./components/header/organization-switcher/organization-switcher.component";
import { OpportunitiesGridSingleComponent } from "./components/shared/opportunities-grid-single/opportunities-grid-single.component";
import { OpportunitiesSingleComponent } from "./components/pages/opportunities/opportunities-single/opportunities-single.component";
import { OpportunitiesCreateComponent } from "./components/pages/opportunities/opportunities-create/opportunities-create.component";
import { OpportunitiesSingleOverviewComponent } from "./components/pages/opportunities/opportunities-single/opportunities-single-overview/opportunities-single-overview.component";
import { GenericTypeAheadComponent } from "./components/shared/generic-type-ahead/generic-type-ahead.component";
import { CommonModule, CurrencyPipe, NgOptimizedImage, PercentPipe } from "@angular/common";
import { OpportunitiesSingleContactsComponent } from "./components/pages/opportunities/opportunities-single/opportunities-single-contacts/opportunities-single-contacts.component";
import { OpportunitiesSingleTeamComponent } from "./components/pages/opportunities/opportunities-single/opportunities-single-team/opportunities-single-team.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { WelcomeScreenComponent } from "./components/pages/welcome-screen/welcome-screen.component";
import { DashboardComponent } from "./components/pages/dashboard/dashboard.component";
import { UserAvatarComponent } from "./components/shared/user-avatar/user-avatar.component";
import { ContactsEditComponent } from "./components/pages/contacts/contacts-edit/contacts-edit.component";
import { BuildingEditorComponent } from "./components/shared/card-editors/building-editor/building-editor.component";
import { DivisionEditorComponent } from "./components/shared/card-editors/division-editor/division-editor.component";
import { DateEditorComponent } from "./components/shared/card-editors/date-editor/date-editor.component";
import { CompanyEditorComponent } from "./components/shared/card-editors/company-editor/company-editor.component";
import { OpportunityContactEditorComponent } from "./components/shared/card-editors/opportunity-contact-editor/opportunity-contact-editor.component";
import { OpportunityProbabilityEditorComponent } from "./components/shared/card-editors/opportunity-probability-editor/opportunity-probability-editor.component";
import { LeadSourceEditorComponent } from "./components/shared/card-editors/lead-source-editor/lead-source-editor.component";
import { OpportunityUserEditorComponent } from "./components/shared/card-editors/opportunity-user-editor/opportunity-user-editor.component";
import { LinkEditorComponent } from "./components/shared/card-editors/link-editor/link-editor.component";
import { MultiInputEditorComponent } from "./components/shared/card-editors/multi-input-editor/multi-input-editor.component";
import { BuildingsEditComponent } from "./components/pages/buildings/buildings-edit/buildings-edit.component";
import { BuildingClassEditorComponent } from "./components/shared/card-editors/building-class-editor/building-class-editor.component";
import { NumberInputEditorComponent } from "./components/shared/card-editors/number-input-editor/number-input-editor.component";
import { TextInputEditorComponent } from "./components/shared/card-editors/text-input-editor/text-input-editor.component";
import { EntityEditorComponent } from "./components/shared/card-editors/entity-editor/entity-editor.component";
import { CompaniesEditComponent } from "./components/pages/companies/companies-edit/companies-edit.component";
import { LegalStatusEditorComponent } from "./components/shared/card-editors/legal-status-editor/legal-status-editor.component";
import { CompanyTypeEditorComponent } from "./components/shared/card-editors/company-type-editor/company-type-editor.component";
import { TextareaEditorComponent } from "./components/shared/card-editors/textarea-editor/textarea-editor.component";
import { CompanyMainContactEditorComponent } from "./components/shared/card-editors/company-main-contact-editor/company-main-contact-editor.component";
import { FormattedInputEditorComponent } from "./components/shared/card-editors/formatted-input-editor/formatted-input-editor.component";
import { CurrencyInputEditorComponent } from "./components/shared/card-editors/formatted-input-editor/currency-input-editor/currency-input-editor.component";
import { PercentageInputEditorComponent } from "./components/shared/card-editors/formatted-input-editor/percentage-input-editor/percentage-input-editor.component";
import { CustomPercentagePipe } from "./pipes/custom-percentage.pipe";
import { TypeAheadContactsComponent } from "./components/shared/type-ahead-contacts/type-ahead-contacts.component";
import { ModalAddContactComponent } from "./components/shared/modals/modal-add-contact/modal-add-contact.component";
import { ModalAddCompanyComponent } from "./components/shared/modals/modal-add-company/modal-add-company.component";
import { ModalAddBuildingComponent } from "./components/shared/modals/modal-add-building/modal-add-building.component";
import { TypeAheadBuildingsComponent } from "./components/shared/type-ahead-buildings/type-ahead-buildings.component";
import { TypeAheadCompaniesComponent } from "./components/shared/type-ahead-companies/type-ahead-companies.component";
import { OpportunitiesSingleEstimatesComponent } from "./components/pages/opportunities/opportunities-single/opportunities-single-estimates/opportunities-single-estimates.component";
import { OpportunityStatusIndicatorComponent } from "./components/shared/opportunity-status-indicator/opportunity-status-indicator.component";
import { OpportunitiesEstimatesSubHeaderComponent } from "./components/pages/opportunities/opportunities-single/opportunities-single-estimates/opportunities-estimates-sub-header/opportunities-estimates-sub-header.component";
import { OpportunitiesSingleEstimatesGridComponent } from "./components/pages/opportunities/opportunities-single/opportunities-single-estimates/opportunities-single-estimates-grid/opportunities-single-estimates-grid.component";
import { OpportunitiesSingleChangeOrderGridComponent } from "./components/pages/opportunities/opportunities-single/opportunities-single-estimates/opportunities-single-change-order-grid/opportunities-single-change-order-grid.component";
import { OpportunitiesSingleEstimatesSummaryComponent } from "./components/pages/opportunities/opportunities-single/opportunities-single-estimates/opportunities-single-estimates-summary/opportunities-single-estimates-summary.component";
import { ModalCreateEstimateComponent } from "./components/shared/modals/modal-create-estimate/modal-create-estimate.component";
import { ModalEditOpportunityContactComponent } from "./components/shared/modals/modal-edit-opportunity-contact/modal-edit-opportunity-contact.component";
import { BuildingContactRoleEditorComponent } from "./components/shared/card-editors/building-contact-role-editor/building-contact-role-editor.component";
import { ModalEditBuildingContactComponent } from "./components/shared/modals/modal-edit-building-contact/modal-edit-building-contact.component";
import { ModalEditOpportunityTeamMemberComponent } from "./components/shared/modals/modal-edit-opportunity-team-member/modal-edit-opportunity-team-member.component";
import { ValidationErrorMessageComponent } from "./components/shared/validation-error-message/validation-error-message.component";
import { DashboardOpportunitiesGridComponent } from "./components/pages/dashboard/dashboard-opportunities-grid/dashboard-opportunities-grid.component";
import { DashboardStatComponent } from "./components/pages/dashboard/dashboard-stat/dashboard-stat.component";
import { LocationDetailsComponent } from "./components/shared/location-details/location-details.component";
import { TypeAheadAddressesComponent } from "./components/shared/type-ahead-addresses/type-ahead-addresses.component";
import { StaticMapComponent } from "./components/shared/static-map/static-map.component";
import { AddressEditorComponent } from "./components/shared/card-editors/address-editor/address-editor.component";
import { ForbiddenComponent } from "./components/pages/errors/forbidden/forbidden.component";
import { DescriptiveItemComponent } from "./components/shared/descriptive-item/descriptive-item.component";
import { LoadingButtonComponent } from "./components/shared/loading-button/loading-button.component";
import { LoadingSpinnerComponent } from "./components/shared/loading-spinner/loading-spinner.component";
import { ModalConfirmComponent } from "./components/shared/modals/modal-confirm/modal-confirm.component";
import { ContactsEditorComponent } from "./components/shared/card-editors/contacts-editor/contacts-editor.component";
import { OpportunitiesAdditionalFiltersComponent } from "./components/shared/opportunities-filters/opportunities-additional-filters/opportunities-additional-filters.component";
import { OpportunitiesSingleActivityLogComponent } from "./components/pages/opportunities/opportunities-single/opportunities-single-activity-log/opportunities-single-activity-log.component";
import { ActivityLogGridComponent } from "./components/shared/activity-log-grid/activity-log-grid.component";
import { OpportunitiesFiltersComponent } from "./components/shared/opportunities-filters/opportunities-filters.component";
import { NotesComponent } from "./components/shared/notes/notes.component";
import { ModalEditNoteComponent } from "./components/shared/modals/modal-edit-note/modal-edit-note.component";
import { NoteUserEditorComponent } from "./components/shared/card-editors/note-user-editor/note-user-editor.component";
import { NoteContentComponent } from "./components/shared/notes/note-content/note-content.component";
import { EnsureHttpsDirective } from "./directives/ensure-https.directive";
import { UserEditorComponent } from "./components/shared/card-editors/user-editor/user-editor.component";
import { OpportunityStatusEditorComponent } from "./components/shared/card-editors/opportunity-status-editor/opportunity-status-editor.component";
import { CheckboxEditorComponent } from "./components/shared/card-editors/checkbox-editor/checkbox-editor.component";
import { NotificationItemComponent } from "./components/header/notifications-panel/notification-item/notification-item.component";
import { NotificationsComponent } from "./components/pages/notifications/notifications.component";
import { NotificationsListComponent } from "./components/header/notifications-panel/notifications-list/notifications-list.component";
import { AuthWrapperComponent } from "./components/shared/auth-wrapper/auth-wrapper.component";
import { EstimateTypeEditorComponent } from "./components/shared/card-editors/estimate-type-editor/estimate-type-editor.component";
import { ForgotPasswordComponent } from "./components/pages/forgot-password/forgot-password.component";
import { AccessDeniedComponent } from "./components/pages/access-denied/access-denied.component";
import { SearchMainEntitiesComponent } from "./components/header/search-main-entities/search-main-entities.component";
import { PopupCardBaseLayoutComponent } from "./components/shared/popup-card-base-layout/popup-card-base-layout.component";
import { OpportunityCardExpandedComponent } from "./components/shared/opportunity-card/opportunity-card-expanded/opportunity-card-expanded.component";
import { OpportunityCardCompactComponent } from "./components/shared/opportunity-card/opportunity-card-compact/opportunity-card-compact.component";
import { OpportunitiesCalendarComponent } from "./components/pages/opportunities/opportunities-calendar/opportunities-calendar.component";
import { OpportunitiesMapComponent } from "./components/pages/opportunities/opportunities-map/opportunities-map.component";
import { ConfigurationComponent } from "./components/pages/configuration/configuration.component";
import { CustomizationComponent } from "./components/pages/configuration/customization/customization.component";
import { CustomizationCustomFieldsComponent } from "./components/pages/configuration/customization/customization-custom-fields/customization-custom-fields.component";
import { CustomizationCustomInfoComponent } from "./components/pages/configuration/customization/customization-custom-info/customization-custom-info.component";
import { CustomFieldsItemComponent } from "./components/shared/custom-fields-item/custom-fields-item.component";
import { CustomizationFiltersComponent } from "./components/pages/configuration/customization/customization-filters/customization-filters.component";
import { OpportunitiesAdditionalFiltersAppliedComponent } from "./components/shared/opportunities-filters/opportunities-additional-filters-applied/opportunities-additional-filters-applied.component";
import { OrganizationItemComponent } from "./components/shared/organization-item/organization-item.component";
import { NotificationsEditComponent } from "./components/pages/notifications/notifications-edit/notifications-edit.component";
import { ToggleEditorComponent } from "./components/shared/card-editors/toggle-editor/toggle-editor.component";
import { ActiveStatusEditorComponent } from "./components/shared/card-editors/active-status-editor/active-status-editor.component";
import { MarketEditorComponent } from "./components/shared/card-editors/market-editor/market-editor.component";
import { StateEditorComponent } from "./components/shared/card-editors/state-editor/state-editor.component";
import { CurrencyEditorComponent } from "./components/shared/card-editors/currency-editor/currency-editor.component";
import { CustomPercentageEditorComponent } from "./components/shared/card-editors/custom-percentage-editor/custom-percentage-editor.component";
import { AgCharts } from "ag-charts-angular";
import { SalesFunnelChartComponent } from "./components/shared/charts/sales-funnel-chart/sales-funnel-chart.component";
import { BidVsAwardedChartComponent } from "./components/shared/charts/bid-vs-awarded-chart/bid-vs-awarded-chart.component";
import { ReportsMainComponent } from "./components/pages/reports/reports-main/reports-main.component";
import { TitledListGroupComponent } from "./components/shared/titled-list-group/titled-list-group.component";
import { ListElementComponent } from "./components/shared/titled-list-group/list-element/list-element.component";
import { AgGridAngular } from "ag-grid-angular";
import "ag-charts-community";
import { GroupByRowsFilterComponent } from "./components/shared/grid-filters/group-by-rows-filter/group-by-rows-filter.component";
import { ColumnSelectorComponent } from "./components/shared/grid-filters/group-by-rows-filter/column-selector/column-selector.component";
import { CdkTableModule } from "@angular/cdk/table";
import { GenericOverlayComponent } from "./components/shared/generic-overlay/generic-overlay.component";
import { ModalUserAccountSettingsComponent } from "./components/shared/modals/modal-user-account-settings/modal-user-account-settings.component";
import { ModalUpdatePasswordComponent } from "./components/shared/modals/modal-update-password/modal-update-password.component";
import { PasswordInputComponent } from "./components/shared/password-input/password-input.component";
import { TrimSpacesDirective } from "./directives/trim-spaces.directive";
import { ColumnVisibilityFilterComponent } from "./components/shared/grid-filters/column-visibility-filter/column-visibility-filter.component";
import { ReportInstanceComponent } from "./components/pages/reports/reports-single/report-section/report-section-instance/report-instance.component";
import { BuildingsSingleCompaniesComponent } from "./components/pages/buildings/buildings-single/buildings-single-companies/buildings-single-companies.component";
import { SliderComponent } from "./components/shared/slider/slider.component";
import { ModalReportSaveComponent } from "./components/shared/modals/modal-report-save/modal-report-save.component";
import { ModalReportShareComponent } from "./components/shared/modals/modal-report-share/modal-report-share.component";
import { InternalNavigatorComponent } from "./components/shared/internal-navigator/internal-navigator.component";
import { CustomMenuItemComponent } from "./components/shared/custom-menu-item/custom-menu-item.component";
import { TitleStrategy } from "@angular/router";
import { AppTitleStrategy } from "./utils/strategies/app-title.strategy";
import { ReportsListComponent } from "./components/pages/reports/reports-list/reports-list.component";
import { DropdownRangesEditorComponent } from "./components/shared/card-editors/dropdown-ranges-editor/dropdown-ranges-editor.component";
import { ModalEditAddressComponent } from "./components/shared/modals/modal-edit-address/modal-edit-address.component";
import { DateRangeEditorComponent } from "./components/shared/card-editors/date-range-editor/date-range-editor.component";
import { ReportingMarketEditorComponent } from "./components/shared/card-editors/reporting-editors/reporting-market-editor/reporting-market-editor.component";
import { MultipleDropdownValuesEditorComponent } from "./components/shared/card-editors/reporting-editors/multiple-dropdown-values-editor/multiple-dropdown-values-editor.component";
import { ReportingUserEditorComponent } from "./components/shared/card-editors/reporting-editors/reporting-user-editor/reporting-user-editor.component";
import { MultipleTypeAheadValuesEditorComponent } from "./components/shared/card-editors/reporting-editors/multiple-type-ahead-values-editor/multiple-type-ahead-values-editor.component";
import { SingleDropdownValueEditorComponent } from "./components/shared/card-editors/reporting-editors/single-dropdown-value-editor/single-dropdown-value-editor.component";
import { ReportingDivisionEditorComponent } from "./components/shared/card-editors/reporting-editors/reporting-division-editor/reporting-division-editor.component";
import { SingleTypeAheadValueEditorComponent } from "./components/shared/card-editors/reporting-editors/single-type-ahead-value-editor/single-type-ahead-value-editor.component";
import { ReportingCompanyEditorComponent } from "./components/shared/card-editors/reporting-editors/reporting-company-editor/reporting-company-editor.component";
import { ReportingOpportunitySourceEditorComponent } from "./components/shared/card-editors/reporting-editors/reporting-opportunity-source-editor/reporting-opportunity-source-editor.component";
import { ReportingOrganizationEditorComponent } from "./components/shared/card-editors/reporting-editors/reporting-organization-editor/reporting-organization-editor.component";
import { ReportingOpportunitySearchEditorComponent } from "./components/shared/card-editors/reporting-editors/reporting-opportunity-search-editor/reporting-opportunity-search-editor.component";
import { ReportingContactEditorComponent } from "./components/shared/card-editors/reporting-editors/reporting-contact-editor/reporting-contact-editor.component";
import { ReportingProjectStatusEditorComponent } from "./components/shared/card-editors/reporting-editors/reporting-project-status-editor/reporting-project-status-editor.component";
import { ReportingProjectSearchEditorComponent } from "./components/shared/card-editors/reporting-editors/reporting-project-search-editor/reporting-project-search-editor.component";
import { ReportingOpportunityStatusEditorComponent } from "./components/shared/card-editors/reporting-editors/reporting-opportunity-status-editor/reporting-opportunity-status-editor.component";
import { ChartComponent } from "./components/pages/reports/reports-single/report-section/report-section-instance/report-section-instance-widgets/chart-widget/chart/chart.component";
import { FiltersMainComponent } from "./components/pages/reports/reports-single/filters/filters-main/filters-main.component";
import { ReportSectionComponent } from "./components/pages/reports/reports-single/report-section/report-section.component";
import { ReportsSingleComponent } from "./components/pages/reports/reports-single/reports-single.component";
import { BoxComponent } from "./components/pages/reports/reports-single/report-section/report-section-instance/report-section-instance-widgets/box-widget/box/box.component";
import { BoxesWrapperComponent } from "./components/pages/reports/reports-single/report-section/report-section-instance/report-section-instance-widgets/box-widget/boxes-wrapper/boxes-wrapper.component";
import { GridComponent } from "./components/pages/reports/reports-single/report-section/report-section-instance/report-section-instance-widgets/grid-widget/grid/grid.component";
import { TableComponent } from "./components/pages/reports/reports-single/report-section/report-section-instance/report-section-instance-widgets/metrics-widget/table/table.component";
import { AdditionalFiltersComponent } from "./components/pages/reports/reports-single/filters/additional-filters/additional-filters.component";
import { GridWidgetComponent } from "./components/pages/reports/reports-single/report-section/report-section-instance/report-section-instance-widgets/grid-widget/grid-widget.component";
import { ChartWidgetComponent } from "./components/pages/reports/reports-single/report-section/report-section-instance/report-section-instance-widgets/chart-widget/chart-widget.component";
import { MetricsWidgetComponent } from "./components/pages/reports/reports-single/report-section/report-section-instance/report-section-instance-widgets/metrics-widget/metrics-widget.component";
import { BoxWidgetComponent } from "./components/pages/reports/reports-single/report-section/report-section-instance/report-section-instance-widgets/box-widget/box-widget.component";
import { AccountsMainComponent } from "./components/pages/accounts/accounts-main/accounts-main.component";
import { AccountsSingleComponent } from "./components/pages/accounts/accounts-single/accounts-single.component";
import { AccountsListComponent } from "./components/pages/accounts/accounts-list/accounts-list.component";
import { AccountsEditComponent } from "./components/pages/accounts/accounts-edit/accounts-edit.component";
import { AccountsSingleOverviewComponent } from "./components/pages/accounts/accounts-single/accounts-single-overview/accounts-single-overview.component";
import { AccountsSingleCompaniesComponent } from "./components/pages/accounts/accounts-single/accounts-single-companies/accounts-single-companies.component";
import { AccountsSingleBuildingsComponent } from "./components/pages/accounts/accounts-single/accounts-single-buildings/accounts-single-buildings.component";
import { AccountsSingleContactsComponent } from "./components/pages/accounts/accounts-single/accounts-single-contacts/accounts-single-contacts.component";
import { AccountEditorComponent } from "./components/shared/card-editors/account-editor/account-editor.component";
import { TypeAheadAccountsComponent } from "./components/shared/type-ahead-accounts/type-ahead-accounts.component";
import { ModalAddAccountComponent } from "./components/shared/modals/modal-add-account/modal-add-account.component";
import { ReportingChangeOrderStatusEditorComponent } from "./components/shared/card-editors/reporting-editors/reporting-change-order-status-editor/reporting-change-order-status-editor.component";
import { TypeAheadUuidsComponent } from './components/type-ahead-uuids/type-ahead-uuids.component';
import { ReportingBuildingClassEditorComponent } from './components/shared/card-editors/reporting-editors/reporting-building-class-editor/reporting-building-class-editor.component';
import { ReportingLegalStatusEditorComponent } from './components/shared/card-editors/reporting-editors/reporting-legal-status-editor/reporting-legal-status-editor.component';
import { ReportingCompanyTypeEditorComponent } from './components/shared/card-editors/reporting-editors/reporting-company-type-editor/reporting-company-type-editor.component';
import { ReportingManagerOrganizationEditorComponent } from './components/shared/card-editors/reporting-editors/reporting-manager-organization-editor/reporting-manager-organization-editor.component';

@NgModule({
    declarations: [
        AppComponent,
        PlaygroundComponent,
        HeaderComponent,
        UserAvatarComponent,
        UserPanelComponent,
        NotificationsPanelComponent,
        UserAppsPanelComponent,
        BuildingsMainComponent,
        CompaniesMainComponent,
        ContactsMainComponent,
        OpportunitiesMainComponent,
        CellFavoriteComponent,
        OpportunitiesListComponent,
        ContactsListComponent,
        BuildingsListComponent,
        CompaniesListComponent,
        BuildingsSingleComponent,
        CompaniesSingleComponent,
        CompaniesSingleOverviewComponent,
        ListItemComponent,
        ContactsSingleComponent,
        AddressDisplayComponent,
        ContactsSingleOverviewComponent,
        BuildingsSingleOverviewComponent,
        BuildingsSingleContactsComponent,
        ContactsSingleBuildingsComponent,
        CompaniesSingleBuildingsComponent,
        CompaniesSingleContactsComponent,
        KebabMenuComponent,
        OrganizationSwitcherComponent,
        OpportunitiesGridSingleComponent,
        OpportunitiesSingleComponent,
        OpportunitiesCreateComponent,
        OpportunitiesSingleOverviewComponent,
        GenericTypeAheadComponent,
        OpportunitiesSingleContactsComponent,
        OpportunitiesSingleTeamComponent,
        LoginComponent,
        WelcomeScreenComponent,
        DashboardComponent,
        ContactsEditComponent,
        BuildingEditorComponent,
        DivisionEditorComponent,
        DateEditorComponent,
        CompanyEditorComponent,
        OpportunityContactEditorComponent,
        OpportunityProbabilityEditorComponent,
        LeadSourceEditorComponent,
        OpportunityUserEditorComponent,
        LinkEditorComponent,
        MultiInputEditorComponent,
        BuildingsEditComponent,
        EntityEditorComponent,
        CompaniesEditComponent,
        BuildingClassEditorComponent,
        NumberInputEditorComponent,
        TextInputEditorComponent,
        LegalStatusEditorComponent,
        CompanyTypeEditorComponent,
        TextareaEditorComponent,
        CompanyMainContactEditorComponent,
        FormattedInputEditorComponent,
        CurrencyInputEditorComponent,
        PercentageInputEditorComponent,
        CustomPercentagePipe,
        TypeAheadContactsComponent,
        ModalAddContactComponent,
        ModalAddCompanyComponent,
        ModalAddBuildingComponent,
        TypeAheadBuildingsComponent,
        TypeAheadCompaniesComponent,
        OpportunitiesSingleEstimatesComponent,
        OpportunityStatusIndicatorComponent,
        OpportunitiesEstimatesSubHeaderComponent,
        OpportunitiesSingleEstimatesGridComponent,
        OpportunitiesSingleChangeOrderGridComponent,
        OpportunitiesSingleEstimatesSummaryComponent,
        ModalCreateEstimateComponent,
        ModalEditOpportunityContactComponent,
        BuildingContactRoleEditorComponent,
        ModalEditBuildingContactComponent,
        ModalEditOpportunityTeamMemberComponent,
        ValidationErrorMessageComponent,
        DashboardOpportunitiesGridComponent,
        DashboardStatComponent,
        LocationDetailsComponent,
        TypeAheadAddressesComponent,
        StaticMapComponent,
        AddressEditorComponent,
        ForbiddenComponent,
        DescriptiveItemComponent,
        LoadingButtonComponent,
        LoadingSpinnerComponent,
        ModalConfirmComponent,
        ContactsEditorComponent,
        OpportunitiesAdditionalFiltersComponent,
        OpportunitiesSingleActivityLogComponent,
        ActivityLogGridComponent,
        OpportunitiesFiltersComponent,
        NotesComponent,
        ModalEditNoteComponent,
        NoteUserEditorComponent,
        NoteContentComponent,
        EnsureHttpsDirective,
        NotificationItemComponent,
        NotificationsComponent,
        NotificationsListComponent,
        UserEditorComponent,
        OpportunityStatusEditorComponent,
        CheckboxEditorComponent,
        AuthWrapperComponent,
        EstimateTypeEditorComponent,
        ForgotPasswordComponent,
        AccessDeniedComponent,
        SearchMainEntitiesComponent,
        PopupCardBaseLayoutComponent,
        OpportunityCardExpandedComponent,
        OpportunityCardCompactComponent,
        OpportunitiesCalendarComponent,
        OpportunitiesMapComponent,
        ConfigurationComponent,
        CustomizationComponent,
        CustomizationCustomFieldsComponent,
        CustomizationCustomInfoComponent,
        CustomFieldsItemComponent,
        CustomizationFiltersComponent,
        OpportunitiesAdditionalFiltersAppliedComponent,
        OrganizationItemComponent,
        NotificationsEditComponent,
        ToggleEditorComponent,
        ActiveStatusEditorComponent,
        MarketEditorComponent,
        StateEditorComponent,
        CurrencyEditorComponent,
        CustomPercentageEditorComponent,
        SalesFunnelChartComponent,
        BidVsAwardedChartComponent,
        ReportsMainComponent,
        TitledListGroupComponent,
        ListElementComponent,
        ReportsSingleComponent,
        BoxComponent,
        BoxesWrapperComponent,
        GridComponent,
        GroupByRowsFilterComponent,
        ColumnSelectorComponent,
        TableComponent,
        ChartComponent,
        FiltersMainComponent,
        GenericOverlayComponent,
        ModalUserAccountSettingsComponent,
        ModalUpdatePasswordComponent,
        PasswordInputComponent,
        TrimSpacesDirective,
        ColumnVisibilityFilterComponent,
        BoxWidgetComponent,
        MetricsWidgetComponent,
        ChartWidgetComponent,
        BuildingsSingleCompaniesComponent,
        ReportInstanceComponent,
        GridWidgetComponent,
        AdditionalFiltersComponent,
        SliderComponent,
        ModalReportSaveComponent,
        ModalReportShareComponent,
        InternalNavigatorComponent,
        CustomMenuItemComponent,
        ReportSectionComponent,
        ReportsListComponent,
        DropdownRangesEditorComponent,
        ModalEditAddressComponent,
        DateRangeEditorComponent,
        ReportingMarketEditorComponent,
        DateRangeEditorComponent,
        MultipleDropdownValuesEditorComponent,
        ReportingUserEditorComponent,
        MultipleTypeAheadValuesEditorComponent,
        SingleDropdownValueEditorComponent,
        ReportingDivisionEditorComponent,
        SingleTypeAheadValueEditorComponent,
        ReportingCompanyEditorComponent,
        ReportingOpportunitySourceEditorComponent,
        ReportingOrganizationEditorComponent,
        ReportingOpportunitySearchEditorComponent,
        ReportingContactEditorComponent,
        ReportingProjectStatusEditorComponent,
        ReportingProjectSearchEditorComponent,
        ReportingOpportunityStatusEditorComponent,
        AccountsMainComponent,
        AccountsSingleComponent,
        AccountsListComponent,
        AccountsEditComponent,
        AccountsSingleOverviewComponent,
        AccountsSingleCompaniesComponent,
        AccountsSingleBuildingsComponent,
        AccountsSingleContactsComponent,
        AccountEditorComponent,
        TypeAheadAccountsComponent,
        ModalAddAccountComponent,
        ReportingChangeOrderStatusEditorComponent,
        TypeAheadUuidsComponent,
        ReportingBuildingClassEditorComponent,
        ReportingLegalStatusEditorComponent,
        ReportingCompanyTypeEditorComponent,
        ReportingManagerOrganizationEditorComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgxLibModule,
        FormsModule,
        ReactiveFormsModule,
        OverlayModule,
        PortalModule,
        A11yModule,
        NgOptimizedImage,
        AgCharts,
        AgGridAngular,
        CdkTableModule,
        CommonModule
    ],
    providers: [CurrencyPipe, PercentPipe, CustomPercentagePipe, provideAnimations(), { provide: TitleStrategy, useClass: AppTitleStrategy }],
    bootstrap: [AppComponent],
    exports: [BoxesWrapperComponent, BoxWidgetComponent, MetricsWidgetComponent, ChartWidgetComponent]
})
export class AppModule {}
