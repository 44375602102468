import { AccountMarketView, MarketView } from "projects/app/src/app/services/http/clients/api-proxies";
import { MarketEntity } from "./abstract/market-entity";
import { MarketService } from "projects/app/src/app/services/http/clients/market.service";

export class AccountMarketEntity extends MarketEntity<AccountMarketView> {
    constructor(marketService: MarketService) {
        super(marketService);
    }

    toDestination(entity: MarketView): AccountMarketView {
        return { marketId: entity.id, marketName: entity.name, accountId: 0, id: 0, accountName: undefined };
    }

    toSource(entity: AccountMarketView): MarketView {
        return { id: entity.marketId, name: entity.marketName };
    }
}
