import { Component, Input, TemplateRef } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "lib-tab-item",
    templateUrl: "./tab-item.component.html",
    styleUrls: ["./tab-item.component.scss"]
})
export class TabItemComponent {
    @Input() tabName? = "default";
    @Input() icon?: SafeHtml;
    @Input() headerTemplate!: TemplateRef<unknown>;
    @Input() bodyTemplate!: TemplateRef<unknown>;
    @Input() disabled = false;

    @Input()
    tabItemId?: string;
}
