import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { AgChartOptions } from "ag-charts-community";
import { customTheme, reportsSingleChart } from "projects/app/src/app/components/shared/themes/chart-themes";
import { ChartService } from "projects/app/src/app/services/chart.service";

@Component({
    selector: "app-chart",
    templateUrl: "./chart.component.html",
    styleUrls: ["./chart.component.scss"]
})
export class ChartComponent implements OnChanges, OnDestroy {
    @Input()
    chartValues?: any[];

    @Input()
    settings?: AgChartOptions;

    series?: AgChartOptions;

    constructor(private readonly chartService: ChartService) {}

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes["chartValues"] && this.chartValues && this.settings) {
            this.chartService.setTheme(reportsSingleChart);
            this.series = {
                theme: this.chartService.getTheme(),
                data: this.chartValues,
                minHeight: 500,
                ...this.settings
            };
        }
    }

    ngOnDestroy(): void {
        this.chartService.setTheme(customTheme);
    }
}
