import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { ModalService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { configs } from "./configs/configs";
import { ActivatedRoute, Router } from "@angular/router";
import { mapping } from "./filters/mapping";
import { ReportTemplateService } from "projects/app/src/app/services/http/clients/reporting-app/report-template.service";
import { CustomReportUserFavoriteService } from "projects/app/src/app/services/http/clients/reporting-app/custom-report/custom-report-user-favorite.service";
import { AbstractReportFactory } from "projects/app/src/app/factories/report-factory/abstract-report.factory";
import { IFilterItem, IFilters, IReportConfiguration } from "../types/report-config.interface";
import { ReportsService } from "projects/app/src/app/services/reports.service";
import { ModalReportSaveComponent } from "../../../shared/modals/modal-report-save/modal-report-save.component";
import { IReportFactory } from "../types/report-factory.interface";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { icons } from "projects/ngx-lib/src/lib/assets/icons";
import { ModalConfirmComponent } from "../../../shared/modals/modal-confirm/modal-confirm.component";
import { CustomReportSearchService } from "projects/app/src/app/services/http/clients/reporting-app/custom-report/custom-report-search.service";
import { ModalReportShareComponent } from "../../../shared/modals/modal-report-share/modal-report-share.component";

@Component({
    selector: "app-reports-single",
    templateUrl: "./reports-single.component.html",
    styleUrls: ["./reports-single.component.scss"]
})
export class ReportsSingleComponent implements OnInit {
    currentTab = "Overview";
    configuration?: IReportConfiguration<unknown>;
    filterParamsSelected: any = {};
    filterValues: any;
    reportFactoryOverview!: IReportFactory;
    reportFactoryGrid!: IReportFactory;
    reportData?: any;
    reportService?: any;
    customReportId?: number;
    reportTemplateName?: string;
    reportSubtitle?: string;
    reportId?: number | string;
    isLoading: boolean;
    templateRun?: boolean;
    saveIcon?: SafeHtml;
    shareIcon?: SafeHtml;
    canUpdateReport: boolean;
    canDeleteReport: boolean;
    canAddToCatalog: boolean;
    canUpdateCatalog: boolean;
    catalogReportId?: string;

    constructor(
        private readonly modalService: ModalService,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly route: ActivatedRoute,
        private readonly awaiter: AwaiterService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly sanitizer: DomSanitizer,
        private readonly router: Router,
        private readonly reportTemplateService: ReportTemplateService,
        private readonly reportsService: ReportsService,
        private readonly customReportUserFavoriteService: CustomReportUserFavoriteService,
        private readonly customReportService: CustomReportSearchService
    ) {
        this.isLoading = true;
        this.saveIcon = this.sanitizer.bypassSecurityTrustHtml(icons.save);
        this.shareIcon = this.sanitizer.bypassSecurityTrustHtml(icons.share);
        this.canUpdateReport = false;
        this.canDeleteReport = false;
        this.canAddToCatalog = false;
        this.canUpdateCatalog = false;
        this.filterValues = {
            organizationIds: null,
            salesPersonIds: null,
            opportunityIds: null,
            opportunityCreationDate: {
                from: undefined,
                to: undefined
            },
            opportunityDueDate: {
                from: undefined,
                to: undefined
            },
            projectStartDate: {
                from: undefined,
                to: undefined
            },
            projectEndDate: {
                from: undefined,
                to: undefined
            },
            markets: null,
            leads: null,
            divisionIds: undefined,
            customerIds: null,
            potentialValue: {
                from: undefined,
                to: undefined
            },
            probabilityValue: {
                from: undefined,
                to: undefined
            },
            expectedResponseDate: {
                from: undefined,
                to: undefined
            },
            bidDate: {
                from: undefined,
                to: undefined
            },
            condition: undefined,
            awardedAmount: {
                from: undefined,
                to: undefined
            },
            bidAmount: {
                from: undefined,
                to: undefined
            },
            stateId: undefined,
            buildingClassId: undefined,
            companyPropertyManagerIds: null,
            companyTypeId: undefined,
            legalStatusId: undefined,
            awardedDate: {
                from: undefined,
                to: undefined
            },
            relatedOpportunitiesCondition: undefined,
            estimateTypeId: undefined,
            opportunityStatusIds: null,
            competingBidderIds: null,
            projectDueDate: {
                from: undefined,
                to: undefined
            },
            projectStatusIds: null,
            changeOrderStatusId: null,
            projectIds: null
        };
        this.templateRun = false;
    }

    async ngOnInit(): Promise<void> {
        this.route.paramMap.subscribe(async params => {
            this.customReportId = parseInt(params.get("id") ?? "0");
            this.reportTemplateName = params.get("reportTemplate") ?? "";
            this.catalogReportId = params.get("token") ?? "";
            if (this.reportTemplateName) {
                this.setFactoryConfig(this.reportTemplateName);
                this.bindFiltersToEditors();
                await this.initializeReport();
            }
        });
        if (this.configuration) {
            const factory = new AbstractReportFactory();
            this.reportFactoryOverview = factory.getConcreteFactory(this.configuration);
            this.reportFactoryGrid = factory.getConcreteFactory(this.configuration);
        }
    }

    async run() {
        this.templateRun = true;
        this.filtersMapping();
        await this.awaiter.awaitAction(
            "Running template...",
            async () => {
                if (!this.configuration) return;
                const instanceOverview = this.reportFactoryOverview.getReportInstance(this.configuration, this.viewContainerRef);
                const instanceGrid = this.reportFactoryGrid.getReportInstance(this.configuration, this.viewContainerRef);
                await instanceOverview.update(this.filterParamsSelected);
                await instanceGrid.update(this.filterParamsSelected);
            },
            loading => (this.isLoading = loading)
        );
    }

    async openSaveModal(editModal?: boolean): Promise<void> {
        this.filtersMapping();
        const reportResponse = await this.modalService.open(ModalReportSaveComponent, {
            id: editModal ? this.customReportId : undefined,
            canAddToCatalog: this.checkAddToCatalogPermission(),
            canUpdateCatalog: this.checkUpdateCatalogPermission(),
            reportService: this.reportService,
            reportData: this.reportData,
            filterParameters: this.filterParamsSelected,
            editMode: editModal
        });

        if (reportResponse && (reportResponse as any).id === this.customReportId) {
            this.reportData = reportResponse;
            this.checkAllowedActions();
        }
    }

    openShareModal() {
        this.modalService.open(ModalReportShareComponent, {
            reportService: this.reportService,
            reportData: this.reportData,
            filterParameters: this.filterParamsSelected
        });
    }

    async toggleFavorite(id: number | string) {
        try {
            if (!this.reportData) return;
            const intId: number = typeof id === "string" ? parseInt(id) : id;
            this.reportData.isFavorite = this.customReportId
                ? await this.customReportUserFavoriteService.toggleFavorite(intId)
                : await this.reportTemplateService.toggleFavorite(intId);

            this.snackbarNotificationService.success(`Report ${this.reportData.isFavorite ? "added" : "removed"} from favorites`);
        } catch (err) {
            this.snackbarNotificationService.error("An error occurred on favorite change");
        }
    }

    async updateReport(): Promise<void> {
        if (!this.checkUpdatePermission()) throw new Error("You do not have permission to update this report");

        await this.awaiter.awaitAction("Updating Report", async () => {
            this.filtersMapping();
            this.reportData = await this.reportService.saveCustomReport({
                id: this.reportData?.id,
                name: this.reportData?.name,
                reportTemplateId: this.reportData?.reportTemplateId,
                filterParameters: this.filterParamsSelected,
                isFavorite: this.reportData?.isFavorite,
                isPublic: this.reportData?.isPublic,
                organizations: this.reportData?.organizations?.length > 0 && this.reportData?.isPublic ? this.reportData.organizations : null
            });
            this.snackbarNotificationService.success("The report has been successfully updated");
        });
    }

    async deleteReport(): Promise<void> {
        if (!this.checkUpdatePermission()) throw new Error("You do not have permission to delete this report");

        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Delete",
            cancelCaption: "Cancel",
            content: "This report will be deleted including all the data. This action cannot be undone. Are you sure you want to delete it?",
            title: "Delete Report",
            isReject: true
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Deleting Report", async () => {
            await this.customReportService.deactivate(this.reportData?.id);
            this.snackbarNotificationService.success("The report has been successfully deleted");
            this.router.navigate(["/reports"]);
        });
    }

    private setFactoryConfig(template: string) {
        this.configuration = configs[template];
    }

    private async initializeReport(): Promise<void> {
        if (!this.configuration?.base.serviceType) throw new Error("Unable to instantiate the report component.");
        this.reportService = this.reportsService.getService(this.configuration.base.serviceType);
        const isSharedRoute = this.route.snapshot.url.some(segment => segment.path === "shared");

        if (isSharedRoute) {
            await this.initializeCatalogReport();
        } else if (this.customReportId) {
            await this.initializeCustomReport();
        } else {
            await this.initializeTemplateReport();
        }

        this.checkAllowedActions();
    }

    private async initializeCatalogReport(): Promise<void> {
        await this.awaiter.awaitAction("Getting Report", async () => {
            this.reportData = await this.reportService.getSharedReport(this.catalogReportId);
            this.reportSubtitle = `${this.reportData.reportTemplateName} - ${this.reportData?.reportTemplateGroupName}`;
            this.reportId = this.catalogReportId;
            if (this.reportData.filterParameters) {
                this.filterParamsSelected = { ...this.reportData.filterParameters };
                this.initializeFilterValues();
                this.run();
            }
        });
    }

    private async initializeCustomReport(): Promise<void> {
        await this.awaiter.awaitAction("Getting Report", async () => {
            this.reportData = await this.reportService.getCustomReport(this.customReportId);
            this.reportSubtitle = `${this.reportData.reportTemplateName} - ${this.reportData?.reportTemplateGroupName}`;
            this.reportId = this.customReportId;
            if (this.reportData.filterParameters) {
                this.filterParamsSelected = { ...this.reportData.filterParameters };
                this.initializeFilterValues();
                this.run();
            }
        });
    }

    private async initializeTemplateReport(): Promise<void> {
        await this.awaiter.awaitAction("Getting Report", async () => {
            this.reportData = await this.reportTemplateService.getByRoute(`reports/${this.reportTemplateName}`);
            this.reportSubtitle = `Base Template - ${this.reportData?.reportTemplateGroupName}`;
            this.reportId = this.reportData.id;
        });
    }

    private bindFiltersToEditors() {
        if (!this.configuration) return;
        Object.keys(this.configuration.filters).forEach((filterCategory: string) => {
            this.configuration?.filters[filterCategory as keyof IFilters].forEach((filter: IFilterItem) => {
                filter.bindContext = this.filterValues;
            });
        });
    }

    private initializeFilterValues() {
        if (!this.configuration) return;
        Object.keys(this.configuration.filters).forEach((filterCategory: string) => {
            this.configuration?.filters[filterCategory as keyof IFilters].forEach((filter: IFilterItem) => {
                if (filter.mapping) {
                    Object.keys(filter.mapping).forEach((sourceKey: string) => {
                        const value = this.filterParamsSelected[sourceKey];
                        const mappedKey = mapping[sourceKey];
                        mappedKey.split(".").reduce((acc, key, idx, arr) => {
                            if (idx === arr.length - 1) {
                                acc[key] = value;
                            } else {
                                acc[key] = acc[key] || {};
                            }
                            return acc[key];
                        }, this.filterValues);
                    });
                } else {
                    this.filterValues[filter.fieldName] = this.filterParamsSelected[filter.fieldName];
                }
            });
        });
        this.reportsService.setSelectedProperty(true);
    }

    private filtersMapping() {
        if (!this.configuration) return;
        Object.keys(this.configuration.filters).forEach((filterCategory: string) => {
            this.configuration?.filters[filterCategory as keyof IFilters].forEach((filter: IFilterItem) => {
                if (filter.mapping) {
                    Object.keys(filter.mapping).forEach(key => {
                        this.filterParamsSelected[key] = this.getFilterValue(filter.mapping[key]);
                    });
                } else {
                    this.filterParamsSelected[filter.fieldName] = this.filterValues[filter.fieldName];
                }
            });
        });
    }

    private getFilterValue(key: string) {
        return key.split(".").reduce((acc, key) => acc && acc[key], this.filterValues);
    }

    private checkAllowedActions(): void {
        this.canUpdateReport = this.checkUpdatePermission();
        this.canDeleteReport = this.checkUpdatePermission();
        this.canAddToCatalog = this.checkAddToCatalogPermission();
        this.canUpdateCatalog = this.checkUpdateCatalogPermission();
    }

    private checkUpdatePermission(): boolean {
        // * User can execute the update report action if the report type is not a template, which means it is a custom report
        // * and if it is a user custom report or if it is a catalog custom report and only if he/she can edit the catalog
        return !!this.customReportId && (!this.reportData?.isPublic || (this.reportData?.isPublic && this.reportData?.canEditCatalogReport));
    }

    private checkAddToCatalogPermission(): boolean {
        return this.reportData?.canAddToCatalog;
    }

    private checkUpdateCatalogPermission(): boolean {
        // * To update catalog (edit or remove) the report must be public
        return !!this.customReportId && this.reportData?.isPublic && this.reportData?.canEditCatalogReport;
    }
}
