<div *ngIf="config" class="tab-container" [id]="gridTabViewId | idGenerator: 'ngx-gridTabView-'">
    <div class="nav">
        <div [ngClass]="{ active: tab == activeTab, disabled: tab.disabled }" class="nav-item" *ngFor="let tab of config">
            <span (click)="activateTab(tab)">{{ tab.tabTitle }}</span>
        </div>
    </div>

    <ng-container *ngIf="activeTab">
        <div *ngFor="let tab of config">
            <div *ngIf="tab === activeTab">
                <lib-generic-grid [columnsConfig]="tab.columnsConfig" [config]="tab.config" [source]="tab.source"></lib-generic-grid>
            </div>
        </div>
    </ng-container>
</div>
