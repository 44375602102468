<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<ng-container *ngIf="columnsConfig">
    <div class="grid-wrapper" cdkScrollable [id]="genericGridId | idGenerator: 'ngx-genericGrid-'">
        <div class="grid-container">
            <div class="grid-header">
                <div *ngIf="source && source.length > 0 && config?.allowSelection">
                    <input [disabled]="isReadOnly" type="checkbox" [checked]="isAllSelected" (change)="selectAll()" />
                </div>
                <div *ngFor="let column of columnsConfig; let i = index" (click)="headerClicked(column)" [ngClass]="columnClasses[i]">
                    <div>
                        <label *ngIf="!column.headerTemplate; else headerTemplate">{{ column.label }}</label>
                        <ng-template #headerTemplate>
                            <ng-container *ngTemplateOutlet="column.headerTemplate || null"></ng-container>
                        </ng-template>
                        <div class="order-asc-desc" [ngClass]="getColumnClasses(column)">
                            <svg *ngIf="column.sortable" xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
                                <path
                                    d="M3.46012 8.13656V3.35322L1.74346 5.06989L0.793457 4.13656L4.12679 0.803223L7.46012 4.13656L6.51012 5.06989L4.79346 3.35322V8.13656H3.46012Z"
                                    fill="black"
                                />
                                <path
                                    d="M8.81673 6.78337L8.81673 11.5667L10.5334 9.85003L11.4834 10.7834L8.15006 14.1167L4.81673 10.7834L5.76673 9.85003L7.4834 11.5667L7.4834 6.78337L8.81673 6.78337Z"
                                    fill="black"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid-body">
                <ng-container *ngIf="source && source.length > 0; else noRows">
                    <div
                        *ngFor="let item of source; let i = index"
                        (click)="onRowClicked(item, $event)"
                        (mousedown)="onMouseDown($event, item)"
                        class="grid-body-item"
                    >
                        <div *ngIf="config?.allowSelection">
                            <input [disabled]="isReadOnly" type="checkbox" [checked]="isSelected(item)" (change)="selectItem(item)" />
                        </div>
                        <div *ngFor="let column of columnsConfig; let i = index" [ngClass]="columnClasses[i]">
                            <span *ngIf="hasExternalLink && column.key === 'externalLink'" class="externalLinkIcon" [innerHTML]="externalLinkIcon"></span>
                            <div *ngIf="!column.cellTemplate; else customBlock" class="grid-item">
                                <span
                                    [ngStyle]="{ '-webkit-line-clamp': column.maxLines ? column.maxLines : defaultMaxLines }"
                                    libTooltip
                                    [tooltip]="column.tooltip ? item[column.key] : ''"
                                >
                                    {{ item[column.key] }}
                                </span>
                            </div>
                            <ng-template #customBlock>
                                <div class="grid-item">
                                    <span
                                        [ngStyle]="{ '-webkit-line-clamp': column.maxLines ? column.maxLines : defaultMaxLines }"
                                        libTooltip
                                        [tooltip]="column.tooltip ? item[column.key] : ''"
                                    >
                                        <ng-container
                                            *ngTemplateOutlet="
                                                getTemplate(column);
                                                context: {
                                                    $implicit: { object: item, column, index: i }
                                                }
                                            "
                                        >
                                        </ng-container>
                                    </span>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </ng-container>

                <ng-template #noRows>
                    <div class="noRows">
                        <div *ngIf="!config?.noRowsTemplate; else noRowsCustomTemplate">No data was retrieved</div>
                    </div>
                </ng-template>
                <ng-template #noRowsCustomTemplate>
                    <ng-container *ngTemplateOutlet="getNoRowTemplate()"></ng-container>
                </ng-template>
            </div>
        </div>
        <div class="scroll-ref" *ngIf="paginationConfig?.autoLoad" libIntersectionListener (intersectionListener)="goNext()"></div>
    </div>

    <div *ngIf="paginationConfig && !paginationConfig.autoLoad" class="pagination">
        <button (click)="goPrevious()" class="next-prev" [ngClass]="{ disabled: !canGoPrevious() }">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                <mask id="mask0_3689_10678" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                    <rect x="0.606445" y="0.446777" width="20" height="20" fill="black" />
                </mask>
                <g mask="url(#mask0_3689_10678)">
                    <path d="M12.6064 15.4468L7.60645 10.4468L12.6064 5.44678L13.6684 6.50878L9.73145 10.4468L13.6684 14.3848L12.6064 15.4468Z" fill="black" />
                </g>
            </svg>
        </button>
        <ng-container *ngIf="showDotsBefore()">
            <div class="page" (click)="changePage(1)">1</div>
            <div>...</div>
        </ng-container>
        <button
            *ngFor="let item of getPaginationItems()"
            (click)="changePage(item)"
            class="page"
            [ngClass]="{ current: item === paginationConfig.currentPage }"
        >
            {{ item }}
        </button>
        <ng-container *ngIf="showDotsAfter()">
            <div>...</div>
            <div class="page" (click)="changePage(paginationConfig.pagesCount)">{{ paginationConfig.pagesCount }}</div>
        </ng-container>
        <button (click)="goNext()" class="next-prev" [ngClass]="{ disabled: !canGoNext() }">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                <mask id="mask0_3689_10683" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                    <rect x="0.606445" y="0.446777" width="20" height="20" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_3689_10683)">
                    <path d="M8.60643 15.4468L7.54443 14.3848L11.4814 10.4468L7.54443 6.50878L8.60643 5.44678L13.6064 10.4468L8.60643 15.4468Z" fill="black" />
                </g>
            </svg>
        </button>
    </div>
</ng-container>
