import { Component, ElementRef, HostListener, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DateTime, Settings } from "luxon";
import { DateTimeService } from "../../services/date-time.service";

export enum CalendarType {
    MONTH = "Month",
    YEAR = "Year",
    DECADE = "Decade"
}

@Component({
    selector: "lib-calendar",
    templateUrl: "./calendar.component.html",
    styleUrls: ["./calendar.component.scss"]
})
export class CalendarComponent implements OnInit {
    readonly daysHeader = ["S", "M", "T", "W", "T", "F", "S"];

    readonly allCalendarTypes = CalendarType;

    @Input() selectedDate: DateTime | undefined;
    @Output() selectedDateChange = new EventEmitter<DateTime | undefined>();

    @Input() calendarType: CalendarType | undefined;

    @Input() dateFrom: DateTime | undefined;
    @Output() dateFromChange = new EventEmitter<DateTime | undefined>();

    @Input() dateTo: DateTime | undefined;
    @Output() dateToChange = new EventEmitter<DateTime | undefined>();

    @Input()
    dateRangeEnabled: boolean;

    @Input()
    minDate: DateTime | undefined;

    @Input()
    maxDate: DateTime | undefined;

    @Input()
    showAsDisabledAnotherMonthDates: boolean;

    @Input()
    calendarId?: string;

    @Input() showCalendarSelection: boolean;
    @Output() showCalendarSelectionChange = new EventEmitter<boolean>();

    private _currentDisplayDate: DateTime;
    get currentDisplayDate(): DateTime {
        return this._currentDisplayDate;
    }

    @Input()
    set currentDisplayDate(value: DateTime) {
        this._currentDisplayDate = value;
        this.setCalendar();
    }

    @Input()
    calendarTypeDisplay: CalendarType;

    daysMatrix: DateTime[][];
    monthsMatrix: DateTime[][];
    yearsMatrix: DateTime[][];

    @Output() hideCalendar: EventEmitter<void> = new EventEmitter<void>();
    allowHideCalendar = false;

    constructor(
        private readonly dateTimeService: DateTimeService,
        private eRef: ElementRef
    ) {
        this.showAsDisabledAnotherMonthDates = false;
        this.showCalendarSelection = true;
        this._currentDisplayDate = this.dateTimeService.now().startOf("month");
        this.dateRangeEnabled = false;
        this.calendarTypeDisplay = CalendarType.MONTH;
        this.daysMatrix = [];
        this.monthsMatrix = [];
        this.yearsMatrix = [];
    }

    ngOnInit(): void {
        setTimeout(() => {
            Settings.defaultLocale = "en";
            if (this.calendarType) this.calendarTypeDisplay = this.calendarType;
            if (this.selectedDate) this._currentDisplayDate = this.selectedDate.startOf("month");
            if (this.dateFrom) this._currentDisplayDate = this.dateFrom.startOf("month");
            this.setCalendar();
        }, 0);
    }

    navigateToCalendarType(calendarType: CalendarType, event?: MouseEvent): void {
        if (event) {
            event.stopPropagation();
        }
        this.calendarTypeDisplay = calendarType;
    }

    navigateToMonth(value: number): void {
        this.currentDisplayDate = this.currentDisplayDate.plus({ months: value });
        this.setCalendar();
    }

    navigateToYear(value: number): void {
        this.currentDisplayDate = this.currentDisplayDate.plus({ years: value });
        this.setCalendar();
    }

    navigateToDecade(value: number): void {
        this.currentDisplayDate = this.currentDisplayDate.plus({
            years: 10 * value
        });
        this.setCalendar();
    }

    isSelectedDate(date: DateTime): boolean {
        if (!this.showCalendarSelection) return false;

        if (this.dateRangeEnabled) {
            if (this.dateFrom !== undefined && this.dateTo === undefined) return this.dateFrom.equals(date);

            if (this.dateFrom !== undefined && this.dateTo !== undefined) return date >= this.dateFrom && date <= this.dateTo;

            return false;
        } else {
            return this.selectedDate !== undefined ? this.selectedDate.startOf("day").equals(date) : false;
        }
    }

    isSelectedMonth(date: DateTime): boolean {
        if (!this.showCalendarSelection) return false;

        if (this.dateRangeEnabled) {
            if (this.dateFrom !== undefined && this.dateTo === undefined) return this.dateFrom?.startOf("month") === date;

            if (this.dateFrom !== undefined && this.dateTo !== undefined) return date >= this.dateFrom.startOf("month") && date <= this.dateTo.startOf("month");

            return false;
        } else {
            return this.selectedDate?.startOf("month") === date;
        }
    }

    isSelectedYear(date: DateTime): boolean {
        if (!this.showCalendarSelection) return false;

        if (this.dateRangeEnabled) {
            if (this.dateFrom !== undefined && this.dateTo === undefined) return this.dateFrom?.startOf("year") === date;

            if (this.dateFrom !== undefined && this.dateTo !== undefined) return date >= this.dateFrom.startOf("year") && date <= this.dateTo.startOf("year");

            return false;
        } else {
            return this.selectedDate?.startOf("year") === date;
        }
    }

    showAsDisabledAnotherMonthDate(date: DateTime) {
        return this.showAsDisabledAnotherMonthDates && date.month !== this.currentDisplayDate.month;
    }

    isDisabledDate(date: DateTime): boolean {
        const disabled =
            (this.minDate !== undefined && date < this.minDate) ||
            (this.maxDate !== undefined && date > this.maxDate) ||
            this.showAsDisabledAnotherMonthDate(date);
        return disabled;
    }

    isDisabledMonth(date: DateTime): boolean {
        return (
            (this.minDate !== undefined && date.startOf("month") < this.minDate.startOf("month")) ||
            (this.maxDate !== undefined && date.startOf("month") > this.maxDate.startOf("month"))
        );
    }

    isDisabledYear(date: DateTime): boolean {
        return (
            (this.minDate !== undefined && date.startOf("year") < this.minDate.startOf("year")) ||
            (this.maxDate !== undefined && date.startOf("year") > this.maxDate.startOf("year"))
        );
    }

    isToday(date: DateTime): boolean {
        return date.startOf("day").toSeconds() === this.dateTimeService.today().toSeconds();
    }

    monthCalendarTitle(): string {
        return this.currentDisplayDate.monthLong + " " + this.currentDisplayDate.year;
    }

    yearCalendarTitle(): string {
        return this.currentDisplayDate.year.toString();
    }

    decadeCalendarTitle(): string {
        return this.getFirstDecadeYear().year + " - " + (this.getFirstDecadeYear().year + 11);
    }

    onSelectDateClick(selectedDate: DateTime, event?: MouseEvent): void {
        if (event) event.stopPropagation();
        this.onSelectDate(selectedDate);
    }

    onSelectDateKeyDown(selectedDate: DateTime, e: KeyboardEvent): void {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            this.onSelectDate(selectedDate);
        }
    }

    onSelectMonthClick(selectedDate: DateTime, event?: MouseEvent): void {
        if (event) event.stopPropagation();
        this.onSelectMonth(selectedDate);
    }

    onSelectMonthKeyDown(selectedDate: DateTime, e: KeyboardEvent): void {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            this.onSelectMonth(selectedDate);
        }
    }

    onSelectYearClick(selectedDate: DateTime, event?: MouseEvent): void {
        if (event) event.stopPropagation();
        this.onSelectYear(selectedDate);
    }

    onSelectYearKeyDown(selectedDate: DateTime, e: KeyboardEvent): void {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            this.onSelectYear(selectedDate);
        }
    }

    disablePreviousMonth(): boolean {
        return this.minDate !== undefined && this.minDate.startOf("month").equals(this.currentDisplayDate.startOf("month"));
    }

    disableNextMonth(): boolean {
        return this.maxDate !== undefined && this.maxDate.startOf("month").equals(this.currentDisplayDate.startOf("month"));
    }

    disablePreviousYear(): boolean {
        return this.minDate !== undefined && this.minDate.startOf("year").equals(this.currentDisplayDate.startOf("year"));
    }

    disableNextYear(): boolean {
        return this.maxDate !== undefined && this.maxDate.startOf("year").equals(this.currentDisplayDate.startOf("year"));
    }

    disablePreviousDecade(): boolean {
        return (
            this.minDate !== undefined &&
            (this.yearsMatrix[0].some(x => x.equals(this.minDate!.startOf("year"))) ||
                this.yearsMatrix[1].some(x => x.equals(this.minDate!.startOf("year"))) ||
                this.yearsMatrix[2].some(x => x.equals(this.minDate!.startOf("year"))))
        );
    }

    disableNextDecade(): boolean {
        return (
            this.maxDate !== undefined &&
            (this.yearsMatrix[0].some(x => x.equals(this.maxDate!.startOf("year"))) ||
                this.yearsMatrix[1].some(x => x.equals(this.maxDate!.startOf("year"))) ||
                this.yearsMatrix[2].some(x => x.equals(this.maxDate!.startOf("year"))))
        );
    }

    private onSelectDate(selectedDate: DateTime): void {
        if (this.isDisabledDate(selectedDate)) return;

        this.showCalendarSelectionChange.emit(true);

        if (this.dateRangeEnabled) {
            if (this.dateFrom === undefined) {
                this.dateFrom = selectedDate;
            } else {
                if (this.dateTo === undefined) {
                    if (this.dateFrom.startOf("day") <= selectedDate.startOf("day")) {
                        this.dateTo = selectedDate;
                    } else {
                        this.dateTo = this.dateFrom;
                        this.dateFrom = selectedDate;
                    }
                } else {
                    this.dateFrom = selectedDate;
                    this.dateTo = undefined;
                }
                this.dateToChange.emit(this.dateTo);
            }
            this.dateFromChange.emit(this.dateFrom);
        } else {
            this.selectedDate = selectedDate;
            this.selectedDateChange.emit(this.selectedDate);
        }
    }

    private onSelectMonth(selectedDate: DateTime): void {
        if (this.isDisabledMonth(selectedDate)) return;

        this.currentDisplayDate = selectedDate;
        this.selectedDate = selectedDate;
        this.selectedDateChange.emit(this.selectedDate);
    }

    private onSelectYear(selectedDate: DateTime): void {
        if (this.isDisabledYear(selectedDate)) return;

        this.currentDisplayDate = selectedDate;
        this.setCalendar();
        this.navigateToCalendarType(CalendarType.YEAR);
    }

    private setCalendar(): void {
        this.setDaysMatrix();
        this.setMonthsMatrix();
        this.setYearsMatrix();
    }

    private setDaysMatrix(): void {
        let actualDay = this.getFirstCalendarSunday(this.currentDisplayDate.startOf("month"));
        this.daysMatrix = [];

        for (let row = 0; row < 6; row++) {
            const actualRow: DateTime[] = [];

            for (let col = 0; col < 7; col++) {
                actualRow.push(actualDay);
                actualDay = actualDay.plus({ days: 1 });
            }

            this.daysMatrix.push(actualRow);
        }
    }

    private setMonthsMatrix(): void {
        let actualMonth = this.currentDisplayDate.startOf("year");
        this.monthsMatrix = [];

        for (let row = 0; row < 3; row++) {
            const actualRow: DateTime[] = [];

            for (let col = 0; col < 4; col++) {
                actualRow.push(actualMonth);
                actualMonth = actualMonth.plus({ month: 1 });
            }

            this.monthsMatrix.push(actualRow);
        }
    }

    private setYearsMatrix(): void {
        let actualYear = this.getFirstDecadeYear();
        this.yearsMatrix = [];

        for (let row = 0; row < 3; row++) {
            const actualRow: DateTime[] = [];

            for (let col = 0; col < 4; col++) {
                actualRow.push(actualYear);
                actualYear = actualYear.plus({ years: 1 });
            }

            this.yearsMatrix.push(actualRow);
        }
    }

    private getFirstDecadeYear(): DateTime {
        return this.currentDisplayDate.startOf("year").minus({ year: +this.currentDisplayDate.year.toString().slice(-1) + 1 });
    }

    private getFirstCalendarSunday(firstDayOfMonth: DateTime): DateTime {
        return firstDayOfMonth.startOf("day").minus({ days: firstDayOfMonth.weekday });
    }

    @HostListener("document:click", ["$event"])
    clickOut(event: MouseEvent) {
        if (!this.eRef.nativeElement.contains(event.target) && this.allowHideCalendar) {
            this.hideCalendar.emit();
        }
        this.allowHideCalendar = true;
    }
}
