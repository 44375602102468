import {
    EntityCustomFieldValueView,
    OpportunityBidCategoryView,
    OpportunityBuildingContactRoleView,
    OpportunityChangeOrderEstimateView,
    OpportunityCompanyView,
    OpportunityContactView,
    OpportunityEstimateView,
    OpportunityMarketView,
    OpportunityProductTypeView,
    OpportunityStatusHistoryView,
    OpportunityTagView,
    OpportunityUserFavoriteView,
    OpportunityUserView,
    OpportunityView
} from "../services/http/clients/api-proxies";
import { ModelBase } from "./ModelBase";

export class Opportunity extends ModelBase implements OpportunityView {
    organizationId: number | undefined;
    code: string | undefined;
    name: string | undefined;
    projectId: string | undefined;
    description: string | undefined;
    divisionId: number | undefined;
    dueDate: Date | undefined;
    expectedResponseDate: Date | undefined;
    projectStart: Date | undefined;
    projectEnd: Date | undefined;
    bidDate: Date | undefined;
    awardedDate: Date | undefined;
    actualValue: number | undefined;
    actualGrossMarginPercentage: number | undefined;
    actualGrossMarginAmount: number | undefined;
    opportunityValue: number | undefined;
    potentialValue: number | undefined;
    proposedGrossMarginPercentage: number | undefined;
    proposedGrossMarginAmount: number | undefined;
    bidStatusId: number | undefined;
    opportunityStatusId: number;
    opportunityProbabilityId: number | undefined;
    companyId: number | undefined;
    buildingId: number | undefined;
    leadSourceId: number | undefined;
    createdByUserId: number | undefined;
    isActive: boolean;
    organizationName: string | undefined;
    divisionName: string | undefined;
    opportunityStatusName: string | undefined;
    probabilityValue: number | undefined;
    probabilityName: string | undefined;
    companyName: string | undefined;
    companyCityName: string | undefined;
    companyCountryName: string | undefined;
    companyStateName: string | undefined;
    companyZipCode: string | undefined;
    companyContactPhone: string[] | undefined;
    buildingName: string | undefined;
    buildingSize: number | undefined;
    buildingFloorSize: number | undefined;
    addressId: number | undefined;
    line1: string | undefined;
    line2: string | undefined;
    latitude: number | undefined;
    longitude: number | undefined;
    zipCode: string | undefined;
    countryId: number | undefined;
    stateId: number | undefined;
    cityId: number | undefined;
    countryName: string | undefined;
    stateName: string | undefined;
    cityName: string | undefined;
    fullAddress: string | undefined;
    salesUsers: string | undefined;
    companyLine1: string | undefined;
    companyLine2: string | undefined;
    salesUsersCount: number | undefined;
    baseEstimateId: number | undefined;
    leadSourceName: string | undefined;
    contactEmail: string[] | undefined;
    contactPhone: string[] | undefined;
    contactFullName: string | undefined;
    leadSourceExternalId: string | undefined;
    organizationCode: string | undefined;
    baseEstimateExternalUrl: string | undefined;
    opportunityBidCategories: OpportunityBidCategoryView[] | undefined;
    opportunityBuildingContactRoles: OpportunityBuildingContactRoleView[] | undefined;
    opportunityChangeOrderEstimates: OpportunityChangeOrderEstimateView[] | undefined;
    opportunityContacts: OpportunityContactView[] | undefined;
    opportunityCompanies: OpportunityCompanyView[] | undefined;
    opportunityEstimates: OpportunityEstimateView[] | undefined;
    opportunityMarkets: OpportunityMarketView[] | undefined;
    opportunityProductTypes: OpportunityProductTypeView[] | undefined;
    opportunityStatusHistories: OpportunityStatusHistoryView[] | undefined;
    opportunityTags: OpportunityTagView[] | undefined;
    opportunityUserFavorites: OpportunityUserFavoriteView[] | undefined;
    opportunityUsers: OpportunityUserView[] | undefined;
    opportunitySalespersons: OpportunityUserView[] | undefined;
    customFieldValues: EntityCustomFieldValueView[] | undefined;
    companyCountryCode: string | undefined;
    companyStateCode: string | undefined;
    countryCode: string | undefined;
    stateCode: string | undefined;
    addressLines: string | undefined;
    companyAddressLines: string | undefined;

    constructor() {
        super();
        this.organizationId = undefined;
        this.code = "";
        this.name = "";
        this.description = "";
        this.projectId = "";
        this.divisionId = undefined;
        this.dueDate = undefined;
        this.expectedResponseDate = undefined;
        this.projectStart = undefined;
        this.projectEnd = undefined;
        this.bidDate = undefined;
        this.awardedDate = undefined;
        this.actualGrossMarginPercentage = undefined;
        this.actualGrossMarginAmount = undefined;
        this.actualValue = undefined;
        this.potentialValue = undefined;
        this.proposedGrossMarginPercentage = undefined;
        this.proposedGrossMarginAmount = undefined;
        this.actualGrossMarginAmount = undefined;
        this.bidStatusId = 0;
        this.opportunityStatusId = 0;
        this.opportunityProbabilityId = undefined;
        this.companyId = undefined;
        this.buildingId = undefined;
        this.buildingSize = undefined;
        this.buildingFloorSize = undefined;
        this.leadSourceId = undefined;
        this.createdByUserId = undefined;
        this.isActive = false;
        this.organizationName = "";
        this.divisionName = "";
        this.opportunityStatusName = "";
        this.probabilityValue = undefined;
        this.probabilityName = "";
        this.companyName = "";
        this.companyCityName = "";
        this.companyContactPhone = undefined;
        this.companyCountryName = "";
        this.companyStateName = "";
        this.companyZipCode = "";
        this.buildingName = "";
        this.addressId = undefined;
        this.line1 = "";
        this.line2 = "";
        this.latitude = undefined;
        this.longitude = undefined;
        this.zipCode = "";
        this.countryId = undefined;
        this.stateId = undefined;
        this.cityId = undefined;
        this.countryName = "";
        this.stateName = "";
        this.cityName = "";
        this.fullAddress = "";
        this.salesUsers = "";
        this.salesUsersCount = undefined;
        this.leadSourceName = "";
        this.contactEmail = [];
        this.contactPhone = [];
        this.contactFullName = "";
        this.baseEstimateId = undefined;
        this.baseEstimateExternalUrl = undefined;
        this.leadSourceExternalId = undefined;
        this.organizationCode = undefined;
        this.opportunityBidCategories = [];
        this.opportunityCompanies = [];
        this.opportunityBuildingContactRoles = [];
        this.opportunityChangeOrderEstimates = [];
        this.opportunityContacts = [];
        this.opportunityEstimates = undefined;
        this.opportunityMarkets = [];
        this.opportunityProductTypes = [];
        this.opportunityStatusHistories = [];
        this.opportunityEstimates = [];
        this.opportunityTags = [];
        this.opportunityUserFavorites = [];
        this.opportunityUsers = [];
        this.opportunitySalespersons = [];
        this.organizationCode = "";
        this.companyContactPhone = undefined;
        this.customFieldValues = [];
    }

    static fromInterface(dto: OpportunityView): Opportunity {
        const opportunity = new Opportunity();
        opportunity.mapFromInterface(dto);
        return opportunity;
    }

    isValidName() {
        return !!this.name;
    }
}
