<button
    (click)="openCloseGroups($event)"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    class="tertiary-btn"
>
    group by
</button>


<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOverlayGroupsOpen"
    (overlayOutsideClick)="restoreLastGroupsApplied(); isOverlayGroupsOpen = false"
    (detach)="isOverlayGroupsOpen = false"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-custom-backdrop'"
    (backdropClick)="isOverlayGroupsOpen = false"
>
    <div class="content" [ngClass]="{ show: isOverlayGroupsOpen }">

        <div>
            <button
                *ngFor="let item of groupingLevels"
                class="resetStyles"
                (click)="openLevel(item, $event)"
                [disabled]="!groupIsEnable(item)"
            >
                {{ item.name }}
                <small *ngIf="appliedGroups[item.level - 1]">{{appliedGroups[item.level - 1].headerName}}</small>
            </button>
        </div>
        <div class="buttons-section">
            <button class="resetStyles" (click)="clearSelection()">Clear</button>
            <button class="resetStyles" (click)="applyGroups()">Apply</button>
        </div>

        <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="isOverlayColsOpen"
            (overlayOutsideClick)="isOverlayColsOpen = false"
            (detach)="isOverlayColsOpen = false"
            [cdkConnectedOverlayHasBackdrop]="true"
            [cdkConnectedOverlayBackdropClass]="'cdk-overlay-custom-backdrop'"
            (backdropClick)="isOverlayColsOpen = false"
        >
            <div class="content" [ngClass]="{ show: isOverlayColsOpen }">
                <app-column-selector
                    *ngIf="groupOpened"
                    cdkTrapFocus
                    cdkTrapFocusAutoCapture="true"
                    [cols]="getAvailableCols(groupOpened)"
                    [group]="groupOpened"
                    (selectedColumnChange)="applyGroup(groupOpened, $event)"
                    (closeOverlayEvent)="closeOverlay()"
                ></app-column-selector>
            </div>
        </ng-template>
    </div>

</ng-template>