import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { ReportsService } from "projects/app/src/app/services/reports.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { DateTimeService } from "projects/ngx-lib/src/lib/services/date-time.service";
import { Subscription } from "rxjs";

interface DateRange {
    from?: Date;
    to?: Date;
}

@Component({
    selector: "app-date-range-editor",
    templateUrl: "./date-range-editor.component.html",
    styleUrls: ["./date-range-editor.component.scss"]
})
export class DateRangeEditorComponent extends EditorComponentBase<DateRange | null> implements OnInit, OnDestroy {
    selectedDate: DateRange;
    rangeDirection?: "forward" | "backward" | "both";
    subscription?: Subscription;

    constructor(
        readonly dateTimeService: DateTimeService,
        private reportsService: ReportsService
    ) {
        super();
        this.selectedDate = {
            from: undefined,
            to: undefined
        };
    }
    ngOnInit(): void {
        this.rangeDirection = this.additionalInfo?.rangeDirection;
        this.subscription = this.reportsService.selectedProperty$.subscribe(async ready => {
            if (ready && this.bindingProperty) {
                this.selectedDate = this.bindingProperty;
                this.formatDate(this.selectedDate);
            }
        });
    }

    formatDate(date: DateRange) {
        if (date.to) {
            this.selectedDate.to = this.dateTimeService.formatDateTimeToDate(this.dateTimeService.getDateTimeFromString(date.to.toString()));
        }
        if (date.from) {
            this.selectedDate.from = this.dateTimeService.formatDateTimeToDate(this.dateTimeService.getDateTimeFromString(date.from.toString()));
        }
    }

    onSelectedDateChange() {
        if (this.selectedDate) {
            this.bindingProperty = this.selectedDate;
            this.validate();
        } else {
            this.bindingProperty = null;
        }
        this.valueChange.emit();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
