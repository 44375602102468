<lib-generic-dropdown
    [source]="items"
    [config]="{
        multiple: false,
        defaultText: defaultText,
        itemDisplayKey: 'name'
    }"
    [(selectedItem)]="selectedItem"
    (selectedItemChange)="onSelectedItemChange()"
>
</lib-generic-dropdown>