import { Injectable } from "@angular/core";
import { ICustomReportUserFavoriteClient, CustomReportUserFavoriteClient} from ".././reporting-api-proxies";

@Injectable({
    providedIn: "root"
})
export class CustomReportUserFavoriteService {
    private client: ICustomReportUserFavoriteClient;
    constructor() {
        this.client = new CustomReportUserFavoriteClient();
    }

    async toggleFavorite(id: number): Promise<boolean> {
        return await this.client.customReportUserFavoriteToggleFavorite(id);
    }
}