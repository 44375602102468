import { Component, Input } from "@angular/core";

export type Orientation = "vertical" | "horizontal";
export type Context = "header" | "subHeader" | "default";

@Component({
    selector: "lib-separator-line",
    templateUrl: "./separator-line.component.html",
    styleUrls: ["./separator-line.component.scss"]
})
export class SeparatorLineComponent {
    @Input()
    orientation?: Orientation;

    @Input()
    context?: Context;

    @Input()
    separatorLineId?: string;

    constructor() {
        this.context = "default";
        this.orientation = "vertical";
    }
}
