import { Component } from "@angular/core";
import { ReportingContactService } from "projects/app/src/app/services/http/clients/reporting-app/reporting-contact.service";
import { MultipleTypeAheadValuesEditorComponent } from "../multiple-type-ahead-values-editor/multiple-type-ahead-values-editor.component";
import { ReportsService } from "../../../../../services/reports.service";

@Component({
    selector: "app-reporting-contact-editor",
    templateUrl: "../multiple-type-ahead-values-editor/multiple-type-ahead-values-editor.component.html",
    styleUrls: ["../multiple-type-ahead-values-editor/multiple-type-ahead-values-editor.component.scss"]
})
export class ReportingContactEditorComponent extends MultipleTypeAheadValuesEditorComponent {
    constructor(reportingUserService: ReportingContactService, reportsService: ReportsService) {
        super(reportingUserService, reportsService);
    }
}
