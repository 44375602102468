import { Overlay, ScrollStrategy } from "@angular/cdk/overlay";
import { Component, HostListener, Input, TemplateRef } from "@angular/core";
import { animate, style, transition, trigger } from "@angular/animations";
import { ViewChildren, QueryList, ElementRef } from "@angular/core";

export interface IDropdownPanelItem {
    id: string;
    name: string;
    action?: () => void;
}

export interface IDropdownPanel {
    items: IDropdownPanelItem[];
}

@Component({
    selector: "lib-dropdown-panel",
    templateUrl: "./dropdown-panel.component.html",
    styleUrls: ["./dropdown-panel.component.scss"],
    animations: [
        trigger("dropdownPanelAnimation", [
            transition(":enter", [
                style({ opacity: 0, transform: "translateY(-10px)" }),
                animate("0.3s ease-out", style({ opacity: 1, transform: "translateY(0)" }))
            ]),
            transition(":leave", [
                style({ opacity: 1, transform: "translateY(0)" }),
                animate("0.3s ease-in", style({ opacity: 0, transform: "translateY(-10px)" }))
            ])
        ])
    ]
})
export class DropdownPanelComponent {
    @ViewChildren("menuItem") menuItems!: QueryList<ElementRef>;

    protected scrollStrategy: ScrollStrategy;

    @Input()
    buttonTemplateRef!: TemplateRef<unknown>;

    @Input()
    contentTemplateRef!: TemplateRef<unknown>;

    @Input()
    textContent!: string;

    @Input()
    source: IDropdownPanel;

    @Input()
    dropdownPanelId?: string;

    opened: boolean;

    constructor(private readonly overlay: Overlay) {
        this.opened = false;
        this.source = {
            items: []
        };
        this.scrollStrategy = this.overlay.scrollStrategies.close();
    }

    openClose(event: Event) {
        event.stopPropagation();
        this.opened = !this.opened;
        if (this.opened) {
            setTimeout(() => {
                const firstItem = this.menuItems.first;
                if (firstItem) {
                    firstItem.nativeElement.focus();
                }
            }, 0);
        }
    }

    action(callback: () => void | undefined, event: Event) {
        event.stopPropagation();
        this.opened = false;
        callback();
    }

    @HostListener("document:keydown.escape")
    onEscape(): void {
        if (this.opened) {
            const activeElement = document.activeElement as HTMLElement;
            if (activeElement) {
                activeElement.blur();
            }
            this.opened = false;
        }
    }
}
