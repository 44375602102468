import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IListElement } from "./list-element/list-element.component";

@Component({
    selector: "app-titled-list-group",
    templateUrl: "./titled-list-group.component.html",
    styleUrls: ["./titled-list-group.component.scss"]
})
export class TitledListGroupComponent {
    @Input()
    title?: string;

    @Input()
    items: IListElement[];

    @Output()
    isFavoriteChange: EventEmitter<IListElement>;

    constructor() {
        this.items = [];
        this.isFavoriteChange = new EventEmitter<IListElement>();
    }

    onFavoriteChange(item: IListElement) {
        this.isFavoriteChange.emit(item);
    }
}
