import { Injectable } from "@angular/core";
import {
    BoxResultType,
    AwardedEstimateClient,
    CustomReportAwardedEstimateView,
    GetEstimateAwardedValuesParameters,
    GetEstimateAwardedColumnChartValuesResultType,
    GetEstimateAwardedValuesResultType,
    GetEstimateAwardedLineChartValuesResultType,
    GetEstimateAwardedMetricsValuesResultType,
    SharedReportDto
} from "./reporting-api-proxies";
import { CustomReportServiceBase } from "./custom-report/custom-report-base.service";

@Injectable({
    providedIn: "root"
})
export class AwardedEstimateService extends CustomReportServiceBase<AwardedEstimateClient, CustomReportAwardedEstimateView> {
    constructor() {
        super(new AwardedEstimateClient());
    }

    async getEstimateValues(
        parameters: GetEstimateAwardedValuesParameters
    ): Promise<GetEstimateAwardedValuesResultType[]> {
        return await this.client.awardedEstimateGetEstimateAwardedValues(parameters);
    }

    async getEstimateBoxesValues(parameters: GetEstimateAwardedValuesParameters): Promise<BoxResultType[]> {
        return await this.client.awardedEstimateGetEstimateAwardedBoxesValues(parameters);
    }

    async getEstimateColumnChartValues(parameters: GetEstimateAwardedValuesParameters): Promise<GetEstimateAwardedColumnChartValuesResultType[]> {
        return await this.client.awardedEstimateGetEstimateAwardedColumnChartValues(parameters);
    }

    async getEstimateMetricsValues(parameters: GetEstimateAwardedValuesParameters): Promise<GetEstimateAwardedMetricsValuesResultType> {
        return await this.client.awardedEstimateGetEstimateAwardedMetricsValues(parameters);
    }

    async getEstimateLineChartValues(parameters: GetEstimateAwardedValuesParameters): Promise<GetEstimateAwardedLineChartValuesResultType[]> {
        return await this.client.awardedEstimateGetEstimateAwardedLineChartValues(parameters);
    }

    override async saveCustomReport(body: CustomReportAwardedEstimateView): Promise<CustomReportAwardedEstimateView> {
        if (body.isPublic)
            return await this.client.awardedEstimateSaveCatalogReport(body);
        else
            return await this.client.awardedEstimateSaveCustomReport(body);
    }

    override async getCustomReport(id: number): Promise<CustomReportAwardedEstimateView> {
        return await this.client.awardedEstimateGetCustomReport(id);
    }

    override async shareReport(body: CustomReportAwardedEstimateView): Promise<SharedReportDto> {
        return await this.client.awardedEstimateCreateSharedReport(body);
    }

    override async getSharedReport(id: string): Promise<CustomReportAwardedEstimateView> {
        return this.client.awardedEstimateGetSharedReport(id);
    }
}
