import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalService } from "projects/ngx-lib/src/lib/services/modal.service";
import { SnackbarNotificationService } from "projects/ngx-lib/src/lib/services/snackbar-notification.service";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { ModalConfirmComponent } from "projects/app/src/app/components/shared/modals/modal-confirm/modal-confirm.component";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";
import { AccountService } from "projects/app/src/app/services/http/clients/account.service";
import { Account } from "projects/app/src/app/models/Account";
import { AccountsSingleOverviewComponent } from "../accounts-single/accounts-single-overview/accounts-single-overview.component";

@Component({
    selector: "app-accounts-edit",
    templateUrl: "./accounts-edit.component.html",
    styleUrls: ["./accounts-edit.component.scss"]
})
export class AccountsEditComponent implements OnInit {
    account: Account;

    accountId: number;

    buttonLabel?: string;

    continueButtonLabel?: string;

    title?: string;

    @ViewChild("overview")
    overview?: AccountsSingleOverviewComponent;

    constructor(
        private readonly awaiter: AwaiterService,
        private readonly accountService: AccountService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly modalService: ModalService,
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {
        this.account = new Account();
        this.accountId = parseInt(this.route.snapshot.paramMap.get("id") ?? "0");
        this.buttonLabel = "Create";
        this.continueButtonLabel = "Continue Creation";
        this.title = "Create New Account";
    }

    async ngOnInit(): Promise<void> {
        if (this.accountId) {
            this.buttonLabel = "Save";
            this.continueButtonLabel = "Continue Edition";
            this.title = "Edit Account";

            await this.awaiter.awaitAction("Loading Account", async () => {
                const view = await this.accountService.getById(this.accountId);

                if (view) {
                    this.account = Account.fromInterface(view);
                }
            });
        }
    }

    async saveAccount(): Promise<void> {
        if (!this.overview?.mainInfoCard?.validate()) return;

        await this.awaiter.awaitAction("Saving Account", async () => {
            await this.accountService.save(this.account);
            this.snackbarNotificationService.success("Account saved successfully.");
            this.router.navigate(this.accountId ? [`/accounts-single/${this.accountId}/${TabNames[TabNames.Overview]}`] : ["/accounts"]);
        });
    }

    async cancel(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Cancel Editing",
            cancelCaption: this.continueButtonLabel,
            content: "Are you sure you want to exit? Your changes will be lost",
            title: "Cancel Save Account"
        });

        if (responseOk) {
            this.router.navigate(this.accountId ? [`/accounts-single/${this.accountId}/${TabNames[TabNames.Overview]}`] : ["/accounts"]);
        }
    }
}
