import { Component } from "@angular/core";
import { ReportWidgetComponent } from "../report-widget.component";

@Component({
    selector: "app-chart-widget",
    templateUrl: './chart-widget.component.html',
    styles: [
        `
            .chart-widget {
                border: 1px solid blue;
            }
        `
    ]
})
export class ChartWidgetComponent<TService, TParameters> extends ReportWidgetComponent<TService, TParameters> {
    chartData: any;

    override setResultData(result: unknown): void {
        this.chartData = result;
    }
}
