import { AfterViewInit, Component, Input, ViewContainerRef } from "@angular/core";
import { IReportInstance } from "projects/app/src/app/components/pages/reports/types/report-instance.interface";
import { LayoutType } from "projects/app/src/app/components/pages/reports/types/report-layout-type.enum";
import { IBase } from "projects/app/src/app/components/pages/reports/types/report-config.interface";
import { IReportWidgetConfiguration } from "projects/app/src/app/components/pages/reports/types/report-widget-config.interface";

@Component({
    selector: "app-report-section",
    templateUrl: "./report-section.component.html",
    styleUrls: ["./report-section.component.scss"]
})
export class ReportSectionComponent<TService> implements AfterViewInit {
    @Input() config?: IBase<TService>;
    @Input() searchConfig: any;

    @Input() reportFactory: any;
    @Input() widgetsConfig?: IReportWidgetConfiguration<TService>[];
    @Input() layoutConfig?: LayoutType;

    private reportInstance!: IReportInstance<any, any>;

    constructor(private readonly viewContainer: ViewContainerRef) {}

    async ngAfterViewInit() {
        if (this.config && this.reportFactory) {
            await this.createReport(this.config);
        }
    }

    async createReport(config: IBase<TService>) {
        this.viewContainer.clear();
        const componentRef = this.reportFactory.getComponent(this.viewContainer);
        const hostEl = this.viewContainer.element.nativeElement;
        hostEl.insertBefore(componentRef.location.nativeElement, hostEl.firstChild);

        componentRef.location.nativeElement.firstChild.style.gridTemplateAreas = this.layoutConfig;

        this.reportInstance = this.reportFactory.getReportInstance(config, this.viewContainer);
        if (this.widgetsConfig?.length) await this.reportInstance.build(config, this.widgetsConfig);
    }
}
