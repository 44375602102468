import { Component, OnInit } from "@angular/core";
import { ReportsService } from "projects/app/src/app/services/reports.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { Subscription } from "rxjs";

interface DropdownRangesValues {
    from?: number;
    to?: number;
}

@Component({
    selector: "app-dropdown-ranges-editor",
    templateUrl: "./dropdown-ranges-editor.component.html",
    styleUrls: ["./dropdown-ranges-editor.component.scss"]
})
export class DropdownRangesEditorComponent extends EditorComponentBase<DropdownRangesValues> implements OnInit {
    selectedValue: DropdownRangesValues;
    iconType?: string;
    minValue?: number;
    maxValue?: number;
    subscription?: Subscription;

    constructor(private reportsService: ReportsService) {
        super();
        this.selectedValue = {
            from: undefined,
            to: undefined
        };
    }

    ngOnInit(): void {
        this.subscription = this.reportsService.selectedProperty$.subscribe(async ready => {
            if (this.bindingProperty && ready) {
                this.selectedValue = this.bindingProperty;
            }
            this.iconType = this.additionalInfo?.iconType;
            this.minValue = this.additionalInfo?.minValue;
            this.maxValue = this.additionalInfo?.maxValue;
        });
    }

    onApplyClicked() {
        this.bindingProperty = this.selectedValue;
        this.valueChange.emit();
    }

    onClearClicked() {
        this.bindingProperty = this.selectedValue;
        this.valueChange.emit();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
