import { Component, OnDestroy, OnInit } from "@angular/core";
import { BaseDtoSearchService, IBaseDto } from "projects/app/src/app/services/http/clients/reporting-app/base-dto.service";
import { ReportsService } from "projects/app/src/app/services/reports.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { Subscription } from "rxjs";

@Component({
    selector: "app-single-type-ahead-value-editor",
    templateUrl: "./single-type-ahead-value-editor.component.html",
    styleUrls: ["./single-type-ahead-value-editor.component.scss"]
})
export class SingleTypeAheadValueEditorComponent extends EditorComponentBase<number | null> implements OnInit, OnDestroy {
    searchText: string;
    items: IBaseDto[];
    selectedItem?: IBaseDto;
    subscription?: Subscription;

    constructor(
        private baseDtoSearchService: BaseDtoSearchService,
        private reportsService: ReportsService
    ) {
        super();
        this.searchText = "";
        this.items = [];
    }

    async ngOnInit(): Promise<void> {
        this.subscription = this.reportsService.selectedProperty$.subscribe(async ready => {
            if (this.bindingProperty && ready) {
                this.selectedItem = await this.baseDtoSearchService.getById(this.bindingProperty);
            }
        });
    }

    async onTextChanged(): Promise<void> {
        if (!this.searchText) return;
        this.items = await this.baseDtoSearchService.search(this.searchText);
    }

    onSelectedItem() {
        this.bindingProperty = this.selectedItem?.id ?? null;
        this.valueChange.emit();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
