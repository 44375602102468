<header class="header" *ngIf="isLoggedIn">
    <app-organization-switcher *ngIf="!isReportsUrl"></app-organization-switcher>

    <div class="search">
        <ng-container *ngIf="user">
            <app-search-main-entities></app-search-main-entities>
        </ng-container>
    </div>

    <div class="panels">
        <ng-container *ngIf="user">
            <app-user-apps-panel></app-user-apps-panel>
            <app-notifications-panel></app-notifications-panel>
            <app-user-panel [user]="user" [showSubheading]="false"></app-user-panel>
        </ng-container>
    </div>
</header>
