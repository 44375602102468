<div class="title">
    <button (click)="closeOverlay()">back</button>
    {{group.name}}
</div>
<div class="content">
    <button
        class="resetStyles"
        [ngClass]="{highlight: selectedColumn?.headerName === col.headerName}"
        *ngFor="let col of cols"
        (click)="selectColumn(col)"
    >{{col.headerName}}</button>
</div>
<div class="buttons-section">
    <!-- <button class="resetStyles" (click)="clearSelection()">Clear</button> -->
    <button class="resetStyles" (click)="applySelection()">Apply</button>
</div>




