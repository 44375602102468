import { Component } from "@angular/core";
import { MultipleTypeAheadValuesEditorComponent } from "../multiple-type-ahead-values-editor/multiple-type-ahead-values-editor.component";
import { ReportingOpportunitiesSearchService } from "projects/app/src/app/services/http/clients/reporting-app/reporting-opportunities-search.service";
import { ReportsService } from "../../../../../services/reports.service";

@Component({
    selector: "app-reporting-opportunity-search-editor",
    templateUrl: "../multiple-type-ahead-values-editor/multiple-type-ahead-values-editor.component.html",
    styleUrls: ["../multiple-type-ahead-values-editor/multiple-type-ahead-values-editor.component.scss"]
})
export class ReportingOpportunitySearchEditorComponent extends MultipleTypeAheadValuesEditorComponent {
    constructor(reportingOpportunitySearchService: ReportingOpportunitiesSearchService, reportsService: ReportsService) {
        super(reportingOpportunitySearchService, reportsService);
        this.placeholder = "Search Opportunities";
    }
}
