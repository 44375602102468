<lib-generic-type-ahead-dropdown
    [(selectedItem)]="selectedUUID"
    placeholder="{{ placeholder || 'Select UUID' }}"
    (selectedItemChange)="onUUIDChange($event)"
    [(text)]="searchText"
    [source]="sourceUUID"
    (textChange)="onUUIDTextChanged()"
    [config]="{
        defaultText: 'Select UUID',
        itemDisplayKey: 'name',
        itemTemplate: uuidTemplate
    }"
    [isReadOnly]="isReadOnly"
    [error]="error"
    [isItemDisabled]="disabled"
>
</lib-generic-type-ahead-dropdown>
<ng-template #uuidTemplate let-data>
    <app-custom-menu-item
        [inputText]="data.inputText"
        [title]="data.object.name"
        [subtitle]="data.object.id.toString()"
        [titlePrefix]="data.object.opportunityId ? { text: 'Already Linked', textColor: 'danger' } : { text: 'Available', textColor: 'success' }"
        [disabled]="data.object.opportunityId"
    >
    </app-custom-menu-item>
</ng-template>
