export const mapping: Record<string, string> = {
    opportunityCreationDateFrom: "opportunityCreationDate.from",
    opportunityCreationDateTo: "opportunityCreationDate.to",
    opportunityDueDateFrom: "opportunityDueDate.from",
    opportunityDueDateTo: "opportunityDueDate.to",
    projectStartDateFrom: "projectStartDate.from",
    projectStartDateTo: "projectStartDate.to",
    projectEndDateFrom: "projectEndDate.from",
    projectEndDateTo: "projectEndDate.to",
    potentialValueFrom: "potentialValue.from",
    potentialValueTo: "potentialValue.to",
    probabilityValueFrom: "probabilityValue.from",
    probabilityValueTo: "probabilityValue.to",
    expectedResponseDateTo: "expectedResponseDate.to",
    expectedResponseDateFrom: "expectedResponseDate.from",
    bidDateFrom: "bidDate.from",
    bidDateTo: "bidDate.to",
    bidAmountFrom: "bidAmount.from",
    bidAmountTo: "bidAmount.to",
    awardedDateFrom: "awardedDate.from",
    awardedDateTo: "awardedDate.to",
    awardedAmountFrom: "awardedAmount.from",
    awardedAmountTo: "awardedAmount.to",
    projectDueDateFrom: "projectDueDate.from",
    projectDueDateTo: "projectDueDate.to"
};

export const inverseMapping: Record<string, string> = {
    "opportunityCreationDate.from": "opportunityCreationDateFrom",
    "opportunityCreationDate.to": "opportunityCreationDateTo",
    "opportunityDueDate.from": "opportunityDueDateFrom",
    "opportunityDueDate.to": "opportunityDueDateTo",
    "projectStartDate.from": "projectStartDateFrom",
    "projectStartDate.to": "projectStartDateTo",
    "projectEndDate.from": "projectEndDateFrom",
    "projectEndDate.to": "projectEndDateTo",
    "potentialValue.from": "potentialValueFrom",
    "potentialValue.to": "potentialValueTo",
    "probabilityValue.from": "probabilityValueFrom",
    "probabilityValue.to": "probabilityValueTo",
    "expectedResponseDate.to": "expectedResponseDateTo",
    "expectedResponseDate.from": "expectedResponseDateFrom",
    "bidDate.from": "bidDateFrom",
    "bidDate.to": "bidDateTo",
    "bidAmount.from": "bidAmountFrom",
    "bidAmount.to": "bidAmountTo",
    "awardedDate.from": "awardedDateFrom",
    "awardedDate.to": "awardedDateTo",
    "awardedAmount.from": "awardedAmountFrom",
    "awardedAmount.to": "awardedAmountTo"
};
