import { ComponentRef, ViewContainerRef } from "@angular/core";
import { IReportConfiguration } from "../../components/pages/reports/types/report-config.interface";
import { ReportInstanceComponent } from "../../components/pages/reports/reports-single/report-section/report-section-instance/report-instance.component";
import { IReportInstance } from "../../components/pages/reports/types/report-instance.interface";
import { IReportFactory } from "../../components/pages/reports/types/report-factory.interface";

export class ReportSectionFactory implements IReportFactory {
    reportComponentRef?: ComponentRef<ReportInstanceComponent<any, any>>;

    create(viewContainer: ViewContainerRef) {
        this.reportComponentRef = viewContainer?.createComponent(ReportInstanceComponent);
    }

    getComponent(viewContainer: ViewContainerRef) {
        this.create(viewContainer);
        return this.reportComponentRef;
    }

    getReportInstance<TService, TParameters>(config: IReportConfiguration<TService>, viewContainer: ViewContainerRef): IReportInstance<TService, TParameters> {
        if (!this.reportComponentRef) throw new Error("Unable to instantiate the report component.");
        return this.reportComponentRef.instance as IReportInstance<TService, TParameters>;
    }
}
