<a
    class="container"
    tabindex="0"
    *ngIf="url"
    href="{{ url }}"
    target="_blank"
    rel="noopener noreferrer"
    title="{{ url }}"
    [ngClass]="{ iconOnly: !message }"
    [id]="externalLinkId | idGenerator: 'ngx-externalLink-'"
>
    <span class="icon" [innerHTML]="externalLinkIcon"></span>
    <span *ngIf="message" class="message">{{ message }}</span>
</a>
