<div class="container">
    <lib-generic-type-ahead-multiselect
        [isReadOnly]="false"
        [source]="userItems"
        [selectedItems]="selectedUsers"
        (selectedItemsChange)="onSelectedUserChange($event)"
        (removeItemChange)="onRemoveUserChange()"
        [config]="{
            defaultText: 'Add User',
            itemDisplayKey: 'userFullName',
            itemTemplate: userTemplate
        }"
        [placeholder]="(isReadOnly && !bindingProperty) || !label ? 'Select an Item' : 'Add ' + label"
        (textChange)="onUserTextChange($event)"
        removeKey="userExternalId"
        [isReadOnly]="isReadOnly"
        [error]="!isValid"
    >
    </lib-generic-type-ahead-multiselect>
</div>

<ng-template #userTemplate let-data>
    <app-custom-menu-item
        [inputText]="data.inputText"
        [title]="data.object?.userFirstName + ' ' + data.object?.userLastName"
        [subtitle]="data.object?.userEmail"
    >
    </app-custom-menu-item>
</ng-template>
