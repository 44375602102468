import { Injectable } from "@angular/core";
import { IDivisionClient, DivisionClient, SearchDivisionsParameters } from "./reporting-api-proxies";
import { BaseDtoService, IBaseDto } from "./base-dto.service";

@Injectable({
    providedIn: "root"
})
export class DivisionService extends BaseDtoService {
    private client: IDivisionClient;
    constructor() {
        super();
        this.client = new DivisionClient();
    }

    async getAll(): Promise<IBaseDto[]> {
        return await this.client.divisionSearch({} as SearchDivisionsParameters);
    }

    async getById(id: number): Promise<IBaseDto> {
        return await this.client.divisionGetById(id);
    }

    async getByIds(divisionIds: number[]): Promise<IBaseDto[]> {
        return await this.client.divisionSearch({ divisionIds } as SearchDivisionsParameters);
    }
}
