import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";

export interface IListElement {
    id: number;
    title?: string;
    subtitle?: string;
    link?: string;
    isFavorite: boolean;
}
@Component({
    selector: "app-list-element",
    templateUrl: "./list-element.component.html",
    styleUrls: ["./list-element.component.scss"]
})
export class ListElementComponent {
    @Input()
    title?: string;

    @Input()
    subtitle?: string;

    @Input()
    link?: string;

    @Input()
    isFavorite: boolean;

    @Output()
    isFavoriteChange: EventEmitter<boolean>;

    constructor(private readonly router: Router) {
        this.link = "";
        this.isFavorite = false;
        this.isFavoriteChange = new EventEmitter<boolean>();
    }

    navigate(url?: string) {
        this.router.navigate([url]);
    }

    onFavoriteChange(event: boolean) {
        this.isFavoriteChange.emit(event);
    }
}
