export enum EditorType {
    numberInput = "number-input",
    textInput = "text-input",
    toggle = "toggle",
    checkbox = "checkbox",
    currencyInput = "currency-input",
    building = "building",
    account = "account",
    text = "text",
    division = "division",
    date = "date",
    dateRange = "date-range",
    company = "company",
    opportunityContact = "opportunity-contact",
    opportunityProbability = "opportunity-probability",
    opportunityStatus = "opportunity-status",
    leadSource = "lead-source",
    opportunityUser = "opportunity-user",
    link = "link",
    multiInput = "multi-input",
    entity = "entity",
    buildingClass = "building-class",
    legalStatus = "legal-status",
    companyType = "company-type",
    textarea = "textarea",
    companyMainContact = "company-main-contact",
    percentageInput = "percentage-input",
    buildingContactRole = "building-contact-role",
    address = "address",
    contact = "contact",
    user = "user",
    noteUser = "note-user",
    estimateType = "estimate-type",
    market = "market",
    state = "state",
    activeStatus = "active-status",
    dropdownRanges = "dropdown-ranges",
    reportingMarket = "reporting-market",
    reportingUser = "reporting-user",
    reportingDivision = "reporting-division",
    reportingCompany = "reporting-company",
    reportingOpportunitySource = "reporting-opportunity-source",
    reportingOrganization = "reporting-organization",
    reportingOpportunitySearch = "reporting-opportunity-search",
    reportingContact = "reporting-contact",
    reportingProjectStatus = "reporting-project-status",
    reportingProjectSearch = "reporting-project-search",
    reportingOpportunityStatus = "reporting-opportunity-status",
    reportingChangeOrderStatus = "reporting-change-order-status",
    reportingBuildingClass = "reporting-building-class",
    reportingLegalStatus = "reporting-legal-status",
    reportingCompanyType = "reporting-company-type",
    reportingManagerOrganization = "reporting-manager-organization"
}
