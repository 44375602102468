import { Pipe, PipeTransform } from "@angular/core";

/**
 * idGenerator generates unique IDs for elements.
 * This pipe ensures each element receives a distinct ID, useful for dynamic content.
 * It safely bypasses Angular's default sanitization to render the IDs as HTML attributes.
 */

@Pipe({
    name: "idGenerator"
})
export class IdGeneratorPipe implements PipeTransform {
    transform(value?: string, prefix?: string): string {
        if (value) return value;
        return (prefix ? `${prefix}` : "ngx-id-") + Math.random().toString(36).substr(2, 9);
    }
}
