import { Injectable } from "@angular/core";
import {
    DomainValidationResult,
    GetOpportunityActualValuesResultDto,
    IOpportunityClient,
    OpportunityClient,
    OpportunityContactView,
    OpportunityMapResult,
    OpportunityUserView,
    OpportunityView,
    SearchOpportunitiesCalendarParameters,
    SearchOpportunitiesCalendarResult,
    SearchOpportunitiesMapParameters,
    SearchOpportunitiesParameters,
    SearchOpportunitiesResultPaginatedResultDto
} from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class OpportunityService {
    private client: IOpportunityClient;
    constructor() {
        this.client = new OpportunityClient();
    }

    async getById(id: number): Promise<OpportunityView> {
        return await this.client.opportunityGetById(id);
    }

    async getActualValues(id: number): Promise<GetOpportunityActualValuesResultDto> {
        return await this.client.opportunityGetActualValues(id);
    }

    async search(body: SearchOpportunitiesParameters | undefined): Promise<SearchOpportunitiesResultPaginatedResultDto> {
        return await this.client.opportunitySearch(body);
    }

    async searchCalendar(body: SearchOpportunitiesCalendarParameters): Promise<SearchOpportunitiesCalendarResult[]> {
        return await this.client.opportunitySearchCalendar(body);
    }

    async searchMap(body: SearchOpportunitiesMapParameters): Promise<OpportunityMapResult[]> {
        return await this.client.opportunityMapSearch(body);
    }

    async saveUser(body: OpportunityUserView): Promise<OpportunityUserView> {
        return await this.client.opportunitySaveUser(body);
    }

    async saveContact(body: OpportunityContactView): Promise<OpportunityContactView> {
        return await this.client.opportunitySaveContact(body);
    }

    async setAsFavorite(body: number): Promise<void> {
        return await this.client.opportunitySetAsFavorite(body);
    }

    async removeFromFavorite(body: number): Promise<void> {
        return await this.client.opportunityRemoveFromFavorite(body);
    }

    async save(body: OpportunityView): Promise<OpportunityView> {
        return await this.client.opportunitySave(body);
    }

    async validateForEstimateCreation(id: number): Promise<DomainValidationResult[]> {
        return await this.client.opportunityValidateForEstimateCreation(id);
    }

    async availableActions(id: number): Promise<number[]> {
        return await this.client.opportunityAvailableActions(id);
    }

    async deactivate(id: number): Promise<void> {
        return await this.client.opportunityDeactivate(id);
    }

    async activate(id: number): Promise<void> {
        return await this.client.opportunityActivate(id);
    }

    async actualValues(id: number): Promise<GetOpportunityActualValuesResultDto> {
        return await this.client.opportunityGetActualValues(id);
    }
}
