<div
    class="badge"
    [ngClass]="{
        primaryColor: color === 'primary',
        secondaryColor: color === 'secondary',
        tertiaryColor: color === 'tertiary',
        readonly: readonly
    }"
    [id]="badgeId | idGenerator: 'ngx-badge-'"
>
    <span *ngIf="label"
        ><span>{{ label }}</span
        >:</span
    >&emsp;
    <span>{{ item && key ? item[key] : text }}</span>
    <div class="remove" *ngIf="removeButton" (click)="remove($event)" (keyup.enter)="remove($event)" tabindex="0">
        <svg width="10" height="10" viewBox="0 0 10 10">
            <path
                d="M3.16406 7.5L5 5.66406L6.83594 7.5L7.5 6.83594L5.66406 5L7.5 3.16406L6.83594 2.5L5 4.33594L3.16406 2.5L2.5 3.16406L4.33594 5L2.5 6.83594L3.16406 7.5ZM5 10C4.31424 10 3.66753 9.86979 3.0599 9.60938C2.45226 9.34896 1.92057 8.99089 1.46484 8.53516C1.00911 8.07943 0.651042 7.54774 0.390625 6.9401C0.130208 6.33247 0 5.68576 0 5C0 4.30556 0.130208 3.65668 0.390625 3.05339C0.651042 2.45009 1.00911 1.92057 1.46484 1.46484C1.92057 1.00911 2.45226 0.651042 3.0599 0.390625C3.66753 0.130208 4.31424 0 5 0C5.69444 0 6.34332 0.130208 6.94662 0.390625C7.54991 0.651042 8.07943 1.00911 8.53516 1.46484C8.99089 1.92057 9.34896 2.45009 9.60938 3.05339C9.86979 3.65668 10 4.30556 10 5C10 5.68576 9.86979 6.33247 9.60938 6.9401C9.34896 7.54774 8.99089 8.07943 8.53516 8.53516C8.07943 8.99089 7.54991 9.34896 6.94662 9.60938C6.34332 9.86979 5.69444 10 5 10Z"
                fill="#175C89"
            />
        </svg>
    </div>
</div>
