import { Component, Input } from "@angular/core";
import { ValidationError } from "projects/ngx-lib/src/lib/interfaces/validator.interface";

@Component({
    selector: "app-validation-error-message",
    templateUrl: "./validation-error-message.component.html",
    styleUrls: ["./validation-error-message.component.scss"]
})
export class ValidationErrorMessageComponent {
    @Input()
    show?: boolean;

    @Input()
    errors?: ValidationError[];

    @Input()
    showOnlyFirstError: boolean;

    constructor() {
        this.show = false;
        this.showOnlyFirstError = false;
    }
}
