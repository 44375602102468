import { GridLayout, OverviewLayout } from "projects/app/src/app/components/pages/reports/types/report-layout-type.enum";
import { UserProjectsService } from "projects/app/src/app/services/http/clients/reporting-app/user-projects.service";
import { IReportConfiguration } from "../../types/report-config.interface";
import { ReportType } from "../../types/report-type.enum";
import { salesMainFilters } from "./shared/main-filters";
import {
    competingBidderEditorConfig,
    marketsEditorConfig,
    opportunitySourceEditorConfig,
    projectDueDateEditorConfig,
    projectEndDateEditorConfig,
    projectStartDateEditorConfig,
    projectStatusEditorConfig
} from "./shared/additional-filters";
import { WidgetType } from "../../types/report-widget-type.enum";

export const UserProjects: IReportConfiguration<UserProjectsService> = {
    base: {
        type: ReportType.ReportSection,
        serviceType: UserProjectsService
    },
    overviewTab: {
        widgets: [
            {
                methodName: "getUserEstimatesBoxesValues",
                type: WidgetType.Box,
                layoutTemplateArea: "boxes"
            },
            {
                methodName: "getUserEstimatesMetricsValues",
                type: WidgetType.Metrics,
                layoutTemplateArea: "metrics",
                settings: {
                    cols: [
                        { name: "accepted", label: "Accepted" },
                        { name: "rejected", label: "Rejected" },
                        { name: "canceled", label: "Canceled" },
                        { name: "onHold", label: "On-hold" }
                    ],
                    rows: [
                        { name: "Qty", label: "Qty" },
                        { name: "Proportion", label: "Percentage", format: "percent" }
                    ]
                }
            },
            {
                methodName: "getLineChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart",
                settings: {
                    title: {
                        text: "Top 10 Projects by Sell Price"
                    },
                    series: [
                        {
                            type: "line",
                            xKey: "projectName",
                            yKey: "sellPrice",
                            yName: "Sell Price "
                        },
                        {
                            type: "line",
                            xKey: "projectName",
                            yKey: "grossMargin",
                            yName: "GM (%)"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom"
                        },
                        {
                            type: "number",
                            position: "left",
                            title: {
                                text: "Amount"
                            },
                            keys: ["sellPrice"]
                        },
                        {
                            type: "number",
                            position: "right",
                            title: {
                                text: "Percentage"
                            },
                            keys: ["grossMargin"],
                            label: {
                                formatter: function (params: any) {
                                    return params.value * 100 + "%";
                                }
                            }
                        }
                    ]
                }
            },
            {
                methodName: "getColumnChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart2",
                settings: {
                    title: {
                        text: "Team Members Performance"
                    },
                    series: [
                        {
                            type: "bar",
                            xKey: "salesPerson",
                            yKey: "opportunityValue",
                            yName: "Opportunity Value"
                        },
                        {
                            type: "bar",
                            xKey: "salesPerson",
                            yKey: "bidAmount",
                            yName: "Bid Amount"
                        },
                        {
                            type: "bar",
                            xKey: "salesPerson",
                            yKey: "awardedAmount",
                            yName: "Awarded Amount"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom"
                        },
                        {
                            type: "number",
                            position: "left",
                            keys: ["opportunityValue", "bidAmount", "awardedAmount"],
                            title: {
                                text: "Amount"
                            }
                        }
                    ]
                }
            }
        ],
        layout: OverviewLayout.overview4
    },
    gridTab: {
        widgets: [
            {
                methodName: "getUserEstimatesSummaryValues",
                type: WidgetType.Grid,
                layoutTemplateArea: "grid",
                settings: [
                    { field: "projectId", headerName: "Project ID", lockVisible: true },
                    { field: "projectName", headerName: "Project Name", lockVisible: true },
                    { field: "projectDueDate", headerName: "Project Due Date" },
                    { field: "status", headerName: "Project Status" },
                    { field: "organization", headerName: "Organization" },
                    { field: "salesPerson", headerName: "Salesperson" },
                    { field: "sellPrice", headerName: "Sell Price" },
                    { field: "grossMargin", headerName: "GM %" },
                    { field: "totalBuildingSqFt", headerName: "Total Building Sq. Ft." },
                    { field: "pricePerSqFt", headerName: "Sell Price / Sq. Ft." },
                    { field: "markets", headerName: "Market" },
                    { field: "lead", headerName: "Opportunity Source" },
                    { field: "competingBidders", headerName: "Competing Bidder" },
                    { field: "division", headerName: "Division" },
                    { field: "customer", headerName: "Customer" },
                    { field: "potentialValue", headerName: "Potential Value" },
                    { field: "probability", headerName: "Probability" },
                    { field: "speculativeValue", headerName: "Speculative Value" }
                ]
            }
        ],
        layout: GridLayout.grid1
    },
    filters: {
        main: [...salesMainFilters],
        additional: [
            projectDueDateEditorConfig,
            projectStartDateEditorConfig,
            projectEndDateEditorConfig,
            projectStatusEditorConfig,
            marketsEditorConfig,
            opportunitySourceEditorConfig,
            competingBidderEditorConfig
        ]
    }
};
