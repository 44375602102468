<div>
    <input
        type="range"
        :name="name"
        :min="min"
        :max="max"
        :value="selected"
        (change)="onChange($event)"
    />
    <label :for="name">{{ label }}</label>



    <div class="range_container">
        <div class="sliders_control">
            <input #fromSlider id="fromSlider" type="range" value="10" min="0" max="100" (change)="controlFromSlider(fromSlider, toSlider)"/>
            <input #toSlider id="toSlider" type="range" value="40" min="0" max="100" (change)="controlToSlider(fromSlider, toSlider)"/>
        </div>
    </div>

</div>