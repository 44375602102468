import { Injectable } from "@angular/core";
import {
    FinalEstimatesClient,
    GetAwardedFinalEstimatesValuesParameters,
    BoxResultType,
    GetFinalEstimatesColumnChartValuesResultType,
    GetAwardedFinalEstimatesMetricsValuesResultType,
    CustomReportAwardedFinalEstimateView,
    GetExternalAwardedFinalEstimatesValuesResultType,
    GetFinalEstimatesLineChartValuesResultType,
    SharedReportDto
} from "./reporting-api-proxies";
import { CustomReportServiceBase } from "./custom-report/custom-report-base.service";

@Injectable({
    providedIn: "root"
})
export class FinalEstimatesService extends CustomReportServiceBase<FinalEstimatesClient, CustomReportAwardedFinalEstimateView> {
    constructor() {
        super(new FinalEstimatesClient());
    }

    async getAwardedFinalEstimatesMetricsValues(
        parameters: GetAwardedFinalEstimatesValuesParameters
    ): Promise<GetAwardedFinalEstimatesMetricsValuesResultType> {
        return await this.client.finalEstimatesGetAwardedFinalEstimatesMetricsValues(parameters);
    }

    async getAwardedFinalEstimatesBoxesValues(parameters: GetAwardedFinalEstimatesValuesParameters): Promise<BoxResultType[]> {
        return await this.client.finalEstimatesGetAwardedFinalEstimatesBoxesValues(parameters);
    }

    async getAwardedFinalEstimatesValues(parameters: GetAwardedFinalEstimatesValuesParameters): Promise<GetExternalAwardedFinalEstimatesValuesResultType[]> {
        return await this.client.finalEstimatesGetAwardedFinalEstimatesValues(parameters);
    }

    async getFinalEstimatesLineChartValues(parameters: GetAwardedFinalEstimatesValuesParameters): Promise<GetFinalEstimatesLineChartValuesResultType[]> {
        return await this.client.finalEstimatesGetFinalEstimatesLineChartValues(parameters);
    }

    async getFinalEstimatesColumnChartValues(parameters: GetAwardedFinalEstimatesValuesParameters): Promise<GetFinalEstimatesColumnChartValuesResultType[]> {
        return await this.client.finalEstimatesGetFinalEstimatesColumnChartValues(parameters);
    }

    override async saveCustomReport(body: CustomReportAwardedFinalEstimateView): Promise<CustomReportAwardedFinalEstimateView> {
        if (body.isPublic) return await this.client.finalEstimatesSaveCatalogReport(body);
        else return await this.client.finalEstimatesSaveCustomReport(body);
    }

    override async getCustomReport(id: number): Promise<CustomReportAwardedFinalEstimateView> {
        return await this.client.finalEstimatesGetCustomReport(id);
    }

    override async shareReport(body: CustomReportAwardedFinalEstimateView): Promise<SharedReportDto> {
        return await this.client.finalEstimatesCreateSharedReport(body);
    }

    override async getSharedReport(id: string): Promise<CustomReportAwardedFinalEstimateView> {
        return await this.client.finalEstimatesGetSharedReport(id);
    }
}
