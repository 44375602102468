<app-generic-overlay
    [overlayOrigin]="overlayOrigin"
    [mainActionable]="mainActionable"
    [actionButtonsRef]="actionButtonsRef"
>
<div>
    <fieldset>
        <div>
            <input type="checkbox" id="all" name="all" value="All" (change)="selectAll($event.target)" />
        </div>
        <div *ngFor="let option of cols; let i = index">
            <input
                type="checkbox"
                [id]="option.field"
                [name]="i.toString()"
                [value]="option.field"
                [(ngModel)]="checkboxesState[option.field!]"
                (ngModelChange)="onChange($event, option.field!)"
            />
            <label [for]="option.field">{{ option.headerName }}</label>
        </div>
    </fieldset>

    <ng-template #actionButtonsRef let-close="close">
        <button class="tertiary-btn" (click)="apply(); close()">apply</button>
    </ng-template>
</div>

</app-generic-overlay>

<div
    cdkOverlayOrigin
    #overlayOrigin="cdkOverlayOrigin">

    <ng-template
        #mainActionable
        let-openClose="openClose"
    >
        <button
            class="tertiary-btn"
            (click)="openClose()"
        >
            <span class="icon" [innerHTML]="settingsPanel"></span>
        </button>
    </ng-template>
</div>