import { Component, OnDestroy, OnInit } from "@angular/core";
import { ArchiveEntityOptionView } from "projects/app/src/app/services/http/clients/api-proxies";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { ArchiveEntityService } from "projects/app/src/app/services/http/clients/archive-entity.service";
import { ReportsService } from "projects/app/src/app/services/reports.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-active-status-editor",
    templateUrl: "./active-status-editor.component.html",
    styleUrls: ["./active-status-editor.component.scss"]
})
export class ActiveStatusEditorComponent extends EditorComponentBase<boolean | null> implements OnInit, OnDestroy {
    statuses: ArchiveEntityOptionView[];
    selectedStatus?: ArchiveEntityOptionView;
    subscription?: Subscription;

    constructor(
        private archiveEntityService: ArchiveEntityService,
        private reportsService: ReportsService
    ) {
        super();
        this.statuses = [];
    }

    async ngOnInit(): Promise<void> {
        this.statuses = await this.archiveEntityService.getArchiveOptions();

        if (this.bindingProperty !== null) {
            if (this.additionalInfo.application === "reporting") {
                this.subscription = this.reportsService.selectedProperty$.subscribe(async ready => {
                    if (ready) {
                        this.selectedStatus = this.foundItem();
                    }
                });
            } else {
                this.selectedStatus = this.foundItem();
            }
        }
    }

    foundItem() {
        return this.statuses?.find(x => x.value === this.bindingProperty);
    }

    onSelectStatus() {
        this.bindingProperty = this.selectedStatus?.value ?? null;
        this.valueChange.emit();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
