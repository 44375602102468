import { IComponentFactory } from "projects/ngx-lib/src/lib/interfaces/component-factory.interface";
import { Type } from "@angular/core";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { BuildingEditorComponent } from "../../components/shared/card-editors/building-editor/building-editor.component";
import { EditorType } from "./enum/editor-type";
import { DivisionEditorComponent } from "../../components/shared/card-editors/division-editor/division-editor.component";
import { DateEditorComponent } from "../../components/shared/card-editors/date-editor/date-editor.component";
import { CompanyEditorComponent } from "../../components/shared/card-editors/company-editor/company-editor.component";
import { OpportunityProbabilityEditorComponent } from "../../components/shared/card-editors/opportunity-probability-editor/opportunity-probability-editor.component";
import { LeadSourceEditorComponent } from "../../components/shared/card-editors/lead-source-editor/lead-source-editor.component";
import { OpportunityContactEditorComponent } from "../../components/shared/card-editors/opportunity-contact-editor/opportunity-contact-editor.component";
import { OpportunityUserEditorComponent } from "../../components/shared/card-editors/opportunity-user-editor/opportunity-user-editor.component";
import { LinkEditorComponent } from "../../components/shared/card-editors/link-editor/link-editor.component";
import { MultiInputEditorComponent } from "../../components/shared/card-editors/multi-input-editor/multi-input-editor.component";
import { EntityEditorComponent } from "../../components/shared/card-editors/entity-editor/entity-editor.component";
import { BuildingClassEditorComponent } from "../../components/shared/card-editors/building-class-editor/building-class-editor.component";
import { NumberInputEditorComponent } from "../../components/shared/card-editors/number-input-editor/number-input-editor.component";
import { TextInputEditorComponent } from "../../components/shared/card-editors/text-input-editor/text-input-editor.component";
import { LegalStatusEditorComponent } from "../../components/shared/card-editors/legal-status-editor/legal-status-editor.component";
import { CompanyTypeEditorComponent } from "../../components/shared/card-editors/company-type-editor/company-type-editor.component";
import { TextareaEditorComponent } from "../../components/shared/card-editors/textarea-editor/textarea-editor.component";
import { CompanyMainContactEditorComponent } from "../../components/shared/card-editors/company-main-contact-editor/company-main-contact-editor.component";
import { BuildingContactRoleEditorComponent } from "../../components/shared/card-editors/building-contact-role-editor/building-contact-role-editor.component";
import { AddressEditorComponent } from "../../components/shared/card-editors/address-editor/address-editor.component";
import { ContactsEditorComponent } from "../../components/shared/card-editors/contacts-editor/contacts-editor.component";
import { NoteUserEditorComponent } from "../../components/shared/card-editors/note-user-editor/note-user-editor.component";
import { UserEditorComponent } from "../../components/shared/card-editors/user-editor/user-editor.component";
import { OpportunityStatusEditorComponent } from "../../components/shared/card-editors/opportunity-status-editor/opportunity-status-editor.component";
import { CheckboxEditorComponent } from "../../components/shared/card-editors/checkbox-editor/checkbox-editor.component";
import { EstimateTypeEditorComponent } from "../../components/shared/card-editors/estimate-type-editor/estimate-type-editor.component";
import { ToggleEditorComponent } from "../../components/shared/card-editors/toggle-editor/toggle-editor.component";
import { ActiveStatusEditorComponent } from "../../components/shared/card-editors/active-status-editor/active-status-editor.component";
import { MarketEditorComponent } from "../../components/shared/card-editors/market-editor/market-editor.component";
import { StateEditorComponent } from "../../components/shared/card-editors/state-editor/state-editor.component";
import { CurrencyEditorComponent } from "../../components/shared/card-editors/currency-editor/currency-editor.component";
import { CustomPercentageEditorComponent } from "../../components/shared/card-editors/custom-percentage-editor/custom-percentage-editor.component";
import { DropdownRangesEditorComponent } from "../../components/shared/card-editors/dropdown-ranges-editor/dropdown-ranges-editor.component";
import { DateRangeEditorComponent } from "../../components/shared/card-editors/date-range-editor/date-range-editor.component";
import { ReportingMarketEditorComponent } from "../../components/shared/card-editors/reporting-editors/reporting-market-editor/reporting-market-editor.component";
import { ReportingUserEditorComponent } from "../../components/shared/card-editors/reporting-editors/reporting-user-editor/reporting-user-editor.component";
import { ReportingDivisionEditorComponent } from "../../components/shared/card-editors/reporting-editors/reporting-division-editor/reporting-division-editor.component";
import { ReportingCompanyEditorComponent } from "../../components/shared/card-editors/reporting-editors/reporting-company-editor/reporting-company-editor.component";
import { ReportingOpportunitySourceEditorComponent } from "../../components/shared/card-editors/reporting-editors/reporting-opportunity-source-editor/reporting-opportunity-source-editor.component";
import { ReportingOrganizationEditorComponent } from "../../components/shared/card-editors/reporting-editors/reporting-organization-editor/reporting-organization-editor.component";
import { ReportingOpportunitySearchEditorComponent } from "../../components/shared/card-editors/reporting-editors/reporting-opportunity-search-editor/reporting-opportunity-search-editor.component";
import { ReportingContactEditorComponent } from "../../components/shared/card-editors/reporting-editors/reporting-contact-editor/reporting-contact-editor.component";
import { ReportingProjectStatusEditorComponent } from "../../components/shared/card-editors/reporting-editors/reporting-project-status-editor/reporting-project-status-editor.component";
import { ReportingProjectSearchEditorComponent } from "../../components/shared/card-editors/reporting-editors/reporting-project-search-editor/reporting-project-search-editor.component";
import { ReportingOpportunityStatusEditorComponent } from "../../components/shared/card-editors/reporting-editors/reporting-opportunity-status-editor/reporting-opportunity-status-editor.component";
import { AccountEditorComponent } from "../../components/shared/card-editors/account-editor/account-editor.component";
import { ReportingChangeOrderStatusEditorComponent } from "../../components/shared/card-editors/reporting-editors/reporting-change-order-status-editor/reporting-change-order-status-editor.component";
import { ReportingBuildingClassEditorComponent } from "../../components/shared/card-editors/reporting-editors/reporting-building-class-editor/reporting-building-class-editor.component";
import { ReportingLegalStatusEditorComponent } from "../../components/shared/card-editors/reporting-editors/reporting-legal-status-editor/reporting-legal-status-editor.component";
import { ReportingCompanyTypeEditorComponent } from "../../components/shared/card-editors/reporting-editors/reporting-company-type-editor/reporting-company-type-editor.component";
import { ReportingManagerOrganizationEditorComponent } from "../../components/shared/card-editors/reporting-editors/reporting-manager-organization-editor/reporting-manager-organization-editor.component";

export class AppEditorFactory implements IComponentFactory {
    getComponent(componentId: string): Type<EditorComponentBase<unknown>> {
        switch (componentId) {
            case EditorType.numberInput:
                return NumberInputEditorComponent;

            case EditorType.textInput:
                return TextInputEditorComponent;

            case EditorType.toggle:
                return ToggleEditorComponent;

            case EditorType.checkbox:
                return CheckboxEditorComponent;

            case EditorType.currencyInput:
                return CurrencyEditorComponent;

            case EditorType.building:
                return BuildingEditorComponent;

            case EditorType.account:
                return AccountEditorComponent;

            case EditorType.division:
                return DivisionEditorComponent;

            case EditorType.date:
                return DateEditorComponent;

            case EditorType.company:
                return CompanyEditorComponent;

            case EditorType.opportunityProbability:
                return OpportunityProbabilityEditorComponent;

            case EditorType.leadSource:
                return LeadSourceEditorComponent;

            case EditorType.opportunityContact:
                return OpportunityContactEditorComponent;

            case EditorType.opportunityUser:
                return OpportunityUserEditorComponent;

            case EditorType.opportunityStatus:
                return OpportunityStatusEditorComponent;

            case EditorType.link:
                return LinkEditorComponent;

            case EditorType.multiInput:
                return MultiInputEditorComponent;

            case EditorType.entity:
                return EntityEditorComponent;

            case EditorType.buildingClass:
                return BuildingClassEditorComponent;

            case EditorType.legalStatus:
                return LegalStatusEditorComponent;

            case EditorType.companyType:
                return CompanyTypeEditorComponent;

            case EditorType.textarea:
                return TextareaEditorComponent;

            case EditorType.companyMainContact:
                return CompanyMainContactEditorComponent;

            case EditorType.percentageInput:
                return CustomPercentageEditorComponent;

            case EditorType.buildingContactRole:
                return BuildingContactRoleEditorComponent;

            case EditorType.address:
                return AddressEditorComponent;

            case EditorType.contact:
                return ContactsEditorComponent;

            case EditorType.noteUser:
                return NoteUserEditorComponent;

            case EditorType.user:
                return UserEditorComponent;

            case EditorType.estimateType:
                return EstimateTypeEditorComponent;

            case EditorType.activeStatus:
                return ActiveStatusEditorComponent;

            case EditorType.market:
                return MarketEditorComponent;

            case EditorType.state:
                return StateEditorComponent;

            case EditorType.dropdownRanges:
                return DropdownRangesEditorComponent;

            case EditorType.dateRange:
                return DateRangeEditorComponent;

            case EditorType.reportingMarket:
                return ReportingMarketEditorComponent;

            case EditorType.reportingUser:
                return ReportingUserEditorComponent;

            case EditorType.reportingDivision:
                return ReportingDivisionEditorComponent;

            case EditorType.reportingCompany:
                return ReportingCompanyEditorComponent;

            case EditorType.reportingOpportunitySource:
                return ReportingOpportunitySourceEditorComponent;

            case EditorType.reportingOrganization:
                return ReportingOrganizationEditorComponent;

            case EditorType.reportingOpportunitySearch:
                return ReportingOpportunitySearchEditorComponent;

            case EditorType.reportingContact:
                return ReportingContactEditorComponent;

            case EditorType.reportingProjectStatus:
                return ReportingProjectStatusEditorComponent;

            case EditorType.reportingProjectSearch:
                return ReportingProjectSearchEditorComponent;

            case EditorType.reportingOpportunityStatus:
                return ReportingOpportunityStatusEditorComponent;

            case EditorType.reportingChangeOrderStatus:
                return ReportingChangeOrderStatusEditorComponent;

            case EditorType.reportingBuildingClass:
                return ReportingBuildingClassEditorComponent;

            case EditorType.reportingLegalStatus:
                return ReportingLegalStatusEditorComponent;

            case EditorType.reportingCompanyType:
                return ReportingCompanyTypeEditorComponent;

            case EditorType.reportingManagerOrganization:
                return ReportingManagerOrganizationEditorComponent;

            default:
                throw new Error("Component id not recognized as a valid editor");
        }
    }
}
