import { Component, OnDestroy, OnInit } from "@angular/core";
import { SearchStateParameters, StateView } from "projects/app/src/app/services/http/clients/api-proxies";
import { StateService } from "projects/app/src/app/services/http/clients/state.service";
import { ReportsService } from "projects/app/src/app/services/reports.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { Subscription } from "rxjs";

@Component({
    selector: "app-state-editor",
    templateUrl: "./state-editor.component.html",
    styleUrls: ["./state-editor.component.scss"]
})
export class StateEditorComponent extends EditorComponentBase<number | null> implements OnInit, OnDestroy {
    searchText: string;
    states: StateView[];
    selectedState?: StateView;
    subscription?: Subscription;

    constructor(
        private readonly stateService: StateService,
        private reportsService: ReportsService
    ) {
        super();
        this.searchText = "";
        this.states = [];
    }

    async ngOnInit(): Promise<void> {
        if (this.bindingProperty !== null) {
            if (this.additionalInfo.application === "reporting") {
                this.subscription = this.reportsService.selectedProperty$.subscribe(async ready => {
                    if (ready) {
                        this.selectedState = await this.foundItem();
                    }
                });
            } else {
                this.selectedState = await this.foundItem();
            }
        }
    }

    async foundItem(): Promise<StateView | undefined> {
        const stateView = this.bindingProperty ? await this.stateService.getById(this.bindingProperty) : undefined;
        return stateView;
    }

    async onStateTextChanged(): Promise<void> {
        if (!this.searchText) return;

        this.states = await this.stateService.search({
            filterText: this.searchText
        } as SearchStateParameters);
    }

    onSelectState() {
        this.bindingProperty = this.selectedState?.id ?? null;
        this.valueChange.emit();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
