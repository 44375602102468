<lib-section-header [title]="reportData?.name || ''" [iconName]="'goBack'" [subTitle]="reportSubtitle" [actionsTemplate]="actionsTemplate">
</lib-section-header>

<ng-template #actionsTemplate>
    <div class="actions">
        <button class="secondary-btn" (click)="openSaveModal()">Save as custom</button>
        <button *ngIf="canUpdateReport" class="resetStyles tertiary-btn" (click)="updateReport()">
            <div class="icon" [innerHTML]="saveIcon"></div>
        </button>
        <button class="tertiary-btn" (click)="openShareModal()">
            <div class="icon" [innerHTML]="shareIcon"></div>
        </button>
        <app-kebab-menu>
            <button *ngIf="canUpdateReport" role="menuitem" class="resetStyles tertiary-btn" (click)="openSaveModal(true)">Edit</button>
            <button *ngIf="reportId" role="menuitem" class="resetStyles tertiary-btn" (click)="toggleFavorite(reportId)" tabindex="0">
                {{ reportData?.isFavorite ? "Remove Favorite" : "Add to Favorite" }}
            </button>
            <button *ngIf="canDeleteReport" role="menuitem" class="resetStyles tertiary-btn" (click)="deleteReport()">Delete</button>
        </app-kebab-menu>
    </div>
</ng-template>

<div class="filters">
    <app-filters-main [filters]="configuration?.filters?.main"></app-filters-main>

    <app-additional-filters [filters]="configuration?.filters?.additional"></app-additional-filters>

    <button class="primary-btn runButton" (click)="run()" tabindex="0">Run</button>
</div>

<ng-container *ngIf="!templateRun && !customReportId">
    <lib-no-data
        [iconName]="'click'"
        [title]="'It seems there’s nothing here yet.'"
        [text]="'Apply the filters to find relevant content based on your search.'"
    >
    </lib-no-data>
</ng-container>

<div class="tabGroup" [ngClass]="{ hide: !templateRun && !customReportId }">
    <lib-tab-view [(currentTab)]="currentTab" alignment="start" [renderAll]="true">
        <lib-tab-item tabName="Overview" [bodyTemplate]="overviewTab">
            <ng-template #overviewTab>
                <div class="tab overview-tab">
                    <app-report-section
                        *ngIf="reportFactoryOverview"
                        [reportFactory]="reportFactoryOverview"
                        [config]="configuration?.base"
                        [searchConfig]="filterParamsSelected"
                        [widgetsConfig]="configuration?.overviewTab?.widgets"
                        [layoutConfig]="configuration?.overviewTab?.layout"
                    ></app-report-section>
                </div>
            </ng-template>
        </lib-tab-item>
        <lib-tab-item tabName="Grid View" [bodyTemplate]="gridTab">
            <ng-template #gridTab>
                <div class="tab grid-tab">
                    <app-report-section
                        *ngIf="reportFactoryGrid"
                        [reportFactory]="reportFactoryGrid"
                        [config]="configuration?.base"
                        [searchConfig]="filterParamsSelected"
                        [widgetsConfig]="configuration?.gridTab?.widgets"
                        [layoutConfig]="configuration?.gridTab?.layout"
                    ></app-report-section>
                </div>
            </ng-template>
        </lib-tab-item>
    </lib-tab-view>
</div>
