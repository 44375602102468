<div class="filtersContainer">
    <lib-slide-toggle class="filter" label="Starred" [(checked)]="filterValue.onlyFavorites" (checkedChange)="onFiltersChanged()"></lib-slide-toggle>
    <lib-search-input class="filter" [placeholderText]="'Report Name'" [(text)]="filterValue.searchText" (textChange)="onFiltersChanged()"></lib-search-input>
</div>

<div class="listContainer">
    <app-titled-list-group
    *ngFor="let reportGroup of groupedList"
    [title]="reportGroup.title"
    [items]="reportGroup.items"
    (isFavoriteChange)="onToggleFavorite($event)"
></app-titled-list-group>
</div>

<lib-no-data *ngIf="groupedList.length === 0" [iconName]="'emptyData'" [title]="'No Reports available'"></lib-no-data>