import { Injectable, Injector } from "@angular/core";
import { CustomizableEntityService } from "../../services/http/clients/customizable-entity-service";
import { EntityTypes } from "../../models/enums/EntityTypes";
import { DivisionService } from "../../services/http/clients/division.service";
import { ProductTypeService } from "../../services/http/clients/product-type.service";

@Injectable({
    providedIn: "root"
})
export class EntityServiceFactory {
    constructor(private readonly injector: Injector) {}

    getService(entityTypeId: number): CustomizableEntityService | undefined {
        switch (entityTypeId) {
            case EntityTypes.Division:
                return this.injector.get(DivisionService);
            case EntityTypes.ProductType:
                return this.injector.get(ProductTypeService);
            default:
                throw new Error("Unsupported entity type ID.");
        }
    }
}
