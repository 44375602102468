import { Component } from "@angular/core";
import { TooltipPosition } from "../../directives/tooltip.directive";
import { SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "lib-tooltip",
    templateUrl: "./tooltip.component.html",
    styleUrls: ["./tooltip.component.scss"]
})
export class TooltipComponent {
    tooltip: SafeHtml;

    position: TooltipPosition;

    left: number;

    top: number;

    visible: boolean;

    tooltipId?: string;

    constructor() {
        this.tooltip = "";
        this.position = TooltipPosition.ABOVE;
        this.left = 0;
        this.top = 0;
        this.visible = false;
        this.tooltipId = undefined;
    }
}
