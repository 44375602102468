<div class="skeleton-container" [ngClass]="{ 'align-start': config.alignItems }" [id]="skeletonId | idGenerator: 'ngx-skeleton-'">
    <div *ngFor="let skeleton of skeletons" [ngStyle]="getBoxStyle()" class="skeleton">
        <div *ngIf="config.type === 'text'" class="skeleton-text">
            <div class="text"></div>
        </div>

        <div *ngIf="config.type === 'rectangle'" class="skeleton-rectangle">
            <div class="text"></div>
            <div class="rectangle" [ngStyle]="getRectangleStyle()"></div>
        </div>
    </div>
</div>
