import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from "@angular/core";
import { IComponentFactory } from "../../interfaces/component-factory.interface";
import { IEditorItemConfig } from "../../interfaces/editor-item-config.interface";
import { EditorItemComponent } from "../editor-item/editor-item.component";

@Component({
    selector: "lib-generic-card",
    templateUrl: "./generic-card.component.html",
    styleUrls: ["./generic-card.component.scss"]
})
export class GenericCardComponent {
    @Input()
    factory?: IComponentFactory;

    @Input()
    configItems: IEditorItemConfig[];

    @Input()
    title?: string;

    @Input()
    highlight?: boolean;

    @Input()
    genericCardId?: string;

    @Output()
    valueChange: EventEmitter<boolean>;

    @ViewChildren("editorItem")
    editors?: QueryList<EditorItemComponent>;

    constructor() {
        this.factory = undefined;
        this.configItems = [];
        this.highlight = false;
        this.valueChange = new EventEmitter<boolean>();
    }

    emitValueChanged(): void {
        this.valueChange.emit();
    }

    validate(): boolean {
        return this.editors?.map(editorItem => editorItem.validate()).every(editorItem => editorItem) ?? false;
    }

    clearValidationErrors(): void {
        this.editors?.forEach(editorItem => editorItem.clearValidationErrors());
    }
}
