<app-generic-overlay [overlayOrigin]="overlayOrigin" [headerTemplate]="headerTemplate" [mainActionable]="mainActionable" [actionButtonsRef]="actionButtonsRef">
    <ng-template #headerTemplate let-close="close">
        <div class="header">
            <button [innerHTML]="closeIcon" class="resetStyles icon" aria-label="Close panel" (click)="close()"></button>
        </div>
    </ng-template>

    <div class="container">
        <div *ngFor="let configItem of filters">
            <lib-editor-item [configItem]="configItem" [factory]="factory" #editorItem></lib-editor-item>
        </div>
        <ng-template #actionButtonsRef let-close="close">
            <div class="action-buttons">
                <button (click)="close()" class="tertiary-btn hide">Clear Filters</button>
                <button (click)="close()" class="tertiary-btn">Apply</button>
            </div>
        </ng-template>
    </div>
</app-generic-overlay>
<div cdkOverlayOrigin #overlayOrigin="cdkOverlayOrigin">
    <ng-template #mainActionable let-openClose="openClose">
        <button (click)="openClose()" class="tertiary-btn">
            <span class="icon" [innerHTML]="additionalFiltersIcon"></span>
            Additional filters
        </button>
    </ng-template>
</div>
