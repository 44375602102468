import { themeQuartz, iconSetQuartzLight } from "@ag-grid-community/theming";
export const customThemeQuartz = themeQuartz.withPart(iconSetQuartzLight).withParams({
    browserColorScheme: "light",
    columnBorder: false,
    fontFamily: "Roboto",
    fontSize: "12px",
    foregroundColor: "#333333",
    headerBackgroundColor: "#FFFFFF",
    headerFontSize: 14,
    headerFontWeight: 400,
    headerVerticalPaddingScale: 0.7,
    rowVerticalPaddingScale: 0.7,
    sidePanelBorder: true,
    wrapperBorder: false,
    columnDropCellBackgroundColor: "#0095FF26",
    columnDropCellBorder: false,
    cellTextColor: "#333333",
    headerTextColor: "#333333"
});
