<div class="calendarHeader" [id]="fullViewCalendarId | idGenerator: 'ngx-fullViewCalendar-'">
    <div class="mainControls">
        <button class="resetStyles todayButton" (click)="goToToday()">Today</button>
        <div class="dateControls">
            <div class="navigation">
                <button class="resetStyles arrowButton left" (click)="retreatPeriod()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="8" viewBox="0 0 4 8" fill="none">
                        <path d="M4 8L0 4L4 0V8Z" fill="#98B7D2" />
                    </svg>
                </button>
                <button class="resetStyles arrowButton right" (click)="advancePeriod()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="8" viewBox="0 0 4 8" fill="none">
                        <path d="M0 8V0L4 4L0 8Z" fill="#98B7D2" />
                    </svg>
                </button>
            </div>
            <lib-datepicker-dropdown
                [dateRangeEnabled]="false"
                [selectedDate]="selectedDateString"
                (selectedDateChange)="onDatepickerChange($event)"
                [defaultTitle]="customDateText"
                [calendarTypeDisplay]="datepickerCalendarType"
            >
            </lib-datepicker-dropdown>
        </div>

        <span *ngIf="isCurrentPeriod" class="currentIndicator">Current {{ currentView }}</span>
    </div>

    <div class="viewSwitcher" *ngIf="showViewSwitcher">
        <button (click)="setView('day')" class="resetStyles viewButton" [ngClass]="{ selected: currentView === 'day' }">Day</button>
        <button (click)="setView('week')" class="resetStyles viewButton" [ngClass]="{ selected: currentView === 'week' }">Week</button>
        <button (click)="setView('month')" class="resetStyles viewButton" [ngClass]="{ selected: currentView === 'month' }">Month</button>
    </div>
</div>

<lib-calendar-view
    *ngIf="currentView === 'day'"
    [currentView]="currentView"
    [currentPeriod]="currentPeriod"
    [eventsForPeriod]="eventsForPeriod"
    [eventTemplate]="eventTemplate"
    [eventCountPerDay]="eventCountPerDay"
>
</lib-calendar-view>

<lib-calendar-view
    *ngIf="currentView === 'week'"
    [currentView]="currentView"
    [currentPeriod]="currentPeriod"
    [eventsForPeriod]="eventsForPeriod"
    [eventTemplate]="eventTemplate"
    [eventCountPerDay]="eventCountPerDay"
>
</lib-calendar-view>

<lib-calendar-view
    *ngIf="currentView === 'month'"
    [currentView]="currentView"
    [currentPeriod]="currentPeriod"
    [eventsForPeriod]="eventsForPeriod"
    [eventTemplate]="eventTemplate"
    [eventCountPerDay]="eventCountPerDay"
    [eventLimitPerDay]="3"
    (viewMoreClicked)="onViewMoreClicked($event)"
>
</lib-calendar-view>
