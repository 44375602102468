import { Component } from "@angular/core";
import { DivisionService } from "projects/app/src/app/services/http/clients/reporting-app/division.service";
import { MultipleDropdownValuesEditorComponent } from "../multiple-dropdown-values-editor/multiple-dropdown-values-editor.component";
import { ReportsService } from "projects/app/src/app/services/reports.service";

@Component({
    selector: "app-reporting-division-editor",
    templateUrl: "../multiple-dropdown-values-editor/multiple-dropdown-values-editor.component.html",
    styleUrls: ["../multiple-dropdown-values-editor/multiple-dropdown-values-editor.component.scss"]
})
export class ReportingDivisionEditorComponent extends MultipleDropdownValuesEditorComponent {
    constructor(
        private readonly reportingDivisionService: DivisionService,
        reportsService: ReportsService
    ) {
        super(reportingDivisionService, reportsService);
        this.defaultText = "Select Divisions";
    }
}
