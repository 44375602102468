<button
    (click)="openClose($event)"
    id="headerButton"
    aria-haspopup="true"
    aria-controls="menu"
    aria-label="Open and close menu button"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    [ngClass]="{ show: opened }"
    [id]="dropdownPanelId | idGenerator: 'ngx-dropdownPanel-'"
>
    <ng-container *ngIf="buttonTemplateRef; else defaultButtonTemplate" [ngTemplateOutlet]="buttonTemplateRef"></ng-container>
    <ng-template #defaultButtonTemplate>{{ textContent }}</ng-template>
</button>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="opened"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    (overlayOutsideClick)="opened = false"
    (detach)="opened = false"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-custom-backdrop'"
    (backdropClick)="opened = false"
>
    <div class="content" [ngClass]="{ show: opened }" @dropdownPanelAnimation>
        <ng-container *ngIf="contentTemplateRef; else defaultContentTemplate" [ngTemplateOutlet]="contentTemplateRef"></ng-container>

        <ng-template #defaultContentTemplate>
            <ng-container *ngIf="source && source.items">
                <div *ngFor="let item of source.items">
                    <button *ngIf="item.action" #menuItem cdkMenuItem class="resetStyles" tabindex="0" (click)="action(item.action, $event)">
                        {{ item.name }}
                    </button>
                </div>
            </ng-container>
        </ng-template>
    </div>
</ng-template>
