import { ReportSectionFactory } from "./report-section.factory";
import { IReportConfiguration } from "../../components/pages/reports/types/report-config.interface";
import { IAbstractReportFactory, IReportFactory } from "../../components/pages/reports/types/report-factory.interface";
import { ReportType } from "../../components/pages/reports/types/report-type.enum";

export class AbstractReportFactory implements IAbstractReportFactory {
    getConcreteFactory<TService>(config: IReportConfiguration<TService>): IReportFactory {
        if (config.base.type === ReportType.ReportSection) return new ReportSectionFactory();

        throw new Error("Report type not recognized.");
    }
}
