import { Component, OnDestroy, OnInit } from "@angular/core";
import { LegalStatusView } from "projects/app/src/app/services/http/clients/api-proxies";
import { LegalStatusService } from "projects/app/src/app/services/http/clients/legal-status.service";
import { ReportsService } from "projects/app/src/app/services/reports.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { Subscription } from "rxjs";

@Component({
    selector: "app-reporting-legal-status-editor",
    templateUrl: "./reporting-legal-status-editor.component.html",
    styleUrls: ["./reporting-legal-status-editor.component.scss"]
})
export class ReportingLegalStatusEditorComponent extends EditorComponentBase<number | null> implements OnInit, OnDestroy {
    legalStatusItems?: LegalStatusView[];
    selectedLegalStatus?: LegalStatusView;
    subscription?: Subscription;
    constructor(
        private readonly legalStatusService: LegalStatusService,
        private reportsService: ReportsService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.legalStatusItems = await this.legalStatusService.getAll();

        if (this.bindingProperty !== null) {
            this.subscription = this.reportsService.selectedProperty$.subscribe(async ready => {
                if (ready) {
                    this.selectedLegalStatus = this.foundItem();
                }
            });
        }
    }

    foundItem() {
        return this.legalStatusItems?.find(x => x.id === this.bindingProperty);
    }

    onLegalStatusChange() {
        if (this.selectedLegalStatus?.id) {
            this.bindingProperty = this.selectedLegalStatus.id;
        } else {
            this.bindingProperty = null;
        }
        this.valueChange.emit();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
