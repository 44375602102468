import { Injectable } from "@angular/core";
import {
    BoxResultType,
    GetSalesLeadValuesParameters,
    GetSalesLeadColumnChartValuesResultType,
    GetSalesLeadLineChartValuesResultType,
    GetSalesLeadMetricsValuesResultType,
    GetSalesLeadValuesResultType,
    CustomReportSalesLeadView,
    SalesLeadClient,
    SharedReportDto
} from "./reporting-api-proxies";
import { CustomReportServiceBase } from "./custom-report/custom-report-base.service";

@Injectable({
    providedIn: "root"
})
export class SalesLeadService extends CustomReportServiceBase<SalesLeadClient, CustomReportSalesLeadView> {
    constructor() {
        super(new SalesLeadClient());
    }

    async getSalesLeadValues(body: GetSalesLeadValuesParameters): Promise<GetSalesLeadValuesResultType[]> {
        return await this.client.salesLeadGetSalesLeadValues(body);
    }

    async getSalesLeadBoxesValues(parameters: GetSalesLeadValuesParameters): Promise<BoxResultType[]> {
        return await this.client.salesLeadGetSalesLeadBoxesValues(parameters);
    }

    async getSalesLeadColumnChartValues(body: GetSalesLeadValuesParameters): Promise<GetSalesLeadColumnChartValuesResultType[]> {
        return await this.client.salesLeadGetSalesLeadColumnChartValues(body);
    }

    async getSalesLeadMetricsValues(body: GetSalesLeadValuesParameters): Promise<GetSalesLeadMetricsValuesResultType> {
        return await this.client.salesLeadGetSalesLeadMetricsValues(body);
    }

    async getSalesLeadLineChartValues(body: GetSalesLeadValuesParameters): Promise<GetSalesLeadLineChartValuesResultType[]> {
        return await this.client.salesLeadGetSalesLeadLineChartValues(body);
    }

    override async saveCustomReport(body: CustomReportSalesLeadView): Promise<CustomReportSalesLeadView> {
        if (body.isPublic)
            return await this.client.salesLeadSaveCatalogReport(body);
        else
            return await this.client.salesLeadSaveCustomReport(body);
    }


    override async getCustomReport(id: number): Promise<CustomReportSalesLeadView> {
        return await this.client.salesLeadGetCustomReport(id);
    }

    override async shareReport(body: CustomReportSalesLeadView): Promise<SharedReportDto> {
        return await this.client.salesLeadCreateSharedReport(body);
    }

    override async getSharedReport(id: string): Promise<CustomReportSalesLeadView> {
        return await this.client.salesLeadGetSharedReport(id);
    }
}
