import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";

const marketsEditorConfig = {
    label: "Markets",
    componentId: EditorType.reportingMarket,
    fieldName: "markets"
};

const opportunitySourceEditorConfig = {
    label: "Opportunity Source",
    componentId: EditorType.reportingOpportunitySource,
    fieldName: "leads"
};

const divisionEditorConfig = {
    label: "Division",
    componentId: EditorType.reportingDivision,
    fieldName: "divisionIds"
};

const customerEditorConfig = {
    label: "Customer",
    componentId: EditorType.reportingCompany,
    fieldName: "customerIds"
};

const probabilityEditorConfig = {
    label: "Probability",
    componentId: EditorType.dropdownRanges,
    fieldName: "probabilityValue",
    additionalInfo: {
        iconType: "percentage"
    },
    mapping: {
        probabilityValueFrom: "probabilityValue.from",
        probabilityValueTo: "probabilityValue.to"
    }
};

const conditionEditorConfig = {
    label: "Condition",
    componentId: EditorType.activeStatus,
    fieldName: "condition",
    additionalInfo: {
        application: "reporting"
    }
};

const relatedOpportunitiesConditionEditorConfig = {
    label: "Related Opportunities Condition",
    componentId: EditorType.activeStatus,
    fieldName: "relatedOpportunitiesCondition",
    additionalInfo: {
        application: "reporting"
    }
};

const estimateTypeEditorConfig = {
    label: "Estimate Type",
    componentId: EditorType.estimateType,
    fieldName: "estimateTypeId",
    additionalInfo: {
        application: "reporting"
    }
};

const opportunityStatusEditorConfig = {
    label: "Opportunity Status",
    componentId: EditorType.reportingOpportunityStatus,
    fieldName: "opportunityStatusIds"
};

const projectStartDateEditorConfig = {
    label: "Project Start Date",
    componentId: EditorType.dateRange,
    fieldName: "projectStartDate",
    mapping: {
        projectStartDateFrom: "projectStartDate.from",
        projectStartDateTo: "projectStartDate.to"
    }
};

const projectEndDateEditorConfig = {
    label: "Project End Date",
    componentId: EditorType.dateRange,
    fieldName: "projectEndDate",
    mapping: {
        projectEndDateFrom: "projectEndDate.from",
        projectEndDateTo: "projectEndDate.to"
    }
};

const opportunityDueDateEditorConfig = {
    label: "Opportunity Due Date",
    componentId: EditorType.dateRange,
    fieldName: "opportunityDueDate",
    mapping: {
        opportunityDueDateFrom: "opportunityDueDate.from",
        opportunityDueDateTo: "opportunityDueDate.to"
    }
};

const competingBidderEditorConfig = {
    label: "Competing Bidder",
    componentId: EditorType.reportingCompany,
    fieldName: "competingBidderIds"
};

const potentialValueEditorConfig = {
    label: "Potential Value",
    componentId: EditorType.dropdownRanges,
    fieldName: "potentialValue",
    additionalInfo: {
        iconType: "dollar"
    },
    mapping: {
        potentialValueFrom: "potentialValue.from",
        potentialValueTo: "potentialValue.to"
    }
};

const expectedResponseDateEditorConfig = {
    label: "(Expected) Response Date",
    componentId: EditorType.dateRange,
    fieldName: "expectedResponseDate",
    mapping: {
        expectedResponseDateTo: "expectedResponseDate.to",
        expectedResponseDateFrom: "expectedResponseDate.from"
    }
};

const companyPropertyManagerEditorConfig = {
    label: "Company Property Manager",
    componentId: EditorType.reportingCompany,
    fieldName: "companyPropertyManagerIds"
};

const stateEditorConfig = {
    label: "State",
    componentId: EditorType.state,
    fieldName: "stateId",
    additionalInfo: {
        application: "reporting"
    }
};

const buildingClassEditorConfig = {
    label: "Class",
    componentId: EditorType.reportingBuildingClass,
    additionalInfo: {
        singleSelection: true
    },
    fieldName: "buildingClassId"
};

const companyTypeEditorConfig = {
    label: "Company Type",
    componentId: EditorType.reportingCompanyType,
    fieldName: "companyTypeId"
};

const legalStatusEditorConfig = {
    label: "Legal Status",
    componentId: EditorType.reportingLegalStatus,
    fieldName: "legalStatusId"
};

const awardedDateEditorConfig = {
    label: "Awarded Date",
    componentId: EditorType.dateRange,
    fieldName: "awardedDate",
    mapping: {
        bidDateFrom: "awardedDate.from",
        bidDateTo: "awardedDate.to"
    }
};

const awardedAmountEditorConfig = {
    label: "Awarded Amount",
    componentId: EditorType.dropdownRanges,
    additionalInfo: {
        iconType: "dollar"
    },
    fieldName: "awardedAmount",
    mapping: {
        awardedAmountFrom: "awardedAmount.from",
        awardedAmountTo: "awardedAmount.to"
    }
};

const bidDateEditorConfig = {
    label: "Bid Date",
    componentId: EditorType.dateRange,
    fieldName: "bidDate",
    mapping: {
        bidDateFrom: "bidDate.from",
        bidDateTo: "bidDate.to"
    }
};

const bidAmountEditorConfig = {
    label: "Bid Amount",
    componentId: EditorType.dropdownRanges,
    additionalInfo: {
        iconType: "dollar"
    },
    fieldName: "bidAmount",
    mapping: {
        bidAmountFrom: "bidAmount.from",
        bidAmountTo: "bidAmount.to"
    }
};

const opportunityCreationDateEditorConfig = {
    label: "Opportunity Creation Date",
    componentId: EditorType.dateRange,
    fieldName: "opportunityCreationDate",
    mapping: {
        opportunityCreationDateFrom: "opportunityCreationDate.from",
        opportunityCreationDateTo: "opportunityCreationDate.to"
    }
};

const projectDueDateEditorConfig = {
    label: "Project Due Date",
    componentId: EditorType.dateRange,
    fieldName: "projectDueDate",
    mapping: {
        projectDueDateFrom: "projectDueDate.from",
        projectDueDateTo: "projectDueDate.to"
    }
};

const projectStatusEditorConfig = {
    label: "Project Status",
    componentId: EditorType.reportingProjectStatus,
    fieldName: "projectStatusIds"
};

const changeOrderStatusEditorConfig = {
    label: "Change Order Status",
    componentId: EditorType.reportingChangeOrderStatus,
    fieldName: "changeOrderStatusId"
};

export {
    marketsEditorConfig,
    opportunitySourceEditorConfig,
    divisionEditorConfig,
    customerEditorConfig,
    probabilityEditorConfig,
    conditionEditorConfig,
    estimateTypeEditorConfig,
    opportunityStatusEditorConfig,
    projectStartDateEditorConfig,
    projectEndDateEditorConfig,
    opportunityDueDateEditorConfig,
    competingBidderEditorConfig,
    potentialValueEditorConfig,
    expectedResponseDateEditorConfig,
    relatedOpportunitiesConditionEditorConfig,
    companyPropertyManagerEditorConfig,
    stateEditorConfig,
    buildingClassEditorConfig,
    companyTypeEditorConfig,
    legalStatusEditorConfig,
    awardedDateEditorConfig,
    awardedAmountEditorConfig,
    bidDateEditorConfig,
    bidAmountEditorConfig,
    opportunityCreationDateEditorConfig,
    projectDueDateEditorConfig,
    projectStatusEditorConfig,
    changeOrderStatusEditorConfig
};
