<div class="topSection">
    <div class="container">
        <header>
            <div class="filters">
                <div class="filterItem">
                    <span class="text">Overview of the</span>
                    <lib-generic-dropdown
                        [source]="comparisonOptions"
                        [(selectedItem)]="filters.period"
                        (selectedItemChange)="onFilterChange()"
                        [config]="{
                            multiple: false,
                            defaultText: 'Select',
                            itemDisplayKey: 'name',
                            clearSelection: false
                        }"
                    >
                    </lib-generic-dropdown>
                </div>
                <div class="filterItem">
                    <lib-generic-dropdown-multiselect
                        [source]="divisions"
                        [config]="{
                            multiple: true,
                            defaultText: 'Select Division',
                            itemDisplayKey: 'name'
                        }"
                        [(selectedItems)]="filters.divisions"
                        (selectedItemsChange)="onFilterChange()"
                    >
                    </lib-generic-dropdown-multiselect>
                </div>
            </div>

            <div class="actions">
                <button class="primary-btn" (click)="onCreateNewOpportunityClicked()">+ New Opportunity</button>
            </div>
        </header>

        <div class="stats">
            <lib-dashboard-card>
                <lib-skeleton *ngIf="!opportunityStat" [config]="TextSkeletonConfig"></lib-skeleton>
                <app-dashboard-stat
                    *ngIf="opportunityStat"
                    [title]="'New Opportunities'"
                    [previousValue]="opportunityStat.previousResult.opportunitiesCount"
                    [currentValue]="opportunityStat.currentResult.opportunitiesCount"
                >
                </app-dashboard-stat>
            </lib-dashboard-card>
            <lib-dashboard-card>
                <lib-skeleton *ngIf="!opportunityStat" [config]="TextSkeletonConfig"></lib-skeleton>

                <app-dashboard-stat
                    *ngIf="opportunityStat"
                    [title]="'Bid Amount'"
                    [previousValue]="opportunityStat.previousResult.bidAmount"
                    [currentValue]="opportunityStat.currentResult.bidAmount"
                    [type]="'currency'"
                >
                </app-dashboard-stat>
            </lib-dashboard-card>
            <lib-dashboard-card>
                <lib-skeleton *ngIf="!opportunityStat" [config]="TextSkeletonConfig"></lib-skeleton>

                <app-dashboard-stat
                    *ngIf="opportunityStat"
                    [title]="'Awarded Amount'"
                    [previousValue]="opportunityStat.previousResult.awardedAmount"
                    [currentValue]="opportunityStat.currentResult.awardedAmount"
                    [type]="'currency'"
                >
                </app-dashboard-stat>
            </lib-dashboard-card>
            <lib-dashboard-card>
                <lib-skeleton *ngIf="!opportunityStat" [config]="TextSkeletonConfig"></lib-skeleton>

                <app-dashboard-stat
                    *ngIf="opportunityStat"
                    [title]="'Total Gross Margin'"
                    [previousValue]="opportunityStat.previousResult.totalGrossMarginPercentage"
                    [currentValue]="opportunityStat.currentResult.totalGrossMarginPercentage"
                    [type]="'customPercentage'"
                >
                </app-dashboard-stat>
            </lib-dashboard-card>
        </div>

        <div class="charts">
            <lib-dashboard-card [contentType]="'chart'" [icon]="infoIcon" [tooltip]="salesFunnelChartConfig?.descriptionHelpText">
                <lib-skeleton *ngIf="!salesFunnelChartConfig" [config]="rectangleSkeletonConfig"></lib-skeleton>

                <app-sales-funnel-chart [config]="salesFunnelChartConfig"></app-sales-funnel-chart>
            </lib-dashboard-card>

            <lib-dashboard-card [contentType]="'chart'" [icon]="infoIcon" [tooltip]="bidVsAwardedChartConfig?.descriptionHelpText">
                <lib-skeleton *ngIf="!bidVsAwardedChartConfig" [config]="rectangleSkeletonConfig"></lib-skeleton>

                <app-bid-vs-awarded-chart [config]="bidVsAwardedChartConfig"></app-bid-vs-awarded-chart>
            </lib-dashboard-card>
        </div>
    </div>
</div>

<div class="bottomSection">
    <div class="grids">
        <app-dashboard-opportunities-grid [type]="'upcomingBids'"></app-dashboard-opportunities-grid>
        <app-dashboard-opportunities-grid [type]="'newProjects'"></app-dashboard-opportunities-grid>
    </div>
</div>
