<div class="list-view-container" [id]="listViewId | idGenerator: 'ngx-listView-'">
    <div>
        <div class="list-view-header"></div>

        <ng-container *ngIf="headerTemplate">
            <div class="header" *ngTemplateOutlet="headerTemplate"></div>
        </ng-container>

        <ng-container *ngIf="source">
            <div class="item" [ngClass]="{ selected: item === selectedItem }" *ngFor="let item of source" (click)="onItemClicked(item)">
                <ng-container *ngIf="itemTemplate">
                    <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: { object: item } }"> </ng-container>
                </ng-container>

                <ng-container *ngIf="!itemTemplate">
                    <div class="description">{{ valueProperty ? item[valueProperty] : item }}</div>
                </ng-container>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="footerTemplate">
        <div class="footer" *ngTemplateOutlet="footerTemplate"></div>
    </ng-container>
</div>
