import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { AgChartOptions, AgChartLabelFormatterParams } from "ag-charts-community";
import { ChartService } from "projects/app/src/app/services/chart.service";
import { FormatNumberWithSuffixPipe } from "projects/ngx-lib/src/lib/pipes/format-number-with-suffix.pipe";
import { CHART_COLORS } from "../../themes/chart-themes";
import { PercentPipe } from "@angular/common";
export interface IBidVsAwardedChartConfig {
    title: string;
    data: IBidVsAwardedChartData[];
    descriptionHelpText?: string;
}

export interface IBidVsAwardedChartData {
    period: string;
    bidTotal: number;
    awardedTotal: number;
    awardedBid: number;
    awardedRate: number;
}

@Component({
    selector: "app-bid-vs-awarded-chart",
    templateUrl: "./bid-vs-awarded-chart.component.html",
    styleUrls: ["./bid-vs-awarded-chart.component.scss"]
})
export class BidVsAwardedChartComponent implements OnChanges {
    chartOptions!: AgChartOptions;

    @Input()
    config?: IBidVsAwardedChartConfig;

    constructor(
        private readonly chartService: ChartService,
        private formatNumberWithSuffixPipe: FormatNumberWithSuffixPipe,
        private readonly percentPipe: PercentPipe
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.config && !changes["config"].firstChange) {
            this.buildChart();
        }
    }

    commonConfig = {
        label: {
            formatter: ({ value }: AgChartLabelFormatterParams<number>) => {
                return value > 0 ? this.formatNumberWithSuffixPipe.transform(value) : "";
            }
        }
    };

    private buildChart(): void {
        if (!this.config) return;

        this.chartOptions = {
            theme: this.chartService.getTheme(),
            height: 250,
            legend: {
                toggleSeries: false
            },
            title: {
                text: this.config.title
            },
            data: this.config.data,
            series: [
                {
                    type: "bar",
                    xKey: "period",
                    yKey: "bidTotal",
                    yName: "Bid Total",
                    fillOpacity: 0.9,
                    ...this.commonConfig
                },
                {
                    type: "bar",
                    xKey: "period",
                    yKey: "awardedBid",
                    yName: "Awarded Bid",
                    fillOpacity: 0.9,
                    ...this.commonConfig
                },
                {
                    type: "bar",
                    xKey: "period",
                    yKey: "awardedTotal",
                    yName: "Awarded Total",
                    fillOpacity: 0.9,
                    ...this.commonConfig
                },
                {
                    type: "line",
                    xKey: "period",
                    yKey: "awardedRate",
                    yName: "Awarded Rate",
                    lineDash: [8, 8],
                    stroke: CHART_COLORS.black,
                    marker: {
                        shape: "circle",
                        fill: CHART_COLORS.black,
                        size: 10
                    },
                    tooltip: {
                        renderer: ({ datum }) => {
                            return {
                                backgroundColor: CHART_COLORS.black,
                                content: `${datum.period}: ${this.percentPipe.transform(datum.awardedRate, "1.0-0")}`
                            };
                        }
                    }
                }
            ],
            axes: [
                {
                    type: "category",
                    position: "bottom"
                },
                {
                    type: "number",
                    position: "left",
                    title: {
                        text: "Quantity"
                    },
                    label: {
                        formatter: ({ value }) => {
                            return this.formatNumberWithSuffixPipe.transform(value);
                        }
                    },
                    keys: ["bidTotal", "awardedBid", "awardedTotal"],
                    nice: true
                },
                {
                    position: "right",
                    type: "number",
                    title: {
                        text: "Awarded Rate (%)"
                    },
                    keys: ["awardedRate"],
                    nice: true,
                    min: 0,
                    max: 1,
                    interval: {
                        values: [0, 0.5, 1]
                    },
                    label: {
                        formatter: ({ value }) => `${this.percentPipe.transform(value, "1.0-0")}`
                    }
                }
            ]
        };
    }
}
