<lib-generic-type-ahead-dropdown
    [(selectedItem)]="selectedAccount"
    [placeholder]="placeholder"
    (selectedItemChange)="onAccountChange($event)"
    [displaySelected]="displaySelected"
    [(text)]="searchText"
    [source]="source"
    (textChange)="onAccountTextChanged()"
    [config]="{
        defaultText: 'Add Account',
        itemDisplayKey: 'name'
    }"
    [isReadOnly]="isReadOnly"
    [action]="addAccountAction"
    [error]="error"
>
</lib-generic-type-ahead-dropdown>
