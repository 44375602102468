import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { icons } from "../../assets/icons";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { SearchInputComponent } from "../search-input/search-input.component";
import { EditorComponentBase } from "../../classes/editor-component-base";
import { IGenericTypeAheadDropdownConfig, ITypeAheadDropdownAction } from "../../interfaces/type-ahead-dropdown-config.interface";
import { Overlay, ScrollStrategy } from "@angular/cdk/overlay";
import { animate, style, transition, trigger } from "@angular/animations";

@Component({
    selector: "lib-generic-type-ahead-dropdown",
    templateUrl: "./generic-type-ahead-dropdown.component.html",
    styleUrls: ["./generic-type-ahead-dropdown.component.scss"],
    animations: [
        trigger("dropdownAnimation", [
            transition(":enter", [
                style({ opacity: 0, transform: "translateY(-10px)" }),
                animate("0.3s ease-out", style({ opacity: 1, transform: "translateY(0)" }))
            ]),
            transition(":leave", [
                style({ opacity: 1, transform: "translateY(0)" }),
                animate("0.3s ease-in", style({ opacity: 0, transform: "translateY(-10px)" }))
            ])
        ])
    ]
})
export class GenericTypeAheadDropdownComponent<T> extends EditorComponentBase<T> implements OnInit, OnChanges {
    readonly icons = icons;

    protected scrollStrategy: ScrollStrategy;

    @ViewChild("typeAheadContainer", { static: true }) typeAheadContainer!: ElementRef<HTMLDivElement>;

    @ViewChild("searchInput")
    searchInput?: SearchInputComponent;

    @Input()
    source?: T[];

    @Input()
    config?: IGenericTypeAheadDropdownConfig<T>;

    @Input()
    disabled: boolean;

    @Input()
    selectedItem?: T;

    @Output()
    selectedItemChange = new EventEmitter<T>();

    @Input()
    text = "";

    @Output()
    textChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    itemClicked: EventEmitter<T> = new EventEmitter<T>();

    @Input()
    override isReadOnly?: boolean;

    @Input()
    displaySelected?: boolean;

    @Input()
    placeholder?: string;

    @Input()
    noResultsText?: string;

    @Input()
    action?: ITypeAheadDropdownAction;

    @Input()
    error?: boolean;

    @Input()
    isItemDisabled?: boolean;

    genericTypeAheadDropdownId?: string;


    _selectedItem?: T;
    closeIcon?: SafeHtml;
    opened: boolean;
    filterActive: boolean;
    focusList?: boolean;

    get displayPlaceholder(): string | undefined {
        return this.placeholder ?? "Select an item";
    }

    constructor(
        private readonly sanitizer: DomSanitizer,
        private readonly overlay: Overlay
    ) {
        super();
        this.disabled = false;
        this.text = "";
        this.isReadOnly = false;
        this.displaySelected = true;
        this.noResultsText = "No matches found";
        this.opened = false;
        this.filterActive = true;
        this.error = false;
        this.scrollStrategy = this.overlay.scrollStrategies.close();
        this.source = [];
    }

    ngOnInit(): void {
        this.filterActive = this.selectedItem !== undefined;
        this.closeIcon = this.sanitizer.bypassSecurityTrustHtml(icons.close);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["selectedItem"] && changes["selectedItem"].currentValue) {
            this._selectedItem = this.selectedItem ? ({ ...this.selectedItem } as T) : undefined;
        }
        if (changes["source"] && changes["source"].currentValue) {
            this.opened = true;
        }
    }

    selectItem(item: T): void {
        this._selectedItem = item;
        this.closePanel();
        this.emitItemChanged(item);
    }

    onKeyDownSelectItem({ event, item }: { event: KeyboardEvent; item: T }): void {
        if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            this.selectItem(item);
        }
    }

    onKeyDownActivateFilter(event: KeyboardEvent): void {
        if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            this.activateFilter(event);
        }
    }

    getTemplate(): TemplateRef<unknown> | null {
        if (!this.config?.itemTemplate) return null;
        return this.config.itemTemplate;
    }

    activateFilter(event: Event): void {
        if (this.isReadOnly || this.disabled) return;
        event.stopPropagation();
        this.filterActive = true;
        this.focusInput();
    }

    async onTextChanged(): Promise<void> {
        this.opened = true;
        this.textChange.emit(this.text);
    }

    clearFilter() {
        this._selectedItem = undefined;
        this.closePanel();
        this.emitItemChanged(undefined);
    }

    onKeyDown(event: KeyboardEvent): void {
        if (this.source && this.source.length > 0 && this.filterActive && this.opened) {
            if (event.key === "ArrowDown" || event.key === "ArrowUp") {
                event.preventDefault();
                this.focusList = true;
            } else if (event.key === "Escape") {
                this.closePanel();
            } else if (event.key === "Tab") {
                this.closePanel();
            }
        }
    }

    onKeyDownClearFilter(e: KeyboardEvent) {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            this.clearFilter();
        }
    }

    focusInput() {
        if (this.filterActive) {
            setTimeout(() => {
                this.searchInput?.inputElement?.nativeElement.focus();
            }, 0);
        }
    }

    onRunAction() {
        this.closePanel();
    }

    closePanel() {
        this.focusList = false;
        this.opened = false;
        this.filterActive = false;
        this.text = "";
        this.source = [];
        this.typeAheadContainer.nativeElement.focus();
    }

    private emitItemChanged(item?: T): void {
        this.selectedItemChange.emit(item);
    }
}
