<div
    class="container"
    [ngClass]="trendClass"
    *ngIf="previousValue !== null && currentValue !== null"
    [id]="trendIndicatorId | idGenerator: 'ngx-trendIndicator-'"
>
    {{ displayValue }}
    <span *ngIf="trendClass !== 'neutral'">
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
            <path *ngIf="trendClass === 'positive'" d="M3.99926 0L7.46336 6H0.535156L3.99926 0Z" fill="#00A299" />
            <path *ngIf="trendClass === 'negative'" d="M4 6L7.4641 0H0.535898L4 6Z" fill="#CE6464" />
        </svg>
    </span>
</div>
