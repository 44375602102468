import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColDef } from 'ag-grid-community';

export interface ICol {
    name: string;
}

export interface IGroupLevel {
    name: string;
    level: number;
}

@Component({
    selector: 'app-group-by-rows-filter',
    templateUrl: './group-by-rows-filter.component.html',
    styleUrls: ['./group-by-rows-filter.component.scss']
})
export class GroupByRowsFilterComponent {
    isOverlayColsOpen = false;
    isOverlayGroupsOpen = false;

    groupingLevels: IGroupLevel[] = [
        {name: 'Grouping level 1', level: 1},
        {name: 'Grouping level 2', level: 2},
        {name: 'Grouping level 3', level: 3},
    ];
    groupOpened: IGroupLevel | null = null;

    @Input() cols: ColDef[] = [];
    @Output() colsChange = new EventEmitter<ColDef[]>();

    appliedGroups: ColDef[] = [];
    lastGroupsApplied: ColDef[] = [];

    groupIsEnable(group: IGroupLevel) {
        return group.level <= this.appliedGroups.length + 1;
    }

    restoreLastGroupsApplied() {
        this.appliedGroups = [...this.lastGroupsApplied];
    }

    clearLevel(groupLevel: number) {
        // possible improvement: if a greater level was previously selected: show modal or something that indicates that next levels are going to be cleared to
    }

    getAvailableCols(groupOpened: IGroupLevel): ColDef[] {
        return this.cols.filter((col: ColDef) => !this.appliedGroups.includes(col) || (this.appliedGroups[groupOpened.level - 1]?.headerName === col.headerName) )
    }

    applyGroup(group: IGroupLevel, col: ColDef) {
        this.appliedGroups[group.level - 1] = col;
        this.isOverlayColsOpen = false;
    }

    openLevel(group: IGroupLevel, event: Event) {
        event.stopPropagation()
        this.isOverlayColsOpen = !this.isOverlayColsOpen;
        this.groupOpened = this.isOverlayColsOpen ? group : null;
    }

    applyGroups() {
        this.colsChange.emit(this.appliedGroups)
        this.lastGroupsApplied = [...this.appliedGroups];
        this.isOverlayGroupsOpen = false;
    }

    closeOverlay() {
        this.isOverlayColsOpen = false;
    }

    openCloseGroups(event: Event) {
        event.stopPropagation();
        this.isOverlayGroupsOpen = !this.isOverlayGroupsOpen;

    }

    clearSelection() {
        this.appliedGroups = [];
        this.lastGroupsApplied = [];
    }
}

