import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { User } from "../../models/security/user";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnChanges {
    @Input()
    isLoggedIn?: boolean;

    @Input()
    user?: User;

    @Input()
    currentUrl?: string;

    isReportsUrl?: boolean;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["currentUrl"]) {
            this.isReportsUrl = this.currentUrl?.startsWith("/reports");
        }
    }
}
