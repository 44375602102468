<div
    class="container"
    [ngClass]="{ selected: selected }"
    (click)="onClick()"
    tabindex="0"
    (keyup.enter)="onClick()"
    [id]="sectionSubHeaderId | idGenerator: 'ngx-sectionSubHeader-'"
>
    <div class="leftSection">
        <span *ngIf="iconName" class="icon" [innerHTML]="svg"></span>
        <div class="mainInfo">
            <p class="title">{{ title }}</p>
            <p *ngIf="!!subTitle" class="subtitle">{{ subTitle }}</p>
        </div>
        <lib-separator-line [context]="'subHeader'" [orientation]="'vertical'"></lib-separator-line>
    </div>

    <div class="rightSection">
        <ng-container *ngTemplateOutlet="info"></ng-container>
    </div>
</div>
