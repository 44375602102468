import { AccountMarketView, AccountView, CompanyView, EntityCustomFieldValueView } from "../services/http/clients/api-proxies";
import { ModelBase } from "./ModelBase";

export class Account extends ModelBase implements AccountView {
    organizationId: number | undefined;
    name: string | undefined;
    webSite: string | undefined;
    linkedin: string | undefined;
    organizationName: string | undefined;
    createdByUserId: number | undefined;
    accountTypeId: number | undefined;
    accountTypeName: string | undefined;
    legalStatusId: number | undefined;
    legalStatusName: string | undefined;
    markets: string | undefined;
    isActive: boolean;
    accountMarkets: AccountMarketView[] | undefined;
    companies: CompanyView[] | undefined;
    customFieldValues: EntityCustomFieldValueView[] | undefined;

    constructor() {
        super();
        this.organizationId = undefined;
        this.name = undefined;
        this.webSite = undefined;
        this.linkedin = undefined;
        this.organizationName = undefined;
        this.createdByUserId = undefined;
        this.accountTypeId = undefined;
        this.accountTypeName = undefined;
        this.legalStatusId = undefined;
        this.legalStatusName = undefined;
        this.markets = undefined;
        this.isActive = true;
        this.accountMarkets = undefined;
        this.companies = undefined;
        this.customFieldValues = undefined;
    }

    static fromInterface(dto: AccountView): Account {
        const contact = new Account();
        contact.mapFromInterface(dto);
        return contact;
    }
}
