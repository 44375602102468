<ng-container *ngIf="noResults && !isLoading; else showContent">
    <lib-no-data [iconName]="'emptyData'" [title]="'No Opportunities available.'"> </lib-no-data>
</ng-container>

<ng-template #showContent>
    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'code', label: 'ID', sortable: true },
                { key: 'name', label: 'Name', sortable: true, tooltip: true },
                { key: 'dueDate', label: 'Due Date', sortable: true, cellTemplate: cellDueDate },
                { key: 'potentialValue', label: 'Potential Value ($)', sortable: true, cellTemplate: cellPotentialValue },
                { key: 'proposedGrossMarginPercentage', label: 'Potential GM (%)', sortable: true, cellTemplate: cellProposedGM },
                { key: 'salesUsers', label: 'Salesperson', sortable: !companyId, cellTemplate: cellSalesPerson },
                { key: 'opportunityStatusName', label: 'Status', sortable: true },
                { key: 'companyName', label: 'Company Name', sortable: !companyId, hidden: !accountId }
            ]
        }"
        [source]="opportunities"
        [(orderConfig)]="orderConfig"
        (orderConfigChange)="onOrderChanged()"
        [(paginationConfig)]="paginationConfig"
        (paginationConfigChange)="refreshGridView()"
        (rowClicked)="onRowClicked($event)"
        (mouseWheelClick)="onMouseWheelClicked($event)"
    >
        <ng-template #cellDueDate let-data>
            {{ data.object.dueDate | date: "MM/dd/y" }}
        </ng-template>

        <ng-template #cellActualValue let-data>
            {{ data.object.actualValue | currency }}
        </ng-template>

        <ng-template #cellActualGM let-data>
            {{ data.object.actualGrossMarginPercentage | customPercentage }}
        </ng-template>

        <ng-template #cellSalesPerson let-data>
            <app-user-avatar [users]="data.object.opportunityUsers"></app-user-avatar>
        </ng-template>

        <ng-template #cellPotentialValue let-data>
            {{ data.object.potentialValue | currency }}
        </ng-template>

        <ng-template #cellProposedGM let-data>
            {{ data.object.proposedGrossMarginPercentage | customPercentage }}
        </ng-template>
    </lib-grid-view>
</ng-template>
