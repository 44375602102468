<div class="panel">
    <ul #itemsList class="itemsContainer">
        <ng-container *ngIf="source && config">
            <li
                *ngFor="let item of source"
                tabindex="-1"
                (click)="onSelectItem(item)"
                (keydown)="onListKeyDown($event, item)"
                [class.disabled]="isItemDisabled"
                [id]="menuId | idGenerator: 'ngx-menu-'"
            >
                <ng-container *ngIf="!config.itemTemplate; else customBlock">
                    <span [innerHTML]="item[config.itemDisplayKey] | highlightText: text"></span>
                </ng-container>
                <ng-template #customBlock>
                    <ng-container
                        *ngTemplateOutlet="
                            getTemplate();
                            context: {
                                $implicit: { object: item, config, inputText: text }
                            }
                        "
                    >
                    </ng-container>
                </ng-template>
            </li>
        </ng-container>
    </ul>

    <ng-container *ngIf="config?.clearSelection">
        <div class="actionContainer">
            <button class="resetStyles actionButton" (click)="onClearSelectionClicked()" [disabled]="!selectedItem">Clear selection</button>
        </div>
    </ng-container>

    <ng-container *ngIf="!source?.length && menuOpen">
        <div class="noResultsText">
            <span>{{ noResultsText }}</span>
        </div>
    </ng-container>

    <ng-container *ngIf="action?.includeAction">
        <div class="actionContainer">
            <button class="resetStyles actionButton" (click)="runAction(action?.action)">{{ action?.name }}</button>
        </div>
    </ng-container>
</div>
