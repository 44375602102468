import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ReportBoxValueDataTypeEnum } from "projects/app/src/app/components/pages/reports/types/report-box-value-data-type.enum";
import { icons } from "projects/ngx-lib/src/lib/assets/icons";

@Component({
    selector: "app-box",
    templateUrl: "./box.component.html",
    styleUrls: ["./box.component.scss"]
})
export class BoxComponent implements OnChanges {
    @Input()
    numericValue: number;

    @Input()
    titleValue?: string;

    @Input()
    formatType: ReportBoxValueDataTypeEnum;

    icon?: SafeHtml;

    public readonly formatTypes = ReportBoxValueDataTypeEnum;

    constructor(private readonly sanitizer: DomSanitizer) {
        this.numericValue = 0;
        this.titleValue = "";
        this.formatType = ReportBoxValueDataTypeEnum.String;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["formatType"]) {
            this.updateIcon();
        }
    }

    updateIcon(): void {
        let iconHtml: string;

        switch (this.formatType) {
            case ReportBoxValueDataTypeEnum.Currency:
                iconHtml = icons.dollar;
                break;

            default:
                iconHtml = icons.bubbles;
                break;
        }

        this.icon = this.sanitizer.bypassSecurityTrustHtml(iconHtml);
    }
}
