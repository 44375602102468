import { Component, Input } from "@angular/core";
import { BoxResultType, GetAwardedFinalEstimatesValuesParameters } from "projects/app/src/app/services/http/clients/reporting-app/reporting-api-proxies";

@Component({
    selector: "app-boxes-wrapper",
    templateUrl: "./boxes-wrapper.component.html",
    styleUrls: ["./boxes-wrapper.component.scss"]
})
export class BoxesWrapperComponent {
    @Input() boxesValues: BoxResultType[];
    searchConfig: GetAwardedFinalEstimatesValuesParameters;

    constructor() {
        this.boxesValues = [] as BoxResultType[];
        this.searchConfig = {} as GetAwardedFinalEstimatesValuesParameters;
    }
}
