import { DateTime } from "luxon";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from "@angular/core";
import { DateTimeService } from "../../../services";
import { EventsForPeriod, FullViewCalendarViewType } from "../types/full-view-calendar.types";
import { IFullViewCalendarEvent } from "../interfaces/full-view-calendar.interface";
@Component({
    selector: "lib-calendar-view",
    templateUrl: "./calendar-view.component.html",
    styleUrls: ["./calendar-view.component.scss"]
})
export class CalendarViewComponent<T> implements OnChanges {
    readonly hours?: number[];

    readonly daysHeader: string[];

    @Input()
    currentView: FullViewCalendarViewType;

    @Input()
    currentPeriod?: DateTime[];

    @Input()
    eventsForPeriod?: EventsForPeriod<T>;

    @Input()
    eventCountPerDay: { [key: string]: number } = {};

    @Input()
    eventTemplate!: TemplateRef<any>;

    @Input()
    eventLimitPerDay: number;

    @Input()
    calendarViewId?: string;

    @Output()
    viewMoreClicked: EventEmitter<DateTime>;

    weeksInMonth: DateTime[][];

    constructor(private readonly dateTimeService: DateTimeService) {
        this.hours = Array.from({ length: 24 }, (_, i) => i);
        this.daysHeader = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        this.currentView = "week";
        this.weeksInMonth = [];
        this.eventLimitPerDay = 3;
        this.viewMoreClicked = new EventEmitter<DateTime>();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes["currentPeriod"].firstChange) {
            if (this.currentView === "month") {
                this.computeWeeksInMonth();
            } else {
                this.scrollToCurrentHour();
            }
        }
    }

    isToday(date: DateTime): boolean {
        return this.dateTimeService.isToday(date);
    }

    isWeekend(date: DateTime): boolean {
        return this.dateTimeService.isWeekend(date);
    }

    getLimitedEventsForDay(day: DateTime): IFullViewCalendarEvent<T>[] {
        const dayKey = day.toISODate() ?? "";
        const eventsForDay: IFullViewCalendarEvent<T>[] = [];

        if (this.eventsForPeriod && this.eventsForPeriod[dayKey]) {
            for (const hour in this.eventsForPeriod[dayKey]) {
                if (eventsForDay.length >= this.eventLimitPerDay) break;

                const eventsAtHour = this.eventsForPeriod[dayKey][hour] || [];
                eventsForDay.push(...eventsAtHour);

                if (eventsForDay.length >= this.eventLimitPerDay) {
                    return eventsForDay.slice(0, this.eventLimitPerDay);
                }
            }
        }
        return eventsForDay;
    }

    onViewMoreClicked(event: DateTime) {
        this.viewMoreClicked.emit(event);
    }

    private scrollToCurrentHour(): void {
        const now = this.dateTimeService.now();
        const hourElement = document.getElementById(`hour-${now.hour}`);

        if (hourElement) {
            hourElement.scrollIntoView({ behavior: "auto", block: "center" });
        }
    }

    private computeWeeksInMonth(): void {
        this.weeksInMonth = [];
        if (!this.currentPeriod || this.currentPeriod.length === 0) {
            this.weeksInMonth = [];
            return;
        }
        const totalDays = this.currentPeriod.length;
        const daysPerWeek = 7;
        let week: DateTime[] = [];

        for (let i = 0; i < totalDays; i++) {
            week.push(this.currentPeriod[i]);

            if (week.length === daysPerWeek || i === totalDays - 1) {
                this.weeksInMonth.push(week);
                week = [];
            }
        }
    }
}
