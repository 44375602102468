import { Injectable } from "@angular/core";
import {
    BoxResultType,
    CustomReportServiceView,
    GetDataFilterValuesParameters,
    ServicesModulesClient,
    GetServiceModulesValuesResultType,
    GetServiceMetricsValuesResultType,
    GetServiceModulesColumnChartResultType,
    GetServiceModulesLineChartResultType,
    SharedReportDto
} from "./reporting-api-proxies";
import { CustomReportServiceBase } from "./custom-report/custom-report-base.service";

@Injectable({
    providedIn: "root"
})
export class ServiceModulesService extends CustomReportServiceBase<ServicesModulesClient, CustomReportServiceView> {
    constructor() {
        super(new ServicesModulesClient());
    }

    async getValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceModulesValuesResultType[]> {
        return await this.client.servicesModulesGetServiceModulesValues(parameters);
    }

    async getBoxesValues(parameters: GetDataFilterValuesParameters): Promise<BoxResultType[]> {
        return await this.client.servicesModulesGetServiceModulesBoxesValues(parameters);
    }

    async getColumnChartValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceModulesColumnChartResultType[]> {
        return await this.client.servicesModulesGetServiceModulesColumnChartValues(parameters);
    }

    async getMetricsValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceMetricsValuesResultType> {
        return await this.client.servicesModulesGetServiceModulesMetricsValues(parameters);
    }

    async getLineChartValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceModulesLineChartResultType[]> {
        return await this.client.servicesModulesGetServiceModulesLineChartValues(parameters);
    }

    override async saveCustomReport(body: CustomReportServiceView): Promise<CustomReportServiceView> {
        if (body.isPublic) return await this.client.servicesModulesSaveCatalogReport(body);
        else return await this.client.servicesModulesSaveCustomReport(body);
    }

    override async getCustomReport(id: number): Promise<CustomReportServiceView> {
        return await this.client.servicesModulesGetCustomReport(id);
    }

    override async shareReport(body: CustomReportServiceView): Promise<SharedReportDto> {
        return await this.client.servicesModulesCreateSharedReport(body);
    }

    override async getSharedReport(id: string): Promise<CustomReportServiceView> {
        return this.client.servicesModulesGetSharedReport(id);
    }
}
