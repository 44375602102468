import { Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { AccountView } from "projects/app/src/app/services/http/clients/api-proxies";
import { GenericCardComponent } from "projects/ngx-lib/src/lib/components/generic-card/generic-card.component";
import { ValidatorType } from "projects/ngx-lib/src/lib/enums/validator-type";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { EntityTypeCustomFieldService } from "projects/app/src/app/services/http/clients/entity-type-custom-field.service";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";

@Component({
    selector: "app-accounts-single-overview",
    templateUrl: "./accounts-single-overview.component.html",
    styleUrls: ["./accounts-single-overview.component.scss"]
})
export class AccountsSingleOverviewComponent implements OnChanges {
    @Input()
    account?: AccountView;

    @Input()
    isReadOnly?: boolean;

    factory: AppEditorFactory;

    mainInfoCardConfig!: IEditorItemConfig[];
    customFieldsConfig!: IEditorItemConfig[];

    @ViewChild("mainInfoCard")
    mainInfoCard?: GenericCardComponent;

    constructor(private readonly entityCustomFieldService: EntityTypeCustomFieldService) {
        this.factory = new AppEditorFactory();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.account && changes["account"]) {
            this.mainInfoCardConfig = [
                {
                    label: "Name",
                    componentId: EditorType.textInput,
                    fieldName: "name",
                    bindContext: this.account,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.required }, { type: ValidatorType.string, args: { maxLength: 100 } }],
                    additionalInfo: {
                        shouldTrimSpaces: true
                    }
                },
                {
                    label: "Website",
                    componentId: EditorType.link,
                    fieldName: "webSite",
                    bindContext: this.account,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.string, args: { maxLength: 100 } }]
                },
                {
                    label: "Linkedin",
                    componentId: EditorType.link,
                    fieldName: "linkedin",
                    bindContext: this.account,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.string, args: { maxLength: 100 } }]
                },
                {
                    label: "Type",
                    componentId: EditorType.companyType,
                    fieldName: "accountTypeId",
                    bindContext: this.account,
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Legal Status",
                    componentId: EditorType.legalStatus,
                    fieldName: "legalStatusId",
                    bindContext: this.account,
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Markets",
                    componentId: EditorType.entity,
                    fieldName: "accountMarkets",
                    bindContext: this.account,
                    entityId: "accountMarket",
                    isReadOnly: this.isReadOnly
                }
            ];

            if (!this.account.customFieldValues?.length)
                this.account.customFieldValues = await this.entityCustomFieldService.getCustomFieldsByEntityType(EntityTypes.Account);
            this.customFieldsConfig =
                this.account.customFieldValues.map(item => {
                    return {
                        label: item.name,
                        componentId: item.editor,
                        bindContext: item,
                        fieldName: "value",
                        isReadOnly: this.isReadOnly
                    } as IEditorItemConfig;
                }) ?? [];
        }
    }
}
