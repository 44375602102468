export enum OverviewLayout {
    overview1 = "'boxes metrics' 'chart chart'",
    overview2 = "'boxes' 'metrics' 'chart'",
    overview3 = "'boxes metrics' 'chart chart' 'chart2 chart2'",
    overview4 = "'boxes' 'metrics' 'chart' 'chart2'"
}

export enum GridLayout {
    grid1 = "'grid'"
}

export type LayoutType = OverviewLayout | GridLayout;
