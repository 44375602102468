import { Injectable } from "@angular/core";
import {
    BoxResultType,
    UsersProjectsClient,
    GetUsersProjectsMetricsValuesResultType,
    GetUserEstimatesSummaryValuesParameters,
    CustomReportUserEstimateSummaryView,
    GetExternalUserProjectsValuesResultType,
    GetUsersProjectsLineChartValuesResultType,
    GetUsersProjectsExtendedColumnChartValuesResultType,
    SharedReportDto
} from "./reporting-api-proxies";
import { CustomReportServiceBase } from "./custom-report/custom-report-base.service";

@Injectable({
    providedIn: "root"
})
export class UserProjectsService extends CustomReportServiceBase<UsersProjectsClient, CustomReportUserEstimateSummaryView> {
    constructor() {
        super(new UsersProjectsClient());
    }

    async getUserEstimatesBoxesValues(parameters: GetUserEstimatesSummaryValuesParameters): Promise<BoxResultType[]> {
        return await this.client.usersProjectsGetUserEstimatesBoxesValues(parameters);
    }

    async getUserEstimatesMetricsValues(parameters: GetUserEstimatesSummaryValuesParameters): Promise<GetUsersProjectsMetricsValuesResultType> {
        return await this.client.usersProjectsGetUserEstimatesMetricsValues(parameters);
    }

    async getUserEstimatesSummaryValues(parameters: GetUserEstimatesSummaryValuesParameters): Promise<GetExternalUserProjectsValuesResultType[]> {
        return await this.client.usersProjectsGetUserEstimatesSummaryValues(parameters);
    }

    async getColumnChartValues(parameters: GetUserEstimatesSummaryValuesParameters): Promise<GetUsersProjectsExtendedColumnChartValuesResultType[]> {
        return await this.client.usersProjectsGetUsersProjectsColumnChartValuesValues(parameters);
    }

    async getLineChartValues(parameters: GetUserEstimatesSummaryValuesParameters): Promise<GetUsersProjectsLineChartValuesResultType[]> {
        return await this.client.usersProjectsGetUsersProjectsLineChartValuesValues(parameters);
    }

    override async saveCustomReport(body: CustomReportUserEstimateSummaryView): Promise<CustomReportUserEstimateSummaryView> {
        if (body.isPublic) return await this.client.usersProjectsSaveCatalogReport(body);
        else return await this.client.usersProjectsSaveCustomReport(body);
    }

    override async getCustomReport(id: number): Promise<CustomReportUserEstimateSummaryView> {
        return await this.client.usersProjectsGetCustomReport(id);
    }

    override async shareReport(body: CustomReportUserEstimateSummaryView): Promise<SharedReportDto> {
        return await this.client.usersProjectsCreateSharedReport(body);
    }

    override async getSharedReport(id: string): Promise<CustomReportUserEstimateSummaryView> {
        return await this.client.usersProjectsGetSharedReport(id);
    }
}
