<div class="input-container">
    <input
        [placeholder]="(isReadOnly && !bindingProperty) || !label ? '' : 'Add ' + label"
        type="text"
        [(ngModel)]="bindingProperty"
        (ngModelChange)="dataChanged()"
        [class.error]="!isValid"
        [readOnly]="isReadOnly"
        [appTrimSpaces]="shouldTrimSpaces"
    />
    <app-validation-error-message [show]="!isValid" [errors]="validationErrors" [showOnlyFirstError]="showOnlyFirstError"></app-validation-error-message>
</div>
