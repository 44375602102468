import { Component } from "@angular/core";
import { MultipleTypeAheadValuesEditorComponent } from "../multiple-type-ahead-values-editor/multiple-type-ahead-values-editor.component";
import { BaseEstimatesService } from "projects/app/src/app/services/http/clients/reporting-app/base-estimates.service";
import { ReportsService } from "../../../../../services/reports.service";

@Component({
    selector: "app-reporting-project-search-editor",
    templateUrl: "../multiple-type-ahead-values-editor/multiple-type-ahead-values-editor.component.html",
    styleUrls: ["../multiple-type-ahead-values-editor/multiple-type-ahead-values-editor.component.scss"]
})
export class ReportingProjectSearchEditorComponent extends MultipleTypeAheadValuesEditorComponent {
    constructor(
        private readonly baseEstimatesService: BaseEstimatesService,
        reportsService: ReportsService
    ) {
        super(baseEstimatesService, reportsService);
        this.placeholder = "Search Projects";
    }
}
