import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { icons } from "../../assets/statusIcons";

export type ColorType = "success" | "error" | "pending" | "info" | "idle";

@Component({
    selector: "lib-status-indicator",
    templateUrl: "./status-indicator.component.html",
    styleUrls: ["./status-indicator.component.scss"]
})
export class StatusIndicatorComponent implements OnChanges {
    readonly icons = icons;

    @Input()
    iconName!: keyof typeof icons;

    @Input()
    text: string;

    @Input()
    colorType: ColorType;

    @Input()
    statusIndicatorId?: string;

    svg?: SafeHtml;

    constructor(private readonly sanitizer: DomSanitizer) {
        this.text = "";
        this.colorType = "idle";
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["iconName"]) this.svg = this.sanitizer.bypassSecurityTrustHtml(icons[this.iconName]);
    }
}
