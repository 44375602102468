<div class="container">
    <div class="editorItem">
        <div class="label">Account</div>
        <ng-container *ngIf="selectedAccount && isReadOnly && allowToNavigate; else selectItemTemplate">
            <app-internal-navigator [path]="routeToSingle" [label]="selectedAccount.name"></app-internal-navigator>
        </ng-container>
        <ng-template #selectItemTemplate>
            <div class="inputContainer">
                <app-type-ahead-accounts
                    [isReadOnly]="isReadOnly"
                    [displaySelected]="displaySelectedAccount"
                    [selectedAccount]="selectedAccount"
                    (selectedAccountChange)="onSelectedAccount($event)"
                    [error]="!isValid"
                    [isActive]="true"
                >
                </app-type-ahead-accounts>
                <app-validation-error-message [show]="!isValid" [errors]="validationErrors"></app-validation-error-message>
            </div>
        </ng-template>
    </div>
</div>
