import { Component, OnDestroy, OnInit } from "@angular/core";
import { CompanyTypeView } from "projects/app/src/app/services/http/clients/api-proxies";
import { CompanyTypeService } from "projects/app/src/app/services/http/clients/company-type.service";
import { ReportsService } from "projects/app/src/app/services/reports.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { Subscription } from "rxjs";

@Component({
    selector: "app-reporting-company-type-editor",
    templateUrl: "./reporting-company-type-editor.component.html",
    styleUrls: ["./reporting-company-type-editor.component.scss"]
})
export class ReportingCompanyTypeEditorComponent extends EditorComponentBase<number | null> implements OnInit, OnDestroy {
    companyTypeItems?: CompanyTypeView[];
    selectedCompanyType?: CompanyTypeView;
    subscription?: Subscription;
    constructor(
        private readonly companyTypeService: CompanyTypeService,
        private reportsService: ReportsService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.companyTypeItems = await this.companyTypeService.getAll();

        if (this.bindingProperty !== null) {
            this.subscription = this.reportsService.selectedProperty$.subscribe(async ready => {
                if (ready) {
                    this.selectedCompanyType = this.foundItem();
                }
            });
        }
    }

    private foundItem() {
        return this.companyTypeItems?.find(x => x.id === this.bindingProperty);
    }

    onCompanyTypeChange() {
        if (this.selectedCompanyType?.id) {
            this.bindingProperty = this.selectedCompanyType.id;
        } else {
            this.bindingProperty = null;
        }
        this.valueChange.emit();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
