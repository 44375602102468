import { Injectable } from "@angular/core";
import {
    ChangeOrdersClient,
    GetChangeOrdersValuesParameters,
    BoxResultType,
    GetExternalChangeOrderValuesResultType,
    CustomReportChangeOrderView,
    GetChangeOrdersColumnChartValuesResultType,
    GetChangeOrdersLineChartValuesResultType,
    GetChangeOrdersMetricsFormattedValuesResultType,
    SharedReportDto
} from "./reporting-api-proxies";
import { CustomReportServiceBase } from "./custom-report/custom-report-base.service";

@Injectable({
    providedIn: "root"
})
export class ChangeOrderService extends CustomReportServiceBase<ChangeOrdersClient, CustomReportChangeOrderView> {
    constructor() {
        super(new ChangeOrdersClient());
    }

    async getChangeOrdersValues(parameters: GetChangeOrdersValuesParameters): Promise<GetExternalChangeOrderValuesResultType[]> {
        return await this.client.changeOrdersGetChangeOrdersValues(parameters);
    }

    async getChangeOrdersMetricsValues(parameters: GetChangeOrdersValuesParameters): Promise<GetChangeOrdersMetricsFormattedValuesResultType> {
        return await this.client.changeOrdersGetChangeOrdersMetricsValues(parameters);
    }

    async getChangeOrdersBoxesValues(parameters: GetChangeOrdersValuesParameters): Promise<BoxResultType[]> {
        return await this.client.changeOrdersGetChangeOrdersBoxesValues(parameters);
    }

    async getColumnChartValues(parameters: GetChangeOrdersValuesParameters): Promise<GetChangeOrdersColumnChartValuesResultType[]> {
        return await this.client.changeOrdersGetChangeOrdersColumnChartValues(parameters);
    }

    async getLineChartValues(parameters: GetChangeOrdersValuesParameters): Promise<GetChangeOrdersLineChartValuesResultType[]> {
        return await this.client.changeOrdersGetChangeOrdersLineChartValues(parameters);
    }

    override async saveCustomReport(body: CustomReportChangeOrderView): Promise<CustomReportChangeOrderView> {
        if (body.isPublic) return await this.client.changeOrdersSaveCatalogReport(body);
        else return await this.client.changeOrdersSaveCustomReport(body);
    }

    override async getCustomReport(id: number): Promise<CustomReportChangeOrderView> {
        return await this.client.changeOrdersGetCustomReport(id);
    }

    override async shareReport(body: CustomReportChangeOrderView): Promise<SharedReportDto> {
        return await this.client.changeOrdersCreateSharedReport(body);
    }

    override async getSharedReport(id: string): Promise<CustomReportChangeOrderView> {
        return await this.client.changeOrdersGetSharedReport(id);
    }
}
