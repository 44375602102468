import { Injectable } from "@angular/core";
import { AccountTypeClient, AccountTypeView, IAccountTypeClient } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class AccountTypeService {
    private client: IAccountTypeClient;
    constructor() {
        this.client = new AccountTypeClient();
    }

    async getAll(): Promise<AccountTypeView[]> {
        return await this.client.accountTypeGetAll();
    }
}
