import { Component } from "@angular/core";
import { MultipleDropdownValuesEditorComponent } from "../multiple-dropdown-values-editor/multiple-dropdown-values-editor.component";
import { BaseEstimateStatusService } from "projects/app/src/app/services/http/clients/reporting-app/base-estimate-status.service";
import { ReportsService } from "projects/app/src/app/services/reports.service";

@Component({
    selector: "app-reporting-project-status-editor",
    templateUrl: "../multiple-dropdown-values-editor/multiple-dropdown-values-editor.component.html",
    styleUrls: ["../multiple-dropdown-values-editor/multiple-dropdown-values-editor.component.scss"]
})
export class ReportingProjectStatusEditorComponent extends MultipleDropdownValuesEditorComponent {
    constructor(
        private readonly baseEstimateStatusService: BaseEstimateStatusService,
        reportsService: ReportsService
    ) {
        super(baseEstimateStatusService, reportsService);
        this.defaultText = "Select Project Status";
    }
}
