<div class="container">
    <lib-section-header title="{{ title }}" [actionsTemplate]="actionsTemplate" [iconName]="'goBack'">
        <ng-template #actionsTemplate>
            <div class="actions">
                <button class="secondary-btn" (click)="cancel()">Cancel</button>
                <button class="primary-btn" (click)="saveAccount()">{{ buttonLabel }}</button>
            </div>
        </ng-template>
    </lib-section-header>

    <div class="cardsContainer">
        <app-accounts-single-overview [account]="account" [isReadOnly]="false" #overview></app-accounts-single-overview>
    </div>
</div>
