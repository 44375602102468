import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ColDef } from 'ag-grid-community';
import { icons } from 'projects/ngx-lib/src/lib/assets/icons';

export type SelectedColsType = Record<string, boolean>;

@Component({
  selector: 'app-column-visibility-filter',
  templateUrl: './column-visibility-filter.component.html',
  styleUrls: ['./column-visibility-filter.component.scss']
})
export class ColumnVisibilityFilterComponent {
    settingsPanel: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(icons.settingsPanel);
    @Input({required: true}) cols!: ColDef[];
    @Input({required: true}) checkboxesState!: SelectedColsType;
    @Output() checkboxesStateChange: EventEmitter<SelectedColsType> = new EventEmitter<SelectedColsType>();
    @Output() visibleColsChange: EventEmitter<SelectedColsType> = new EventEmitter<SelectedColsType>();

    constructor(private readonly sanitizer: DomSanitizer) { }

    selectAll(target: any) {
        for (const col in this.checkboxesState) {
            this.checkboxesStateChange.emit({[col]: target.checked})
        }
    }

    apply() {
        const changedCols: SelectedColsType = {};

        for (const key in this.checkboxesState) {
            changedCols[key] = this.checkboxesState[key]
        }

        if (Object.keys(changedCols).length > 0) {
            this.visibleColsChange.emit(changedCols)
        }
    }

    onChange(e: boolean, checkbox: string) {
        this.checkboxesStateChange.emit({[checkbox]: e})
    }
}