<div class="container">
    <div class="map" *ngIf="showStaticImageMap && selectedAddress && selectedAddress.latitude && selectedAddress.longitude">
        <app-static-map [latitude]="selectedAddress.latitude" [longitude]="selectedAddress.longitude"></app-static-map>
    </div>
    <div class="editorItem">
        <div class="label" *ngIf="showStaticImageMap">Address</div>
        <div class="inputContainer">
            <app-type-ahead-addresses
                [selectedAddress]="selectedAddress"
                (selectedAddressChange)="onSelectedAddress($event)"
                [isReadOnly]="isReadOnly"
                [error]="!isValid"
            ></app-type-ahead-addresses>
            <app-validation-error-message [show]="!isValid" [errors]="validationErrors"></app-validation-error-message>
        </div>
    </div>

    <app-location-details [location]="selectedAddress" [isReadOnly]="isReadOnly" [(line2)]="$any(bindContext).line2" (line2Change)="valueChange.emit()">
    </app-location-details>
</div>
