import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { AccountView } from "projects/app/src/app/services/http/clients/api-proxies";
import { ModalService, TabViewComponent } from "projects/ngx-lib/src/public-api";
import { ModalConfirmComponent } from "../../../shared/modals/modal-confirm/modal-confirm.component";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { TabCounterService, TabCounterType } from "projects/app/src/app/services/tab-counter.service";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";
import { getTab, processTabParameter } from "projects/app/src/app/utils/tab-navigation";
import { AccountService } from "projects/app/src/app/services/http/clients/account.service";

@Component({
    selector: "app-accounts-single",
    templateUrl: "./accounts-single.component.html",
    styleUrls: ["./accounts-single.component.scss"]
})
export class AccountsSingleComponent implements OnInit, AfterViewInit {
    @ViewChild(TabViewComponent)
    tabControl!: TabViewComponent;

    accountId: number;

    account?: AccountView;

    currentTab: string;

    listViewSelectedItem?: AccountView;

    isActive?: boolean;

    entityTypeId: EntityTypes;

    tabsCounters: TabCounterType = {
        companies: 0,
        Opportunities: 0,
        Contacts: 0,
        Buildings: 0,
        Notes: 0
    };

    constructor(
        private readonly accountService: AccountService,
        private readonly awaiter: AwaiterService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly tabCounterService: TabCounterService
    ) {
        this.accountId = parseInt(this.route.snapshot.paramMap.get("id") ?? "0");
        this.currentTab = getTab(this.route.snapshot.paramMap.get("tab")) ?? TabNames[TabNames.Overview];
        this.isActive = true;
        this.entityTypeId = EntityTypes.Account;
    }

    async ngOnInit(): Promise<void> {
        await this.handleAccountChange();

        this.route.paramMap.subscribe(async params => {
            const newId = parseInt(params.get("id") ?? "0");
            const newTab = getTab(params.get("tab"));

            if (newId && newId !== this.accountId) {
                this.accountId = newId;
                await this.handleAccountChange();
            }

            if (newTab && newTab !== this.currentTab && this.tabControl) {
                this.currentTab = processTabParameter(this.route, this.tabControl);
            }
        });
    }

    ngAfterViewInit(): void {
        this.currentTab = processTabParameter(this.route, this.tabControl);
    }

    onEditAccount(): void {
        this.router.navigate([`/accounts-edit/${this.accountId}`]);
    }

    async archiveAccount(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Archive",
            cancelCaption: "Cancel",
            content: "Are you sure you want to archive this account?",
            title: "Archive Account"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Archiving Account", async () => {
            if (!this.account?.id) return;

            await this.accountService.deactivate(this.account.id);
            this.isActive = false;
        });
    }

    async restoreAccount(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Restore",
            cancelCaption: "Cancel",
            content: "Are you sure you want to restore this account?",
            title: "Restore Account"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Restoring Account", async () => {
            if (!this.account?.id) return;
            await this.accountService.activate(this.account.id);
            this.isActive = true;
        });
    }

    async onTabCountersChange(): Promise<void> {
        await this.updateTabCounters();
    }

    tabChange(tabName: string) {
        this.router.navigate(["/accounts-single", this.accountId, tabName]);
    }

    async handleAccountChange(): Promise<void> {
        if (!this.accountId) return;

        await this.updateAccountInfo();
        await this.updateTabCounters();
    }

    private async updateAccountInfo(): Promise<void> {
        await this.awaiter.awaitAction("Getting Account Info", async () => {
            this.account = await this.accountService.getById(this.accountId);
            this.isActive = this.account.isActive;
        });
    }

    private async updateTabCounters(): Promise<void> {
        await this.tabCounterService.updateCounters(this.entityTypeId, this.accountId, this.tabsCounters);
    }
}
