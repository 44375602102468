import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ColDef, ColumnVisibleEvent, FirstDataRenderedEvent, GridApi, GridOptions, GridReadyEvent } from "ag-grid-community";
import { customThemeQuartz } from "projects/app/src/app/ag-grid-custom-theme";
import { SelectedColsType } from "projects/app/src/app/components/shared/grid-filters/column-visibility-filter/column-visibility-filter.component";

@Component({
    selector: "app-grid",
    templateUrl: "./grid.component.html",
    styleUrls: ["./grid.component.scss"]
})
export class GridComponent {
    @Input()
    finalEstimates: [];

    @Input()
    colDefs: ColDef[] = [];

    dropDownTitle = "Select columns";

    groupByDropDownTitle = "Group by...";

    public isDropdownVisible = false;

    suppressMoveWhenColumnDragging: boolean;

    public agTheme = customThemeQuartz;

    @Input()
    gridApi!: GridApi;

    @Output()
    setGridApi: EventEmitter<GridApi> = new EventEmitter<GridApi>();

    @Output()
    visibleColsChange: EventEmitter<SelectedColsType> = new EventEmitter<SelectedColsType>();

    gridOptions: GridOptions;

    constructor() {
        this.finalEstimates = [];
        this.suppressMoveWhenColumnDragging = false;
        this.gridOptions = {
            columnTypes: {
                array: {
                    valueFormatter: (params: any) => this.formatArray(params.value)
                }
            }
        };
    }
    public defaultColDef: ColDef = {
        flex: 1,
        minWidth: 100
    };
    public autoGroupColumnDef: ColDef = {
        minWidth: 200
    };

    onFirstDataRendered(params: FirstDataRenderedEvent) {
        params.api.sizeColumnsToFit();
    }

    onGridReady(params: GridReadyEvent) {
        this.setGridApi.emit(params.api);
    }

    onColumnVisibleChange(e: ColumnVisibleEvent) {
        if (e.column && e.visible != undefined) {
            this.visibleColsChange.emit({ [e.column.getColId()]: e.visible });
        }
    }
    private formatArray(arr: string[]) {
        if (!arr.length) {
            return "";
        }
        return arr.join(", ");
    }
}
