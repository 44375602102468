import { Injectable } from "@angular/core";
import {
    BoxResultType,
    EstimateClient,
    CustomReportEstimateView,
    GetEstimateValuesParameters,
    GetEstimateValuesResultType,
    GetEstimateColumnChartValuesResultType,
    GetEstimateMetricsValuesResultType,
    GetEstimateLineChartValuesResultType,
    SharedReportDto
} from "./reporting-api-proxies";
import { CustomReportServiceBase } from "./custom-report/custom-report-base.service";

@Injectable({
    providedIn: "root"
})
export class BidEstimateService extends CustomReportServiceBase<EstimateClient, CustomReportEstimateView> {
    constructor() {
        super(new EstimateClient());
    }

    async getEstimateValues(
        parameters: GetEstimateValuesParameters
    ): Promise<GetEstimateValuesResultType[]> {
        return await this.client.estimateGetEstimateValues(parameters);
    }

    async getEstimateBoxesValues(parameters: GetEstimateValuesParameters): Promise<BoxResultType[]> {
        return await this.client.estimateGetEstimateBoxesValues(parameters);
    }

    async getEstimateColumnChartValues(parameters: GetEstimateValuesParameters): Promise<GetEstimateColumnChartValuesResultType[]> {
        return await this.client.estimateGetEstimateColumnChartValues(parameters);
    }

    async getEstimateMetricsValues(parameters: GetEstimateValuesParameters): Promise<GetEstimateMetricsValuesResultType> {
        return await this.client.estimateGetEstimateMetricsValues(parameters);
    }

    async getEstimateLineChartValues(parameters: GetEstimateValuesParameters): Promise<GetEstimateLineChartValuesResultType[]> {
        return await this.client.estimateGetEstimateLineChartValues(parameters);
    }

    override async saveCustomReport(body: CustomReportEstimateView): Promise<CustomReportEstimateView> {
        if (body.isPublic)
            return await this.client.estimateSaveCatalogReport(body);
        else
            return await this.client.estimateSaveCustomReport(body);
    }


    override async getCustomReport(id: number): Promise<CustomReportEstimateView> {
        return await this.client.estimateGetCustomReport(id);
    }

    override async shareReport(body: CustomReportEstimateView): Promise<SharedReportDto> {
        return await this.client.estimateCreateSharedReport(body);
    }

    override async getSharedReport(id: string): Promise<CustomReportEstimateView> {
        return this.client.estimateGetSharedReport(id);
    }
}
