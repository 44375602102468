import { IReportWidgetConfiguration } from "../../../../types/report-widget-config.interface";
import { IWidgetInstance } from "../../../../types/widget-instance.interface";

export abstract class ReportWidgetComponent<TService, TParameters> implements IWidgetInstance<TService, TParameters> {
    private config?: IReportWidgetConfiguration<TService>;

    get widgetConfig() {
        return this.config;
    }

    async build(configuration: IReportWidgetConfiguration<TService>): Promise<void> {
        this.config = configuration;
    }

    async update(service: TService, parameters: TParameters): Promise<void> {
        if (!this.config) throw new Error("Cannot update the widget without a configuration.");
        const result = await (service as any)[this.config.methodName](parameters);
        this.setResultData(result);
    }

    abstract setResultData(result: unknown): void;
}
