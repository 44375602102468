import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { OpportunityStatuses } from "../../../models/enums/OpportunityStatuses";
import { icons } from "projects/ngx-lib/src/lib/assets/statusIcons";
import { ColorType } from "projects/ngx-lib/src/lib/components/status-indicator/status-indicator.component";

@Component({
    selector: "app-opportunity-status-indicator",
    templateUrl: "./opportunity-status-indicator.component.html",
    styleUrls: ["./opportunity-status-indicator.component.scss"]
})
export class OpportunityStatusIndicatorComponent implements OnChanges {
    @Input()
    status: OpportunityStatuses;

    currentIcon: keyof typeof icons;
    currentText: string;
    currentColorType: ColorType;

    constructor() {
        this.status = OpportunityStatuses.Unqualified;
        this.currentIcon = "edit";
        this.currentText = "Unqualified lead";
        this.currentColorType = "idle";
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["status"]) this.updateStatusIndicator(this.status);
    }

    updateStatusIndicator(status: OpportunityStatuses) {
        switch (status) {
            case OpportunityStatuses.Accepted:
                this.currentIcon = "accepted";
                this.currentText = "Accepted";
                this.currentColorType = "success";
                break;

            case OpportunityStatuses.InProgress:
                this.currentIcon = "inProgress";
                this.currentText = "In progress";
                this.currentColorType = "info";
                break;

            case OpportunityStatuses.Canceled:
                this.currentIcon = "canceled";
                this.currentText = "Canceled";
                this.currentColorType = "error";
                break;

            case OpportunityStatuses.InReview:
                this.currentIcon = "inReview";
                this.currentText = "In review";
                this.currentColorType = "pending";
                break;

            case OpportunityStatuses.OnHold:
                this.currentIcon = "onHold";
                this.currentText = "On Hold";
                this.currentColorType = "idle";
                break;

            case OpportunityStatuses.Rejected:
                this.currentIcon = "rejected";
                this.currentText = "Rejected";
                this.currentColorType = "error";
                break;

            case OpportunityStatuses.Submitted:
                this.currentIcon = "submitted";
                this.currentText = "Submitted";
                this.currentColorType = "pending";
                break;

            case OpportunityStatuses.Ready:
                this.currentIcon = "ready";
                this.currentText = "Ready";
                this.currentColorType = "pending";
                break;

            case OpportunityStatuses.EstimateRequested:
                this.currentIcon = "requested";
                this.currentText = "Estimate Requested";
                this.currentColorType = "idle";
                break;

            case OpportunityStatuses.Unqualified:
            default:
                this.currentIcon = "edit";
                this.currentText = "Unqualified lead";
                this.currentColorType = "idle";
                break;
        }
    }
}
