import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { icons } from "projects/ngx-lib/src/lib/assets/icons";

@Component({
    selector: "app-additional-filters",
    templateUrl: "./additional-filters.component.html",
    styleUrls: ["./additional-filters.component.scss"]
})
export class AdditionalFiltersComponent {
    @Input() filters?: any[];

    @Output() clearFilters = new EventEmitter<void>();

    factory: AppEditorFactory;

    closeIcon?: SafeHtml;

    additionalFiltersIcon?: SafeHtml;

    constructor(private readonly sanitizer: DomSanitizer) {
        this.factory = new AppEditorFactory();
        this.closeIcon = this.sanitizer.bypassSecurityTrustHtml(icons.close);
        this.additionalFiltersIcon = this.sanitizer.bypassSecurityTrustHtml(icons.additionalFilters);
    }
}
