<div class="boxes-wrapper">
    <app-box
        *ngFor="let card of boxesValues"
        [numericValue]="card.value"
        [titleValue]="card.title"
        [formatType]="card.formatType"
    >
    </app-box>
</div>

