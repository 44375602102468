import { Injectable } from "@angular/core";
import {
    ICustomReportSearchClient,
    CustomReportView,
    CustomReportSearchClient,
    SearchReportTemplateParameters,
    ICustomReportUserFavoriteClient,
    CustomReportUserFavoriteClient
} from ".././reporting-api-proxies";
import { IReportsSearchService } from "./custom-report-search.service";

@Injectable({
    providedIn: "root"
})
export class CatalogReportSearchService implements IReportsSearchService<CustomReportView> {
    private client: ICustomReportSearchClient;
    private favoriteClient: ICustomReportUserFavoriteClient;
    constructor() {
        this.client = new CustomReportSearchClient();
        this.favoriteClient = new CustomReportUserFavoriteClient();
    }

    async searchReports(body: SearchReportTemplateParameters): Promise<CustomReportView[]> {
        return await this.client.customReportSearchSearchCatalogReports(body);
    }

    async toggleFavorite(id: number): Promise<boolean> {
        return await this.favoriteClient.customReportUserFavoriteToggleFavorite(id);
    }
}
