import { Component } from "@angular/core";
import { LeadService } from "projects/app/src/app/services/http/clients/reporting-app/lead.service";
import { MultipleDropdownValuesEditorComponent } from "../multiple-dropdown-values-editor/multiple-dropdown-values-editor.component";
import { ReportsService } from "projects/app/src/app/services/reports.service";

@Component({
    selector: "app-reporting-opportunity-source-editor",
    templateUrl: "../multiple-dropdown-values-editor/multiple-dropdown-values-editor.component.html",
    styleUrls: ["../multiple-dropdown-values-editor/multiple-dropdown-values-editor.component.scss"]
})
export class ReportingOpportunitySourceEditorComponent extends MultipleDropdownValuesEditorComponent {
    constructor(reportingLeadService: LeadService, reportsService: ReportsService) {
        super(reportingLeadService, reportsService);
        this.defaultText = "Select Opportunities";
    }
}
