<div class="container" [id]="selectedSummaryId | idGenerator: 'ngx-selectedSummary-'">
    <div>
        <div class="header">
            <h3>{{ config?.itemDescriptionHeader ?? "Description" }}</h3>
            <h3>{{ config?.totalHeader ?? "Total" }}/{{ config?.selectedHeader ?? "Selected" }}</h3>
        </div>
        <button tabindex="0" *ngFor="let item of items" class="item" [ngClass]="{ selected: item === selectedItem }" (click)="onItemClicked(item)">
            <div class="description">
                <p>{{ item.description }}</p>
            </div>
            <div class="detail">
                <p>
                    {{ item.total }}/<span class="selectedCount">{{ item.selected }}</span>
                </p>
            </div>
        </button>
    </div>
    <div class="footer">
        <div class="description">
            <p>{{ config?.totalSummaryText ?? "Total" }}</p>
        </div>
        <div class="detail">
            <p>{{ getTotal() }}</p>
        </div>
    </div>
</div>
