<div class="checkbox-container">
    <input
        #checkboxElement
        type="checkbox"
        class="input-checkbox"
        aria-label="checkbox"
        [ngClass]="{
            error: error,
            circle: circle
        }"
        [(ngModel)]="checked"
        [disabled]="disabled!"
        [attr.aria-label]="ariaLabel"
        [id]="checkboxId | idGenerator: 'ngx-checkbox-'"
        (change)="onCheckboxChange($event)"
        (click)="onClick($event)"
        (keydown)="onKeyDown($event)"
    />
</div>
