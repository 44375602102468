<div class="item">
    <span *ngIf="icon" class="icon" [innerHTML]="icon"></span>

    <div class="textWrapper">
        <span
            class="titlePrefix"
            [ngClass]="titlePrefix?.textColor ? 'titlePrefix--' + titlePrefix?.textColor : 'titlePrefix--' + 'danger'"
            [innerHTML]="titlePrefix?.text | highlightText: inputText"
            *ngIf="titlePrefix?.text"
        ></span>
        <span class="title" [ngClass]="{ disabled: disabled }" [innerHTML]="title | highlightText: inputText"></span>
        <span class="subtitle" [innerHTML]="subtitle | highlightText: inputText"></span>
    </div>
</div>
