import { Injectable } from "@angular/core";
import {
    AccountClient,
    AccountView,
    AccountViewPaginatedResultDto,
    IAccountClient,
    QuickSearchAccountParameters,
    SearchAccountParameters
} from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class AccountService {
    private client: IAccountClient;
    constructor() {
        this.client = new AccountClient();
    }

    async getById(id: number): Promise<AccountView> {
        return await this.client.accountGetById(id);
    }

    async save(body: AccountView): Promise<AccountView> {
        return await this.client.accountSave(body);
    }

    async activate(id: number): Promise<void> {
        await this.client.accountActivate(id);
    }

    async deactivate(id: number): Promise<void> {
        await this.client.accountDeactivate(id);
    }

    async search(body: QuickSearchAccountParameters | undefined): Promise<AccountView[]> {
        return await this.client.accountQuickSearch(body);
    }

    async searchAccount(body: SearchAccountParameters | undefined): Promise<AccountViewPaginatedResultDto> {
        return await this.client.accountSearch(body);
    }
}
