<div class="header">
    <lib-section-header [title]="title" [titleStyle]="'tertiary'" [iconName]="type === 'newProjects' ? 'flare' : 'hourglass'"> </lib-section-header>
</div>

<div class="grid">
    <lib-grid-view
        [gridConfig]="
            type === 'newProjects'
                ? {
                      columnsConfig: [
                          { key: 'name', label: 'Name', sortable: false, tooltip: true, cellTemplate: cellDefault },
                          { key: 'probabilityName', label: 'Probability', sortable: false, cellTemplate: cellProbability },
                          { key: 'leadSourceName', label: 'Lead Source', sortable: false, cellTemplate: cellDefault },
                          { key: 'potentialValue', label: 'Potential Value ($)', sortable: false, cellTemplate: cellPotentialValue },
                          { key: 'proposedGrossMarginPercentage', label: 'Potential GM (%)', sortable: false, cellTemplate: cellPotentialGrossMargin },
                          { key: 'actualValue', label: 'Actual Value ($)', sortable: false, cellTemplate: cellActualValue },
                          { key: 'actualGrossMarginPercentage', label: 'Actual GM (%)', sortable: false, cellTemplate: cellActualGrossMargin }
                      ]
                  }
                : {
                      columnsConfig: [
                          { key: 'name', label: 'Name', sortable: false, tooltip: true, cellTemplate: cellDefault },
                          { key: 'dueDate', label: 'Due Date', sortable: false, cellTemplate: cellDueDate },
                          { key: 'companyName', label: 'Customer', sortable: false, tooltip: true, cellTemplate: cellDefault },
                          { key: 'potentialValue', label: 'Potential Value ($)', sortable: false, cellTemplate: cellPotentialValue },
                          { key: 'proposedGrossMarginPercentage', label: 'Potential GM (%)', sortable: false, cellTemplate: cellPotentialGrossMargin },
                          { key: 'actualValue', label: 'Actual Value ($)', sortable: false, cellTemplate: cellActualValue },
                          { key: 'actualGrossMarginPercentage', label: 'Actual GM (%)', sortable: false, cellTemplate: cellActualGrossMargin }
                      ]
                  }
        "
        [source]="dashboardOpportunities || placeholderRows"
        (rowClicked)="onRowClicked($event)"
        (mouseWheelClick)="onMouseWheelClicked($event)"
    >
        <!-- Define Cell Templates -->
        <ng-template #cellDefault let-data>
            <span *ngIf="data.object.name; else skeletonCell">{{ data.object.name }}</span>
        </ng-template>

        <ng-template #cellDueDate let-data>
            <span *ngIf="data.object.dueDate; else skeletonCell">{{ data.object.dueDate | date: "MM/dd/y" }}</span>
        </ng-template>

        <ng-template #cellProbability let-data>
            <span *ngIf="data.object.probabilityName; else skeletonCell">{{ data.object.probabilityName }}</span>
        </ng-template>

        <ng-template #cellPotentialGrossMargin let-data>
            <span *ngIf="data.object.proposedGrossMarginPercentage; else skeletonCell">
                {{ data.object.proposedGrossMarginPercentage | customPercentage }}
            </span>
        </ng-template>

        <ng-template #cellPotentialValue let-data>
            <span *ngIf="data.object.potentialValue; else skeletonCell">
                {{ data.object.potentialValue | currency }}
            </span>
        </ng-template>

        <ng-template #cellActualGrossMargin let-data>
            <span *ngIf="data.object.actualGrossMarginPercentage; else skeletonCell">
                {{ data.object.actualGrossMarginPercentage | customPercentage }}
            </span>
        </ng-template>

        <ng-template #cellActualValue let-data>
            <span *ngIf="data.object.actualValue; else skeletonCell">
                {{ data.object.actualValue | currency }}
            </span>
        </ng-template>

        <ng-template #skeletonCell>
            <lib-skeleton *ngIf="loadingSkeleton" [config]="{ count: 1, type: 'text', hasBox: false, width: '80%', height: '16px', alignItems: true }">
            </lib-skeleton>
        </ng-template>
    </lib-grid-view>
</div>
