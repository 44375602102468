import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { OpportunityView } from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityDashboardService } from "projects/app/src/app/services/http/clients/opportunity-dashboard.service";

type DashboardOpportunitiesGrid = "newProjects" | "upcomingBids";

@Component({
    selector: "app-dashboard-opportunities-grid",
    templateUrl: "./dashboard-opportunities-grid.component.html",
    styleUrls: ["./dashboard-opportunities-grid.component.scss"]
})
export class DashboardOpportunitiesGridComponent implements OnInit {
    @Input()
    type: DashboardOpportunitiesGrid;

    title: string;

    dashboardOpportunities?: OpportunityView[];

    placeholderRows: any[];

    loadingSkeleton: boolean;

    constructor(
        private readonly awaiter: AwaiterService,
        private readonly opportunityDashboardService: OpportunityDashboardService,
        private readonly router: Router
    ) {
        this.type = "newProjects";
        this.title = "";
        this.loadingSkeleton = true;
        this.placeholderRows = Array.from({ length: 3 }).map(() => ({}));
    }

    async ngOnInit(): Promise<void> {
        if (this.type === "newProjects") {
            this.title = "New Projects";
            this.dashboardOpportunities = await this.opportunityDashboardService.getNewProjects();
        } else {
            this.title = "Upcoming Bids";
            this.dashboardOpportunities = await this.opportunityDashboardService.getUpcomingBids();
        }
        this.placeholderRows = [];
        this.loadingSkeleton = false;
    }

    onRowClicked(opportunity: OpportunityView) {
        this.router.navigate([`/opportunities-single/${opportunity.id}/${TabNames[TabNames.Overview]}`]);
    }

    onMouseWheelClicked(opportunity: OpportunityView) {
        if (opportunity?.id && opportunity.id > 0) {
            window.open(`/opportunities-single/${opportunity.id}/${TabNames[TabNames.Overview]}`, "_blank");
        }
    }
}
