import { Component, OnInit } from "@angular/core";
import {
    AccountTypeView,
    ArchiveEntityOptionView,
    ContactView,
    MarketView,
    QuickSearchContactsParameters
} from "projects/app/src/app/services/http/clients/api-proxies";
import { MarketService } from "projects/app/src/app/services/http/clients/market.service";
import { ITypeAheadService } from "../../../shared/generic-type-ahead/generic-type-ahead.component";
import { ContactService } from "projects/app/src/app/services/http/clients/contact.service";
import { Router } from "@angular/router";
import { FilterService, IGetById } from "projects/ngx-lib/src/public-api";
import { ArchiveEntityService } from "projects/app/src/app/services/http/clients/archive-entity.service";
import { Condition } from "projects/app/src/app/models/enums/Condition";
import { AccountTypeService } from "projects/app/src/app/services/http/clients/account-type.service";
export interface IFilterValueAccounts {
    searchText?: string;
    accountType?: AccountTypeView;
    market?: MarketView;
    activeStatus?: ArchiveEntityOptionView;
}

@Component({
    selector: "app-accounts-main",
    templateUrl: "./accounts-main.component.html",
    styleUrls: ["./accounts-main.component.scss"]
})
export class AccountsMainComponent implements OnInit {
    filterValue: IFilterValueAccounts;

    accountTypes: AccountTypeView[];

    markets: MarketView[];

    contactServiceFilter: ITypeAheadService<ContactView, QuickSearchContactsParameters>;

    activeStatuses?: ArchiveEntityOptionView[];

    constructor(
        private readonly contactService: ContactService,
        private readonly accountTypeService: AccountTypeService,
        private readonly marketService: MarketService,
        private readonly router: Router,
        private readonly filterService: FilterService,
        private readonly archiveEntityService: ArchiveEntityService
    ) {
        this.filterValue = {};
        this.accountTypes = [];
        this.markets = [];
        this.contactServiceFilter = contactService;
    }

    async ngOnInit(): Promise<void> {
        this.accountTypes = await this.accountTypeService.getAll();
        this.markets = await this.marketService.getAll();
        this.activeStatuses = await this.archiveEntityService.getArchiveOptions();
        this.filterValue = {
            ...(this.activeStatuses?.length && { activeStatus: this.activeStatuses.find(c => c.id === Condition.Active) })
        };
        await this.deserializeUrlParams();
        await this.updateFilterUrlParams();
        this.buildFilters();
    }

    buildFilters(): void {
        this.filterValue = {
            searchText: this.filterValue.searchText,
            accountType: this.filterValue.accountType,
            market: this.filterValue.market,
            activeStatus: this.filterValue.activeStatus
        };
    }

    async onFilterChanged(): Promise<void> {
        await this.updateFilterUrlParams();
        this.buildFilters();
    }

    private async updateFilterUrlParams(): Promise<void> {
        await this.filterService.serialize<IFilterValueAccounts>(this.filterValue, "searchText");
        await this.filterService.serializeArray<IFilterValueAccounts, AccountTypeView>(this.filterValue, "accountType", "id");
        await this.filterService.serializeArray<IFilterValueAccounts, MarketView>(this.filterValue, "market", "id");
        await this.filterService.serializeArray<IFilterValueAccounts, ArchiveEntityOptionView>(this.filterValue, "activeStatus", "id");
    }

    private async deserializeUrlParams() {
        this.filterService.deserialize<IFilterValueAccounts>(this.filterValue, "searchText");
        this.filterService.deserializeArray<IFilterValueAccounts, AccountTypeView>(this.filterValue, "accountType", "id", this.accountTypes ?? []);
        this.filterService.deserializeArray<IFilterValueAccounts, MarketView>(this.filterValue, "market", "id", this.markets ?? []);
        this.filterService.deserializeArray<IFilterValueAccounts, ArchiveEntityOptionView>(this.filterValue, "activeStatus", "id", this.activeStatuses ?? []);
    }

    onCreateNewAccountClicked() {
        this.router.navigate([`/accounts-edit`]);
    }
}
