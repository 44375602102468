import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "lib-checkbox",
    templateUrl: "./checkbox.component.html",
    styleUrls: ["./checkbox.component.scss"]
})
export class CheckboxComponent {
    @Input()
    circle?: boolean;

    @Input()
    disabled?: boolean;

    @Input()
    error?: boolean;

    @Input()
    checked?: boolean;

    @Input()
    checkboxId?: string;

    @Input()
    ariaLabel?: string;

    @Output()
    clickEvent: EventEmitter<Event>;

    @Output()
    checkedChange: EventEmitter<boolean>;

    constructor() {
        this.clickEvent = new EventEmitter<Event>();
        this.checkedChange = new EventEmitter<boolean>();
    }

    onCheckboxChange(event: Event): void {
        const isChecked = (event.target as HTMLInputElement).checked;
        this.checked = isChecked;
        this.checkedChange.emit(isChecked);
    }

    onClick(event: Event): void {
        this.clickEvent.emit(event);
    }

    onKeyDown(event: KeyboardEvent): void {
        if (event.key === "Enter") {
            this.onCheckboxChange(event);
        }
    }
}
