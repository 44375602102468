import { Component, Input, QueryList, ViewChildren } from "@angular/core";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { EditorItemComponent } from "projects/ngx-lib/src/lib/components/editor-item/editor-item.component";

@Component({
    selector: "app-filters-main",
    templateUrl: "./filters-main.component.html",
    styleUrls: ["./filters-main.component.scss"]
})
export class FiltersMainComponent {
    @Input() filters?: any[];
    @Input() filterParamsSelected: any;
    @Input() filterValues: any;

    factory: AppEditorFactory;
    @ViewChildren("editorItem") editors?: QueryList<EditorItemComponent>;

    constructor() {
        this.factory = new AppEditorFactory();
    }
}
