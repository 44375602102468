import { Component } from "@angular/core";
import { ChangeOrderStatusService } from "projects/app/src/app/services/http/clients/reporting-app/change-order-status.service";
import { SingleDropdownValueEditorComponent } from "../single-dropdown-value-editor/single-dropdown-value-editor.component";
import { ReportsService } from "projects/app/src/app/services/reports.service";

@Component({
    selector: "app-reporting-change-order-status-editor",
    templateUrl: "../single-dropdown-value-editor/single-dropdown-value-editor.component.html",
    styleUrls: ["../single-dropdown-value-editor/single-dropdown-value-editor.component.scss"]
})
export class ReportingChangeOrderStatusEditorComponent extends SingleDropdownValueEditorComponent {
    constructor(changeOrderStatusService: ChangeOrderStatusService, reportsService: ReportsService) {
        super(changeOrderStatusService, reportsService);
        this.defaultText = "Select Change Order Status";
    }
}
