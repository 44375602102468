import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PlaygroundComponent } from "./components/pages/playground/playground.component";
import { BuildingsMainComponent } from "./components/pages/buildings/buildings-main/buildings-main.component";
import { CompaniesMainComponent } from "./components/pages/companies/companies-main/companies-main.component";
import { ContactsMainComponent } from "./components/pages/contacts/contacts-main/contacts-main.component";
import { OpportunitiesMainComponent } from "./components/pages/opportunities/opportunities-main/opportunities-main.component";
import { BuildingsSingleComponent } from "./components/pages/buildings/buildings-single/buildings-single.component";
import { CompaniesSingleComponent } from "./components/pages/companies/companies-single/companies-single.component";
import { ContactsSingleComponent } from "./components/pages/contacts/contacts-single/contacts-single.component";
import { OpportunitiesSingleComponent } from "./components/pages/opportunities/opportunities-single/opportunities-single.component";
import { OpportunitiesCreateComponent } from "./components/pages/opportunities/opportunities-create/opportunities-create.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { authGuard } from "./guards/auth.guard";
import { UserRoles } from "./models/enums/UserRoles";
import { WelcomeScreenComponent } from "./components/pages/welcome-screen/welcome-screen.component";
import { DashboardComponent } from "./components/pages/dashboard/dashboard.component";
import { ContactsEditComponent } from "./components/pages/contacts/contacts-edit/contacts-edit.component";
import { BuildingsEditComponent } from "./components/pages/buildings/buildings-edit/buildings-edit.component";
import { CompaniesEditComponent } from "./components/pages/companies/companies-edit/companies-edit.component";
import { ForbiddenComponent } from "./components/pages/errors/forbidden/forbidden.component";
import { NotificationsComponent } from "./components/pages/notifications/notifications.component";
import { ForgotPasswordComponent } from "./components/pages/forgot-password/forgot-password.component";
import { AccessDeniedComponent } from "./components/pages/access-denied/access-denied.component";
import { ConfigurationComponent } from "./components/pages/configuration/configuration.component";
import { CustomizationComponent } from "./components/pages/configuration/customization/customization.component";
import { NotificationsEditComponent } from "./components/pages/notifications/notifications-edit/notifications-edit.component";
import { ReportsMainComponent } from "./components/pages/reports/reports-main/reports-main.component";
import { entityTitleResolver } from "./utils/resolvers/entity-title.resolver";
import { OpportunityService } from "./services/http/clients/opportunity.service";
import { BuildingService } from "./services/http/clients/building.service";
import { CompanyService } from "./services/http/clients/company.service";
import { ContactService } from "./services/http/clients/contact.service";
import { ReportsSingleComponent } from "./components/pages/reports/reports-single/reports-single.component";
import { AccountService } from "./services/http/clients/account.service";
import { AccountsMainComponent } from "./components/pages/accounts/accounts-main/accounts-main.component";
import { AccountsEditComponent } from "./components/pages/accounts/accounts-edit/accounts-edit.component";
import { AccountsSingleComponent } from "./components/pages/accounts/accounts-single/accounts-single.component";

const routes: Routes = [
    { path: "", redirectTo: "welcome", pathMatch: "full" },
    { path: "playground", component: PlaygroundComponent, canActivate: [authGuard(UserRoles.None)] },
    { path: "welcome", component: WelcomeScreenComponent },
    { path: "login", component: LoginComponent },
    { path: "forgot-password", component: ForgotPasswordComponent },
    { path: "access-denied", component: AccessDeniedComponent },
    { path: "forbidden", component: ForbiddenComponent, canActivate: [authGuard(UserRoles.None)] },
    { path: "dashboard", component: DashboardComponent, title: "Dashboard", canActivate: [authGuard(UserRoles.None)] },
    { path: "opportunities", component: OpportunitiesMainComponent, title: "Opportunities", canActivate: [authGuard(UserRoles.None)] },
    { path: "opportunities-create", component: OpportunitiesCreateComponent, title: "New Opportunity", canActivate: [authGuard(UserRoles.None)] },
    {
        path: "opportunities-single/:id/:tab",
        component: OpportunitiesSingleComponent,
        title: entityTitleResolver,
        data: { service: OpportunityService },
        canActivate: [authGuard(UserRoles.None)]
    },
    { path: "buildings", component: BuildingsMainComponent, title: "Buildings", canActivate: [authGuard(UserRoles.None)] },
    {
        path: "buildings-single/:id/:tab",
        component: BuildingsSingleComponent,
        title: entityTitleResolver,
        data: { service: BuildingService },
        canActivate: [authGuard(UserRoles.None)]
    },
    { path: "buildings-edit", component: BuildingsEditComponent, title: "New Building", canActivate: [authGuard(UserRoles.None)] },
    {
        path: "buildings-edit/:id",
        component: BuildingsEditComponent,
        title: entityTitleResolver,
        data: { service: BuildingService },
        canActivate: [authGuard(UserRoles.None)]
    },
    { path: "companies", component: CompaniesMainComponent, title: "Companies", canActivate: [authGuard(UserRoles.None)] },
    { path: "companies-edit", component: CompaniesEditComponent, title: "New Company", canActivate: [authGuard(UserRoles.None)] },
    {
        path: "companies-edit/:id",
        component: CompaniesEditComponent,
        title: entityTitleResolver,
        data: { service: CompanyService },
        canActivate: [authGuard(UserRoles.None)]
    },
    {
        path: "companies-single/:id/:tab",
        component: CompaniesSingleComponent,
        title: entityTitleResolver,
        data: { service: CompanyService },
        canActivate: [authGuard(UserRoles.None)]
    },
    { path: "accounts", component: AccountsMainComponent, title: "Accounts", canActivate: [authGuard(UserRoles.None)] },
    { path: "accounts-edit", component: AccountsEditComponent, title: "New Account", canActivate: [authGuard(UserRoles.None)] },
    {
        path: "accounts-edit/:id",
        component: AccountsEditComponent,
        title: entityTitleResolver,
        data: { service: AccountService },
        canActivate: [authGuard(UserRoles.None)]
    },
    {
        path: "accounts-single/:id/:tab",
        component: AccountsSingleComponent,
        title: entityTitleResolver,
        data: { service: AccountService },
        canActivate: [authGuard(UserRoles.None)]
    },
    { path: "contacts", component: ContactsMainComponent, title: "Contacts", canActivate: [authGuard(UserRoles.None)] },
    { path: "contacts-edit", component: ContactsEditComponent, title: "New Contact", canActivate: [authGuard(UserRoles.None)] },
    {
        path: "contacts-edit/:id",
        component: ContactsEditComponent,
        title: entityTitleResolver,
        data: { service: ContactService, property: "fullName" },
        canActivate: [authGuard(UserRoles.None)]
    },
    {
        path: "contacts-single/:id/:tab",
        component: ContactsSingleComponent,
        title: entityTitleResolver,
        data: { service: ContactService, property: "fullName" },
        canActivate: [authGuard(UserRoles.None)]
    },
    { path: "notifications", component: NotificationsComponent, title: "Notifications", canActivate: [authGuard(UserRoles.None)] },
    { path: "notifications-edit", component: NotificationsEditComponent, title: "Notification Settings", canActivate: [authGuard(UserRoles.None)] },
    { path: "reports", component: ReportsMainComponent, canActivate: [authGuard(UserRoles.None)] },
    { path: "reports/:reportTemplate", component: ReportsSingleComponent, canActivate: [authGuard(UserRoles.None)] },
    { path: "reports/:reportTemplate/:id", component: ReportsSingleComponent, canActivate: [authGuard(UserRoles.None)] },
    { path: "reports/:reportTemplate/shared/:token", component: ReportsSingleComponent, canActivate: [authGuard(UserRoles.None)] },
    {
        path: "configuration",
        component: ConfigurationComponent,
        title: "Customization",
        canActivate: [authGuard(UserRoles.OrganizationAdmin)],
        children: [
            {
                path: "customization",
                component: CustomizationComponent,
                canActivate: [authGuard(UserRoles.OrganizationAdmin)]
            },
            { path: "", pathMatch: "full", redirectTo: "customization" }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
