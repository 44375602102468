<lib-generic-type-ahead-dropdown
    [(selectedItem)]="selectedUser"
    [placeholder]="(isReadOnly && !bindingProperty) || !label ? 'Select an Item' : 'Add ' + label"
    (selectedItemChange)="onSelectedUser()"
    [(text)]="usersSearchText"
    [source]="usersSearchResult"
    (textChange)="onUsersTextChanged()"
    [config]="{
        defaultText: 'Add User',
        itemDisplayKey: 'fullName',
        itemTemplate: userTemplate
    }"
>
</lib-generic-type-ahead-dropdown>

<ng-template #userTemplate let-data>
    <app-custom-menu-item
        [inputText]="data.inputText"
        [title]="data.object?.firstName + ' ' + data.object?.lastName"
        [subtitle]="data.object?.email"
        [titlePrefix]="!data.object?.isActive ? { text: 'Inactive', textColor: 'danger' } : undefined"
    >
    </app-custom-menu-item>
</ng-template>
