import { Component } from "@angular/core";
import { MultipleTypeAheadValuesEditorComponent } from "../multiple-type-ahead-values-editor/multiple-type-ahead-values-editor.component";
import { ReportingUserService } from "projects/app/src/app/services/http/clients/reporting-app/reporting-user.service";
import { ReportsService } from "../../../../../services/reports.service";

@Component({
    selector: "app-reporting-user-editor",
    templateUrl: "../multiple-type-ahead-values-editor/multiple-type-ahead-values-editor.component.html",
    styleUrls: ["../multiple-type-ahead-values-editor/multiple-type-ahead-values-editor.component.scss"]
})
export class ReportingUserEditorComponent extends MultipleTypeAheadValuesEditorComponent {
    constructor(reportingUserService: ReportingUserService, reportsService: ReportsService) {
        super(reportingUserService, reportsService);
        this.placeholder = "Search Users";
    }
}
