/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { IGenericGridPaginationConfig } from "../generic-grid/generic-grid.component";

enum ColumnDataType {
    String = 1,
    Bool,
    Date,
    Number,
    Percentage,
    Amount
}

interface IRowDataCellContent {
    value?: any;
}

@Component({
    selector: "lib-dynamic-grid",
    templateUrl: "./dynamic-grid.component.html",
    styleUrls: ["./dynamic-grid.component.scss"]
})
export class DynamicGridComponent implements OnChanges {
    @Input()
    source?: any;

    @Input()
    paginationConfig?: IGenericGridPaginationConfig;

    @Input()
    dynamicGridId?: string;

    @Output()
    paginationConfigChange: EventEmitter<IGenericGridPaginationConfig>;

    rowsContent: any;

    columnsHeader: any;

    rowGridContent: IRowDataCellContent[][];

    rowData: any;

    constructor() {
        this.paginationConfigChange = new EventEmitter<IGenericGridPaginationConfig>();
        this.columnsHeader = [];
        this.rowsContent = [];
        this.rowGridContent = [];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["source"]?.currentValue) {
            const data = changes["source"]?.currentValue;

            this.columnsHeader = data.columns
                ? data.columns.map((c: { reportColumnTitle: string }) => ({
                      title: c.reportColumnTitle
                  }))
                : [];

            this.rowsContent = [];
            this.rowsContent = data.rows ? this.rowsContent.concat(data.rows ?? []) : [];

            if (this.rowsContent.length) this.buildRowGridContent();
        }
    }

    canGoNext() {
        if (!this.paginationConfig?.currentPage || !this.paginationConfig?.pagesCount) return false;
        return this.paginationConfig?.currentPage < this.paginationConfig?.pagesCount;
    }

    goNext() {
        if (!this.canGoNext()) return;
        const nextPage = this.paginationConfig ? this.paginationConfig.currentPage + 1 : 1;
        this.changePage(nextPage);
    }

    changePage(page: number) {
        if (!this.paginationConfig) return;
        this.paginationConfigChange.emit({ ...this.paginationConfig, currentPage: page });
    }

    private transformCellData(cell: any): IRowDataCellContent {
        switch (cell["reportColumnDataType"]) {
            case ColumnDataType.String:
            case ColumnDataType.Number:
                return { value: cell.reportCellValue };

            case ColumnDataType.Date:
                return { value: cell.reportCellValue ? new Date(cell.reportCellValue).toLocaleDateString("en-US") : "" };

            case ColumnDataType.Percentage:
                return { value: cell.reportCellValue ? `${cell.reportCellValue * 100}%` : "" };

            case ColumnDataType.Amount:
                return { value: cell.reportCellValue ? `$${cell.reportCellValue}` : "" };

            default:
                return { value: cell.reportCellValue ? cell.reportCellValue : "" };
        }
    }

    private buildRowGridContent() {
        for (const row of this.rowsContent) {
            this.rowData = [];

            for (const cell of row) {
                const cellValue = this.transformCellData(cell);
                this.rowData.push(cellValue);
            }

            this.rowGridContent.push(this.rowData);
        }
    }
}
