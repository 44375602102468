import { Component, OnDestroy, OnInit } from "@angular/core";

import { EstimateTypeView } from "projects/app/src/app/services/http/clients/api-proxies";
import { EstimateTypeService } from "projects/app/src/app/services/http/clients/estimate-type.service";
import { ReportsService } from "projects/app/src/app/services/reports.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { Subscription } from "rxjs";

@Component({
    selector: "app-estimate-type-editor",
    templateUrl: "./estimate-type-editor.component.html",
    styleUrls: ["./estimate-type-editor.component.scss"]
})
export class EstimateTypeEditorComponent extends EditorComponentBase<number | null> implements OnInit, OnDestroy {
    estimateTypes?: EstimateTypeView[];
    selectedEstimateType?: EstimateTypeView;
    subscription?: Subscription;

    constructor(
        private readonly estimateTypeService: EstimateTypeService,
        private reportsService: ReportsService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.estimateTypes = await this.estimateTypeService.getAwardableEstimateTypes();

        if (this.bindingProperty !== null) {
            if (this.additionalInfo.application === "reporting") {
                this.subscription = this.reportsService.selectedProperty$.subscribe(async ready => {
                    if (ready) {
                        this.selectedEstimateType = this.foundItem();
                    }
                });
            } else {
                this.selectedEstimateType = this.foundItem();
            }
        }
    }

    foundItem() {
        return this.estimateTypes?.find(x => x.id === this.bindingProperty);
    }

    onEstimateTypeChange() {
        this.bindingProperty = this.selectedEstimateType?.id ?? null;
        this.valueChange.emit();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
