import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AccountService } from "../../../services/http/clients/account.service";
import { ModalService } from "projects/ngx-lib/src/lib/services/modal.service";
import { ModalAddAccountComponent } from "../modals/modal-add-account/modal-add-account.component";
import { AccountView, QuickSearchAccountParameters } from "../../../services/http/clients/api-proxies";
import { ITypeAheadDropdownAction } from "projects/ngx-lib/src/lib/interfaces/type-ahead-dropdown-config.interface";

@Component({
    selector: "app-type-ahead-accounts",
    templateUrl: "./type-ahead-accounts.component.html",
    styleUrls: ["./type-ahead-accounts.component.scss"]
})
export class TypeAheadAccountsComponent implements OnInit {
    @Input()
    selectedAccount?: AccountView;

    @Input()
    isReadOnly?: boolean;

    @Input()
    displaySelected?: boolean;

    @Input()
    placeholder?: string;

    @Input()
    isActive?: boolean;

    @Output()
    selectedAccountChange = new EventEmitter<AccountView>();

    @Input()
    error?: boolean;

    searchText: string;

    source?: AccountView[];

    addAccountAction!: ITypeAheadDropdownAction;

    constructor(
        private readonly accountService: AccountService,
        private readonly modalService: ModalService
    ) {
        this.searchText = "";
        this.isReadOnly = false;
        this.displaySelected = false;
        this.placeholder = "Add Account";
        this.error = false;
    }

    async ngOnInit(): Promise<void> {
        this.addAccountAction = {
            includeAction: true,
            name: "+ Add new account",
            action: () => this.openModal()
        };
    }

    async openModal(): Promise<void> {
        this.selectedAccount = await this.modalService.open(ModalAddAccountComponent);
        if (this.selectedAccount) {
            this.selectedAccountChange.emit(this.selectedAccount);
        }
    }

    async onAccountTextChanged(): Promise<void> {
        if (!this.searchText) return;

        const searchConfig: QuickSearchAccountParameters = {
            filterText: this.searchText,
            isActive: this.isActive,
            organizationId: undefined
        };

        this.source = await this.accountService.search(searchConfig);
    }

    onAccountChange(itemSelected: AccountView): void {
        this.selectedAccount = itemSelected;
        this.selectedAccountChange.emit(itemSelected);
    }
}
