<div class="container">
    <h1>{{ title }}</h1>
    <div class="items-wrapper">
        <app-list-element
            *ngFor="let item of items"
            [title]="item.title"
            [subtitle]="item.subtitle"
            [link]="item.link"
            [isFavorite]="item.isFavorite"
            (isFavoriteChange)="onFavoriteChange(item)"
        ></app-list-element>
    </div>
</div>
