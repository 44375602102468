import { Component, Input } from "@angular/core";

@Component({
    selector: "lib-skeleton",
    templateUrl: "./skeleton.component.html",
    styleUrls: ["./skeleton.component.scss"]
})
export class SkeletonComponent {
    @Input() config: SkeletonConfig;

    @Input() skeletonId?: string;

    skeletons: number[] = [];

    constructor() {
        this.config = {
            count: 1,
            type: "text",
            hasBox: false
        };
    }

    ngOnInit() {
        this.skeletons = Array(this.config.count).fill(0);
    }

    getBoxStyle() {
        return this.config.hasBox
            ? {
                  backgroundColor: this.config.boxStyle?.backgroundColor,
                  border: this.config.boxStyle?.border,
                  borderRadius: this.config.boxStyle?.borderRadius,
                  padding: this.config.boxStyle?.padding,
                  width: this.config.boxStyle?.width,
                  height: this.config.boxStyle?.height
              }
            : { width: this.config.width, height: this.config.height };
    }

    getRectangleStyle() {
        return {
            width: this.config.rectangleStyle?.width,
            height: this.config.rectangleStyle?.height,
            backgroundColor: this.config.rectangleStyle?.backgroundColor,
            borderRadius: this.config.rectangleStyle?.borderRadius
        };
    }
}

export interface SkeletonConfig {
    count: number;
    type: "text" | "rectangle";
    hasBox: boolean;
    width?: string;
    height?: string;
    alignItems?: boolean;
    boxStyle?: {
        backgroundColor?: string;
        border?: string;
        borderRadius?: string;
        padding?: string;
        width?: string;
        height?: string;
    };
    rectangleStyle?: {
        width?: string;
        height?: string;
        backgroundColor?: string;
        borderRadius?: string;
    };
    gridStyle?: {
        width?: string;
        height?: string;
        backgroundColor?: string;
        borderRadius?: string;
    };
}
