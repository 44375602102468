import { Component, ContentChildren, Input, Output, QueryList } from "@angular/core";
import { GridFilterDirective } from "../../directives";

@Component({
    selector: "lib-grid-filters",
    templateUrl: "./grid-filters.component.html",
    styleUrls: ["./grid-filters.component.scss"]
})
export class GridFiltersComponent<T> {
    @ContentChildren(GridFilterDirective) filterComponents!: QueryList<GridFilterDirective>;

    @Input()
    filterValues?: T;

    @Input()
    gridFiltersId?: string;

    @Output()
    filterValuesChange?: (filters: T) => void;
}
