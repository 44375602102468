import { Directive, Input, HostListener, ElementRef } from "@angular/core";

@Directive({
    selector: "[libRestrictNegativeNumbers]"
})
export class RestrictNegativeNumbersDirective {
    @Input()
    libRestrictNegativeNumbers: boolean | null = false;

    constructor(private el: ElementRef) {}

    @HostListener("input", ["$event"])
    onInputChange(): void {
        if (this.libRestrictNegativeNumbers === false || this.libRestrictNegativeNumbers === null) {
            return;
        }

        let value = this.el.nativeElement.value;

        value = value.replace(/[^0-9]/g, "");

        if (value !== "" && !Number.isInteger(+value)) {
            this.el.nativeElement.value = "";
        } else if (value < 0) {
            this.el.nativeElement.value = "0";
        } else {
            this.el.nativeElement.value = value;
        }
    }
}
