import { Injectable } from "@angular/core";

export interface IBaseDto {
    name?: string;
    id: number;
}

@Injectable({
    providedIn: "root"
})
export abstract class BaseDtoService {
    abstract getAll(): Promise<IBaseDto[]>;
    abstract getByIds(ids: number[]): Promise<IBaseDto[]>;
}

export abstract class BaseDtoSearchService {
    abstract search(filterText: string): Promise<IBaseDto[]>;
    abstract getById(id: number): Promise<IBaseDto>;
    abstract getByIds(ids: number[]): Promise<IBaseDto[]>;
}
