import { GridLayout, OverviewLayout } from "projects/app/src/app/components/pages/reports/types/report-layout-type.enum";
import { FinalEstimatesService } from "projects/app/src/app/services/http/clients/reporting-app/final-estimates.service";
import { IReportConfiguration } from "../../types/report-config.interface";
import { ReportType } from "../../types/report-type.enum";
import { WidgetType } from "../../types/report-widget-type.enum";
import { salesMainFilters } from "./shared/main-filters";
import {
    marketsEditorConfig,
    opportunitySourceEditorConfig,
    projectDueDateEditorConfig,
    projectEndDateEditorConfig,
    projectStartDateEditorConfig,
    projectStatusEditorConfig
} from "./shared/additional-filters";

export const AwardedFinalEstimates: IReportConfiguration<FinalEstimatesService> = {
    base: {
        type: ReportType.ReportSection,
        serviceType: FinalEstimatesService
    },
    overviewTab: {
        widgets: [
            {
                methodName: "getAwardedFinalEstimatesBoxesValues",
                type: WidgetType.Box,
                layoutTemplateArea: "boxes"
            },
            {
                methodName: "getAwardedFinalEstimatesMetricsValues",
                type: WidgetType.Metrics,
                layoutTemplateArea: "metrics",
                settings: {
                    cols: [
                        { name: "average", label: "Average" },
                        { name: "min", label: "Min" },
                        { name: "max", label: "Max" },
                        { name: "median", label: "Med" }
                    ],
                    rows: [
                        { name: "TDC", label: "Quantity" },
                        { name: "GrossMarginPercent", label: "Gross Margin %" },
                        { name: "SellPrice", label: "Sell Price" }
                    ]
                }
            },
            {
                methodName: "getFinalEstimatesLineChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart",
                settings: {
                    title: {
                        text: "Top 10 Awarded Final Estimates by Sell Price"
                    },
                    series: [
                        {
                            type: "line",
                            xKey: "projectName",
                            yKey: "sellPrice",
                            yName: "Sell Price"
                        },
                        {
                            type: "line",
                            xKey: "projectName",
                            yKey: "grossMargin",
                            yName: "GM (%)"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom"
                        },
                        {
                            type: "number",
                            position: "left",
                            keys: ["sellPrice"],
                            title: {
                                text: "Amount"
                            }
                        },
                        {
                            type: "number",
                            position: "right",
                            keys: ["grossMargin"],
                            label: {
                                formatter: function (params: any) {
                                    return params.value * 100 + "%";
                                }
                            },
                            title: {
                                text: "Percentage"
                            }
                        }
                    ]
                }
            },
            {
                methodName: "getFinalEstimatesColumnChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart2",
                settings: {
                    title: {
                        text: "Team Members Performance"
                    },
                    series: [
                        {
                            type: "bar",
                            xKey: "salesPerson",
                            yKey: "sellPrice",
                            yName: "Sell Price"
                        },
                        {
                            type: "bar",
                            xKey: "salesPerson",
                            yKey: "tdc",
                            yName: "TDC ($)"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom"
                        },
                        {
                            type: "number",
                            position: "left",
                            keys: ["sellPrice", "tdc"],
                            title: {
                                text: "Amount"
                            }
                        }
                    ]
                }
            }
        ],
        layout: OverviewLayout.overview3
    },
    gridTab: {
        widgets: [
            {
                methodName: "getAwardedFinalEstimatesValues",
                type: WidgetType.Grid,
                layoutTemplateArea: "grid",
                settings: [
                    { field: "projectId", headerName: "Project ID", lockVisible: true },
                    { field: "projectName", headerName: "Project Name", lockVisible: true },
                    { field: "projectStatus", headerName: "Status" },
                    { field: "organization", headerName: "Organization" },
                    { field: "salesPerson", headerName: "Sales Person" },
                    { field: "alternateQty", headerName: "Alternate Qty" },
                    { field: "finalEstimateId", headerName: "Final Estimate ID" },
                    { field: "finalEstimateName", headerName: "Final Estimate Name" },
                    { field: "creationDate", headerName: "Creation Date" },
                    { field: "grossMargin", headerName: "Gross Margin" },
                    { field: "sellPrice", headerName: "Sell Price" },
                    { field: "tdc", headerName: "TDC" },
                    { field: "markets", headerName: "Markets", type: "array" },
                    { field: "lead", headerName: "Lead" }
                ]
            }
        ],
        layout: GridLayout.grid1
    },
    filters: {
        main: [...salesMainFilters],
        additional: [
            projectDueDateEditorConfig,
            projectStartDateEditorConfig,
            projectEndDateEditorConfig,
            projectStatusEditorConfig,
            marketsEditorConfig,
            opportunitySourceEditorConfig
        ]
    }
};
