import { Injectable } from "@angular/core";
import {
    IContactClient,
    ContactClient,
    SearchContactsBasicDataParameters,
    SearchContactsBasicDataResultDto
} from "./reporting-api-proxies";
import { BaseDtoSearchService, IBaseDto } from "./base-dto.service";

@Injectable({
    providedIn: "root"
})
export class ReportingContactService extends BaseDtoSearchService {
    private client: IContactClient;
    constructor() {
        super();
        this.client = new ContactClient();
    }

    async search(filterText: string): Promise<IBaseDto[]> {
        const response = await this.client.contactSearchBasicData({ filterText } as SearchContactsBasicDataParameters);

        return response.map(contact => ({
            id: contact.id,
            name: contact.fullName
        }));
    }

    override getById(id: number): Promise<IBaseDto> {
        throw new Error("Method not implemented.");
    }

    async getByIds(contactIds: number[]): Promise<SearchContactsBasicDataResultDto[]> {
        return await this.client.contactSearchBasicData({ contactIds } as SearchContactsBasicDataParameters);
    }
}
