import { Injectable } from "@angular/core";
import { CompanyTypeClient, CompanyTypeView, ICompanyTypeClient } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class CompanyTypeService {
    private client: ICompanyTypeClient;
    constructor() {
        this.client = new CompanyTypeClient();
    }


    async getAll(): Promise<CompanyTypeView[]> {
        return await this.client.companyTypeGetAll();
    }
}
