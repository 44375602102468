import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "formatNumberWithSuffix"
})
export class FormatNumberWithSuffixPipe implements PipeTransform {
    transform(value: number): string {
        if (value >= 1_000_000) {
            return `${(value / 1_000_000).toFixed(1).replace(/\.0$/, "")} M`;
        } else if (value >= 1000) {
            return `${(value / 1_000).toFixed(1).replace(/\.0$/, "")} k`;
        }
        return Number.isInteger(value) ? value.toString() : value.toFixed(2);
    }
}
