<div
    *ngIf="tooltip"
    class="tooltip"
    [ngClass]="['tooltip-' + position]"
    [style.left]="left + 'px'"
    [style.top]="top + 'px'"
    [class.tooltip-visible]="visible"
    [id]="tooltipId | idGenerator: 'ngx-tooltip-'"
>
    <div [innerHTML]="tooltip"></div>
</div>
