import { Injectable } from "@angular/core";
import { ICustomInfoEntity } from "./custom-info-entity";
import { ISearchCustomInfoEntityParameters } from "./search-custom-info-entity-parameters";

@Injectable({
    providedIn: "root"
})
export abstract class CustomizableEntityService {
    abstract search(parameters: ISearchCustomInfoEntityParameters): Promise<ICustomInfoEntity[]>;
    abstract getById(id: number): Promise<ICustomInfoEntity>;
    abstract save(body: ICustomInfoEntity[]): Promise<ICustomInfoEntity[]>;
    abstract activate(id: number): Promise<void>;
    abstract deactivate(id: number): Promise<void>;
}
