<lib-generic-dropdown-multiselect
    name="entity"
    id="entity"
    [source]="items"
    [(selectedItems)]="selectedItems"
    (selectedItemsChange)="onSelectedItemsChange()"
    [config]="{
        multiple: true,
        defaultText: defaultText,
        itemDisplayKey: additionalInfo?.itemDisplayKey ?? 'name'
    }"
>
</lib-generic-dropdown-multiselect>
