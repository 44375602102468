import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-loading-button",
    templateUrl: "./loading-button.component.html",
    styleUrls: ["./loading-button.component.scss"]
})
export class LoadingButtonComponent {
    @Input()
    isLoading: boolean;

    @Input()
    text: string;

    @Input()
    disabled: boolean;

    @Output()
    clickEvent: EventEmitter<Event>;

    constructor() {
        this.isLoading = false;
        this.disabled = false;
        this.text = "Submit";
        this.clickEvent = new EventEmitter<Event>();
    }

    handleClick(event: Event) {
        if (!this.isLoading) {
            this.clickEvent.emit(event);
        }
    }
}
