import { Injectable } from "@angular/core";
import { IMarketClient, MarketClient } from "./reporting-api-proxies";
import { BaseDtoService, IBaseDto } from "./base-dto.service";

@Injectable({
    providedIn: "root"
})
export class MarketService extends BaseDtoService {
    private client: IMarketClient;
    constructor() {
        super();
        this.client = new MarketClient();
    }

    async getAll(): Promise<IBaseDto[]> {
        return await this.client.marketGetAll();
    }

    async getById(id: number): Promise<IBaseDto> {
        return await this.client.marketGetById(id);
    }

    async getByIds(ids: number[]): Promise<IBaseDto[]> {
        return await this.client.marketGetByIds(ids);
    }
}
