import { Injectable } from "@angular/core";
import {
    BoxResultType,
    OpportunityClient,
    GetOpportunityColumnChartValuesResultType,
    GetOpportunityMetricsValuesResultType,
    GetOpportunityValuesParameters,
    GetOpportunityValuesResultType,
    CustomReportOpportunityView,
    GetOpportunityLineChartValuesResultType,
    SharedReportDto
} from "./reporting-api-proxies";
import { CustomReportServiceBase } from "./custom-report/custom-report-base.service";

@Injectable({
    providedIn: "root"
})
export class ReportingOpportunityService extends CustomReportServiceBase<OpportunityClient, CustomReportOpportunityView> {
    constructor() {
        super(new OpportunityClient());
    }

    async getOpportunityValues(body: GetOpportunityValuesParameters): Promise<GetOpportunityValuesResultType[]> {
        return await this.client.opportunityGetOpportunityValues(body);
    }

    async getOpportunityMetricsValues(body: GetOpportunityValuesParameters): Promise<GetOpportunityMetricsValuesResultType> {
        return await this.client.opportunityGetOpportunityMetricsValues(body);
    }

    async getOpportunityBoxesValues(body: GetOpportunityValuesParameters): Promise<BoxResultType[]> {
        return await this.client.opportunityGetOpportunityBoxesValues(body);
    }

    async getOpportunityLineChartValues(body: GetOpportunityValuesParameters): Promise<GetOpportunityLineChartValuesResultType[]> {
        return await this.client.opportunityGetOpportunityLineChartValues(body);
    }

    async getOpportunityColumnChartValues(body: GetOpportunityValuesParameters): Promise<GetOpportunityColumnChartValuesResultType[]> {
        return await this.client.opportunityGetOpportunityColumnChartValues(body);
    }

    override async saveCustomReport(body: CustomReportOpportunityView): Promise<CustomReportOpportunityView> {
        if (body.isPublic)
            return await this.client.opportunitySaveCatalogReport(body);
        else
            return await this.client.opportunitySaveCustomReport(body);
    }

    override async getCustomReport(id: number): Promise<CustomReportOpportunityView> {
        return await this.client.opportunityGetCustomReport(id);
    }

    override async shareReport(body: CustomReportOpportunityView): Promise<SharedReportDto> {
        return await this.client.opportunityCreateSharedReport(body);
    }

    override async getSharedReport(id: string): Promise<CustomReportOpportunityView> {
        return await this.client.opportunityGetSharedReport(id);
    }
}
