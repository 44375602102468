import { ReportingBaseClient, SharedReportDto } from "../reporting-api-proxies";

export interface ICustomReportServiceBase<TView> {
    saveCustomReport(body: TView): Promise<TView>;

    getCustomReport(id: number): Promise<TView>;

    shareReport(body: TView): Promise<SharedReportDto>;

    getSharedReport(id: string): Promise<TView>;
}

export abstract class CustomReportServiceBase<TClient extends ReportingBaseClient, TView> implements ICustomReportServiceBase<TView> {
    protected client: TClient;
    constructor(client: TClient) {
        this.client = client;
    }

    abstract saveCustomReport(body: TView): Promise<TView>;

    abstract getCustomReport(id: number): Promise<TView>;

    abstract shareReport(body: TView): Promise<SharedReportDto>;

    abstract getSharedReport(id: string): Promise<TView>;
}
