<div class="container" [id]="noDataId | idGenerator: 'ngx-noData-'">
    <div *ngIf="iconName" class="icon" [innerHTML]="svg"></div>
    <p class="title">{{ title }}</p>
    <p *ngIf="text || linkText" class="text">
        {{ text }}
        <span *ngIf="linkText && linkAction" class="linkText" title="{{ linkText }}" (click)="onLinkClicked()" (keyup.enter)="onLinkClicked()" tabindex="0">
            {{ linkText }}
        </span>
    </p>
</div>
