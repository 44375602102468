import { Injectable, Injector, Type } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ReportsService {
    private _selectedProperty = new BehaviorSubject<boolean | undefined>(false);

    public readonly selectedProperty$: Observable<boolean | undefined> = this._selectedProperty.asObservable();

    constructor(private readonly injector: Injector) {}

    setSelectedProperty(selectedProperty?: boolean): void {
        this._selectedProperty.next(selectedProperty);
    }

    getService<T>(serviceType: Type<T>): T {
        return this.injector.get(serviceType);
    }
}
