import { Injectable } from "@angular/core";
import { IProductTypeClient, ProductTypeClient, ProductTypeView, SearchProductTypesParameters } from "./api-proxies";
import { CustomizableEntityService } from "./customizable-entity-service";
import { ISearchCustomInfoEntityParameters } from "./search-custom-info-entity-parameters";

@Injectable({
    providedIn: "root"
})
export class ProductTypeService extends CustomizableEntityService {
    private readonly client: IProductTypeClient;
    constructor() {
        super();
        this.client = new ProductTypeClient();
    }

    async search(parameters: ISearchCustomInfoEntityParameters): Promise<ProductTypeView[]> {
        return await this.client.productTypeSearchProductTypes(parameters as SearchProductTypesParameters);
    }

    async getById(id: number): Promise<ProductTypeView> {
        return await this.client.productTypeGetById(id);
    }

    async save(body: ProductTypeView[]): Promise<ProductTypeView[]> {
        return await this.client.productTypeSave(body);
    }

    async activate(id: number): Promise<void> {
        return await this.client.productTypeActivate(id);
    }

    async deactivate(id: number): Promise<void> {
        return await this.client.productTypeDeactivate(id);
    }
}
