import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { ITypeAheadDropdownEntityParameters } from "projects/app/src/app/interfaces/type-ahead-dropdown-entity-parameters.interface";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { CompanyView, SaveAccountParameters, SearchCompaniesForAccountParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { CompanyService } from "projects/app/src/app/services/http/clients/company.service";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { ModalService } from "projects/ngx-lib/src/lib/services/modal.service";
import { SnackbarNotificationService } from "projects/ngx-lib/src/lib/services/snackbar-notification.service";
import { ModalConfirmComponent } from "../../../../shared/modals/modal-confirm/modal-confirm.component";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";

@Component({
    selector: "app-accounts-single-companies",
    templateUrl: "./accounts-single-companies.component.html",
    styleUrls: ["./accounts-single-companies.component.scss"]
})
export class AccountsSingleCompaniesComponent implements OnChanges {
    @Input()
    accountId?: number;

    @Output()
    tabCounterChange: EventEmitter<void>;

    companies: CompanyView[];

    orderConfig?: IGenericGridOrderConfig<CompanyView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchCompaniesForAccountParameters;

    typeAheadSearchByEntityParameters: ITypeAheadDropdownEntityParameters;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly companyService: CompanyService,
        private readonly awaiter: AwaiterService,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly snackbarNotificationService: SnackbarNotificationService
    ) {
        this.companies = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            accountId: this.accountId
        } as SearchCompaniesForAccountParameters;

        this.typeAheadSearchByEntityParameters = {
            entityKeyName: EntityTypes.Account
        };

        this.noResults = true;
        this.isLoading = true;

        this.tabCounterChange = new EventEmitter<void>();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.accountId && changes["accountId"]) {
            this.searchConfig.accountId = this.accountId;
            this.typeAheadSearchByEntityParameters.entity = {
                accountId: this.accountId
            };
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.accountSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Companies",
            async () => {
                const response = await this.companyService.searchForAccount(this.searchConfig);

                const { pageInfo, results } = response;

                this.companies = this.companies?.concat(results ?? []);

                this.noResults = this.companies.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;

        this.companies = [];

        await this.refreshGridView();
    }

    onRowClicked(company: CompanyView) {
        this.router.navigate(["/companies-single", company.id, TabNames[TabNames.Overview]]);
    }

    onMouseWheelClicked(company: CompanyView) {
        if (company?.id && company.id > 0) {
            window.open(`/companies-single/${company.id}/${TabNames[TabNames.Overview]}`, "_blank");
        }
    }

    async onSelectedCompany(company: CompanyView): Promise<void> {
        if (!company || !this.accountId) return;

        try {
            const companySaved = await this.companyService.setAccount({
                id: company.id,
                accountId: this.accountId
            } as SaveAccountParameters);

            if (companySaved) {
                this.tabCounterChange.emit();
                this.companies.unshift(companySaved);
            }
            this.noResults = false;
        } catch (error) {
            this.snackbarNotificationService.error("An error occurred while trying to save the company");
        }
    }

    async removeItem(company: CompanyView): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Remove company",
            cancelCaption: "Cancel",
            content: "Are you sure you want to remove this company from the company list?",
            title: "Remove company"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction(
            "Removing company",
            async () => {
                if (!company) return;

                await this.companyService.removeAccount(company.id);

                const companyIndex = this.companies.findIndex(comp => comp.id === company.id);
                this.companies.splice(companyIndex, 1);
                this.noResults = this.companies.length === 0;
                this.tabCounterChange.emit();
            },
            loading => (this.isLoading = loading)
        );
    }

    private accountSearchConfig() {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            sortDirection: this.orderConfig?.order,
            sortBy: this.orderConfig?.key,
            accountId: this.accountId
        } as SearchCompaniesForAccountParameters;
    }
}
