import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICol, IGroupLevel } from '../group-by-rows-filter.component';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'app-column-selector',
  templateUrl: './column-selector.component.html',
  styleUrls: ['./column-selector.component.scss']
})
export class ColumnSelectorComponent {
    @Input()
    cols: ColDef[] = [];

    @Input()
    group!: IGroupLevel;

    @Input()
    selectedColumn?: ColDef;

    @Output()
    selectedColumnChange = new EventEmitter<ColDef>();

    @Output()
    closeOverlayEvent = new EventEmitter();

    selectColumn(col: ColDef) {
        this.selectedColumn = col;
    }

    applySelection() {
        if (this.selectedColumn) {
            this.selectedColumnChange.emit(this.selectedColumn);
        }
    }

    closeOverlay() {
        this.closeOverlayEvent.emit();
    }
}
