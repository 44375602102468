<aside [ngClass]="{ expanded: isExpanded }" [id]="genericSideNavId | idGenerator: 'ngx-genericSideNav-'">
    <span *ngIf="logoCollapsed && !isExpanded" class="logo" [innerHTML]="svgCollapsed"></span>
    <span *ngIf="logoExpanded && isExpanded" class="logo" [innerHTML]="svgExpanded"></span>
    <button class="expandButton" (click)="onToggleButton()" tabindex="0" aria-label="Open and close menu">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.70492 6L8.29492 7.41L12.8749 12L8.29492 16.59L9.70492 18L15.7049 12L9.70492 6Z" fill="white" />
        </svg>
    </button>
    <nav>
        <lib-generic-menu [config]="{ defaultText: 'defaultText' }" [source]="source" [isExpanded]="isExpanded" [currentUrl]="currentUrl"></lib-generic-menu>
    </nav>
</aside>
