import { Component, OnInit } from "@angular/core";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";
import { AccountView } from "projects/app/src/app/services/http/clients/api-proxies";
import { AccountService } from "projects/app/src/app/services/http/clients/account.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-account-editor",
    templateUrl: "./account-editor.component.html",
    styleUrls: ["./account-editor.component.scss"]
})
export class AccountEditorComponent extends EditorComponentBase<number | undefined> implements OnInit {
    selectedAccount?: AccountView;

    displaySelectedAccount: boolean;

    allowToNavigate?: boolean;

    routeToSingle?: string;

    constructor(private readonly accountService: AccountService) {
        super();
        this.displaySelectedAccount = true;
    }

    async ngOnInit(): Promise<void> {
        this.allowToNavigate = this.additionalInfo?.allowToNavigate;
        if (this.bindingProperty) {
            this.selectedAccount = await this.accountService.getById(this.bindingProperty);
            if (this.allowToNavigate) this.setRouteToNavigate(this.selectedAccount);
        }
    }

    onSelectedAccount(account: AccountView) {
        this.selectedAccount = account;
        this.bindingProperty = this.selectedAccount?.id;
        this.validate();
        this.valueChange.emit();
    }

    setRouteToNavigate(account: AccountView) {
        this.routeToSingle = `accounts-single/${account.id}/${TabNames[TabNames.Overview]}`;
    }
}
