import { Component } from "@angular/core";
import { ReportingCompanySearchService } from "projects/app/src/app/services/http/clients/reporting-app/reporting-company-search.service";
import { MultipleTypeAheadValuesEditorComponent } from "../multiple-type-ahead-values-editor/multiple-type-ahead-values-editor.component";
import { ReportsService } from "../../../../../services/reports.service";

@Component({
    selector: "app-reporting-company-editor",
    templateUrl: "../multiple-type-ahead-values-editor/multiple-type-ahead-values-editor.component.html",
    styleUrls: ["../multiple-type-ahead-values-editor/multiple-type-ahead-values-editor.component.scss"]
})
export class ReportingCompanyEditorComponent extends MultipleTypeAheadValuesEditorComponent {
    constructor(
        private readonly reportingCompanySearchService: ReportingCompanySearchService,
        reportsService: ReportsService
    ) {
        super(reportingCompanySearchService, reportsService);
        this.placeholder = "Search Customers";
    }
}
