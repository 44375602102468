import { Injectable } from "@angular/core";
import {
    ICustomReportSearchClient,
    CustomReportView,
    CustomReportSearchClient,
    SearchReportTemplateParameters,
    CustomReportUserFavoriteClient,
    ICustomReportUserFavoriteClient
} from ".././reporting-api-proxies";

export interface IReportsSearchService<TView> {
    searchReports: (body: SearchReportTemplateParameters) => Promise<TView[]>;
    toggleFavorite: (id: number) => Promise<boolean>;
}

@Injectable({
    providedIn: "root"
})
export class CustomReportSearchService implements IReportsSearchService<CustomReportView> {
    private client: ICustomReportSearchClient;
    private favoriteClient: ICustomReportUserFavoriteClient;
    constructor() {
        this.client = new CustomReportSearchClient();
        this.favoriteClient = new CustomReportUserFavoriteClient();
    }

    async searchReports(body: SearchReportTemplateParameters): Promise<CustomReportView[]> {
        return await this.client.customReportSearchSearchCustomReports(body);
    }

    async searchCatalogReports(body: SearchReportTemplateParameters): Promise<CustomReportView[]> {
        return await this.client.customReportSearchSearchCatalogReports(body);
    }

    async toggleFavorite(id: number): Promise<boolean> {
        return await this.favoriteClient.customReportUserFavoriteToggleFavorite(id);
    }

    async deactivate(id: number): Promise<void> {
        await this.client.customReportSearchDeactivate(id);
    }
}