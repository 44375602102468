import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { IGenericTypeAheadDropdownConfig, ITypeAheadDropdownAction } from "../../interfaces/type-ahead-dropdown-config.interface";

@Component({
    selector: "lib-options-menu",
    templateUrl: "./options-menu.component.html",
    styleUrls: ["./options-menu.component.scss"]
})
export class OptionsMenuComponent<T> implements OnChanges, AfterViewInit {
    @Input() source?: T[];
    @Input() menuOpen?: boolean;
    @Input() config?: IGenericTypeAheadDropdownConfig<T>;
    @Input() selectedItem?: T;
    @Input() selectedItems?: T[];
    @Input() text: string;
    @Input() noResultsText?: string;
    @Input() action?: ITypeAheadDropdownAction;
    @Input() focusList?: boolean;
    @Input() isItemDisabled?: boolean;
    @Input() menuId?: string;

    @Output() selectedItemChange: EventEmitter<T>;
    @Output() menuOpenChange: EventEmitter<boolean>;
    @Output() selectedItemsChange = new EventEmitter<T[]>();
    @Output() listKeyDown = new EventEmitter<{
        event: KeyboardEvent;
        item: T;
    }>();
    @Output() runActionEmitter = new EventEmitter<void>();

    focusedIndex: number;

    @ViewChild("itemsList")
    itemsList!: ElementRef<HTMLUListElement | undefined>;

    constructor() {
        this.text = "";
        this.selectedItem = undefined;
        this.selectedItemChange = new EventEmitter<T>();
        this.menuOpenChange = new EventEmitter<boolean>();
        this.focusedIndex = 0;
    }

    ngAfterViewInit(): void {
        this.itemsList = this.itemsList as ElementRef<HTMLUListElement | undefined>;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["focusList"]) {
            if (this.focusList) (this.itemsList.nativeElement?.children[0] as HTMLLIElement).focus();
        }
    }

    onSelectItem(item: T) {
        this.selectedItemChange.emit(item);
    }

    onListKeyDown(event: KeyboardEvent, item: T): void {
        if (this.source && this.menuOpen) {
            if (event.key === "ArrowDown") {
                event.preventDefault();
                if (this.focusedIndex !== this.source.length - 1) {
                    this.focusedIndex++;
                    this.focusListItem();
                }
            } else if (event.key === "ArrowUp") {
                event.preventDefault();
                if (this.focusedIndex !== 0) {
                    this.focusedIndex--;
                    this.focusListItem();
                }
            } else if (event.key === "Enter") {
                event.preventDefault();
                this.onSelectItem(item);
            } else if (event.key === "Tab") {
                this.itemsList?.nativeElement?.childNodes.forEach(item => ((item as HTMLLIElement).tabIndex = -1));
                if (this.config?.clearSelection || this.action?.includeAction) return;
                this.menuOpenChange.emit(false);
            } else if (event.key === "Escape") {
                this.menuOpenChange.emit(false);
            }
        }
        this.listKeyDown.emit({ event, item });
    }

    onClearSelectionClicked() {
        if (!this.config?.clearSelection || this.config?.multiple) return;
        this.selectedItemChange.emit();
    }

    runAction(callback?: () => Promise<void>) {
        if (!callback) return;
        this.runActionEmitter.emit();
        callback();
    }

    getTemplate(): TemplateRef<unknown> | null {
        if (!this.config?.itemTemplate) return null;
        return this.config.itemTemplate;
    }

    focusListItem(forcedIndex?: number): void {
        const activeListItem = this.itemsList?.nativeElement?.children[forcedIndex ?? this.focusedIndex] as HTMLLIElement | undefined;
        if (activeListItem) {
            this.itemsList?.nativeElement?.childNodes.forEach(item => {
                if (item === activeListItem) {
                    activeListItem.tabIndex = 0;
                    activeListItem.focus();
                } else (item as HTMLLIElement).tabIndex = -1;
            });
        }
    }
}
