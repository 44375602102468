import { Injectable } from "@angular/core";
import {
    BoxResultType,
    CustomReportCompanyView,
    CompanyClient,
    GetCompanyColumnChartValuesResultType,
    GetCompanyLineChartValuesResultType,
    GetCompanyMetricsValuesResultType,
    GetCompanyValuesParameters,
    GetCompanyValuesResultType,
    SharedReportDto
} from "./reporting-api-proxies";
import { CustomReportServiceBase } from "./custom-report/custom-report-base.service";

@Injectable({
    providedIn: "root"
})
export class ReportingCompanyService extends CustomReportServiceBase<CompanyClient, CustomReportCompanyView> {
    constructor() {
        super(new CompanyClient());
    }

    async getCompanyBoxesValues(parameters: GetCompanyValuesParameters): Promise<BoxResultType[]> {
        return await this.client.companyGetCompanyBoxesValues(parameters);
    }

    async getCompanyColumnChartValues(parameters: GetCompanyValuesParameters): Promise<GetCompanyColumnChartValuesResultType[]> {
        return await this.client.companyGetCompanyColumnChartValues(parameters);
    }

    async getCompanyLineChartValues(parameters: GetCompanyValuesParameters): Promise<GetCompanyLineChartValuesResultType[]> {
        return await this.client.companyGetCompanyLineChartValues(parameters);
    }

    async getCompanyMetricsValues(parameters: GetCompanyValuesParameters): Promise<GetCompanyMetricsValuesResultType> {
        return await this.client.companyGetCompanyMetricsValues(parameters);
    }

    async getCompanyValues(parameters: GetCompanyValuesParameters): Promise<GetCompanyValuesResultType[]> {
        return await this.client.companyGetCompanyValues(parameters);
    }
    override async saveCustomReport(body: CustomReportCompanyView): Promise<CustomReportCompanyView> {
        if (body.isPublic)
            return await this.client.companySaveCatalogReport(body);
        else
            return await this.client.companySaveCustomReport(body);
    }


    override async getCustomReport(id: number): Promise<CustomReportCompanyView> {
        return await this.client.companyGetCustomReport(id);
    }

    override async shareReport(body: CustomReportCompanyView): Promise<SharedReportDto> {
        return await this.client.companyCreateSharedReport(body);
    }

    override async getSharedReport(id: string): Promise<CustomReportCompanyView> {
        return await this.client.companyGetSharedReport(id);
    }
}
