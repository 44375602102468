<div class="calendar-wrapper" [id]="calendarId | idGenerator: 'ngx-calendar-'">
    <div class="month-container" *ngIf="calendarTypeDisplay === 'Month'">
        <div class="month-container-header">
            <button class="month-name" (click)="navigateToCalendarType(allCalendarTypes.YEAR, $event)" tabindex="0">
                {{ monthCalendarTitle() }}
            </button>
            <div class="action">
                <button class="prev-month" (click)="navigateToMonth(-1)" aria-label="Previous month" tabindex="0" [disabled]="disablePreviousMonth()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <mask id="mask0_3689_10678" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                            <rect x="0.606445" y="0.446777" width="20" height="20" fill="black" />
                        </mask>
                        <g mask="url(#mask0_3689_10678)">
                            <path
                                d="M12.6064 15.4468L7.60645 10.4468L12.6064 5.44678L13.6684 6.50878L9.73145 10.4468L13.6684 14.3848L12.6064 15.4468Z"
                                fill="black"
                            />
                        </g>
                    </svg>
                </button>
                <button class="next-month" (click)="navigateToMonth(1)" aria-label="Next month" tabindex="0" [disabled]="disableNextMonth()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <mask id="mask0_3689_10683" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                            <rect x="0.606445" y="0.446777" width="20" height="20" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_3689_10683)">
                            <path
                                d="M8.60643 15.4468L7.54443 14.3848L11.4814 10.4468L7.54443 6.50878L8.60643 5.44678L13.6064 10.4468L8.60643 15.4468Z"
                                fill="black"
                            />
                        </g>
                    </svg>
                </button>
            </div>
        </div>
        <div class="month-container-body">
            <table>
                <tr class="day-container">
                    <th *ngFor="let header of daysHeader">
                        <div>{{ header }}</div>
                    </th>
                </tr>
                <tr *ngFor="let row of daysMatrix" class="day-number">
                    <td *ngFor="let date of row" class="day-number" tabindex="0" (keydown)="onSelectDateKeyDown(date, $event)">
                        <div
                            [ngClass]="{
                                selected: isSelectedDate(date),
                                today: isToday(date),
                                disabled: isDisabledDate(date) || showAsDisabledAnotherMonthDate(date)
                            }"
                            (click)="onSelectDateClick(date, $event)"
                        >
                            {{ date.day }}
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div class="year-container" *ngIf="calendarTypeDisplay === 'Year'">
        <div class="year-container-header">
            <button class="year-number" (click)="navigateToCalendarType(allCalendarTypes.DECADE, $event)">
                {{ yearCalendarTitle() }}
            </button>
            <div class="action">
                <button class="prev-year" (click)="navigateToYear(-1)" aria-label="Previous year" tabindex="0" [disabled]="disablePreviousYear()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <mask id="mask0_3689_10678" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                            <rect x="0.606445" y="0.446777" width="20" height="20" fill="black" />
                        </mask>
                        <g mask="url(#mask0_3689_10678)">
                            <path
                                d="M12.6064 15.4468L7.60645 10.4468L12.6064 5.44678L13.6684 6.50878L9.73145 10.4468L13.6684 14.3848L12.6064 	15.4468Z"
                                fill="black"
                            />
                        </g>
                    </svg>
                </button>
                <button class="next-year" (click)="navigateToYear(1)" aria-label="Next year" tabindex="0" [disabled]="disableNextYear()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <mask id="mask0_3689_10683" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                            <rect x="0.606445" y="0.446777" width="20" height="20" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_3689_10683)">
                            <path
                                d="M8.60643 15.4468L7.54443 14.3848L11.4814 10.4468L7.54443 6.50878L8.60643 5.44678L13.6064 10.4468L8.60643 	15.4468Z"
                                fill="black"
                            />
                        </g>
                    </svg>
                </button>
            </div>
        </div>
        <div class="year-container-body">
            <tr *ngFor="let row of monthsMatrix">
                <td *ngFor="let date of row" tabindex="0" (keydown)="onSelectMonthKeyDown(date, $event)">
                    <div
                        [ngClass]="{
                            selected: isSelectedMonth(date),
                            disabled: isDisabledMonth(date)
                        }"
                        (click)="onSelectMonthClick(date, $event)"
                    >
                        {{ date.monthShort | uppercase }}
                    </div>
                </td>
            </tr>
        </div>
    </div>
    <div class="decade-container" *ngIf="calendarTypeDisplay === 'Decade'">
        <div class="decade-container-header">
            <p class="decade-range">{{ decadeCalendarTitle() }}</p>

            <div class="action">
                <button class="prev-decade" (click)="navigateToDecade(-1)" aria-label="Previous decade" tabindex="0" [disabled]="disablePreviousDecade()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <mask id="mask0_3689_10678" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                            <rect x="0.606445" y="0.446777" width="20" height="20" fill="black" />
                        </mask>
                        <g mask="url(#mask0_3689_10678)">
                            <path
                                d="M12.6064 15.4468L7.60645 10.4468L12.6064 5.44678L13.6684 6.50878L9.73145 10.4468L13.6684 14.3848L12.6064 	15.4468Z"
                                fill="black"
                            />
                        </g>
                    </svg>
                </button>
                <button class="next-decade" (click)="navigateToDecade(1)" aria-label="Next decade" tabindex="0" [disabled]="disableNextDecade()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <mask id="mask0_3689_10683" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                            <rect x="0.606445" y="0.446777" width="20" height="20" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_3689_10683)">
                            <path
                                d="M8.60643 15.4468L7.54443 14.3848L11.4814 10.4468L7.54443 6.50878L8.60643 5.44678L13.6064 10.4468L8.60643 	15.4468Z"
                                fill="black"
                            />
                        </g>
                    </svg>
                </button>
            </div>
        </div>
        <div class="decade-container-body">
            <tr *ngFor="let row of yearsMatrix">
                <td *ngFor="let date of row" tabindex="0" (keydown)="onSelectYearKeyDown(date, $event)">
                    <div
                        [ngClass]="{
                            selected: isSelectedYear(date),
                            disabled: isDisabledYear(date)
                        }"
                        (click)="onSelectYearClick(date, $event)"
                    >
                        {{ date.year }}
                    </div>
                </td>
            </tr>
        </div>
    </div>
</div>
