import { Injectable } from "@angular/core";
import { ILeadClient, LeadDto, LeadClient } from "./reporting-api-proxies";
import { BaseDtoService } from "./base-dto.service";

@Injectable({
    providedIn: "root"
})
export class LeadService extends BaseDtoService {
    private client: ILeadClient;
    constructor() {
        super();
        this.client = new LeadClient();
    }

    async getAll(): Promise<LeadDto[]> {
        return await this.client.leadGetAll();
    }

    async getById(id: number): Promise<LeadDto> {
        return await this.client.leadGetById(id);
    }

    async getByIds(ids: number[]): Promise<LeadDto[]> {
        return await this.client.leadGetByIds(ids);
    }
}
