import { Injectable } from "@angular/core";
import { BaseDtoSearchService, IBaseDto } from "./base-dto.service";
import { IOpportunityClient, QuickSearchOpportunitiesParameters, OpportunityClient } from "./reporting-api-proxies";

@Injectable({
    providedIn: "root"
})
export class ReportingOpportunitiesSearchService extends BaseDtoSearchService {
    client: IOpportunityClient;
    constructor() {
        super();
        this.client = new OpportunityClient();
    }

    async search(filterText: string): Promise<IBaseDto[]> {
        return await this.client.opportunityQuickSearch({ filterText } as QuickSearchOpportunitiesParameters);
    }

    override getById(ids: number): Promise<IBaseDto> {
        throw new Error("Method not implemented.");
    }

    async getByIds(opportunityIds: number[]): Promise<IBaseDto[]> {
        return await this.client.opportunityQuickSearch({ opportunityIds } as QuickSearchOpportunitiesParameters);
    }
}
