<div class="tabContainer" [ngClass]="{ column: direction === 'column' }" [id]="tabViewId | idGenerator: 'ngx-tabView-'">
    <div
        class="nav"
        [ngClass]="{
            hasSteps: hasSteps,
            hasQuantity: hasQuantity,
            column: direction === 'column',
            alignStart: alignment === 'start',
            alignCenter: alignment === 'center',
            alignEnd: alignment === 'end',
            narrowSeparation: separation === 'narrow',
            normalSeparation: separation === 'normal',
            wideSeparation: separation === 'wide',
            containerBackground: containerBackground
        }"
    >
        <div
            [ngClass]="{ active: tab === getActiveComponent(), disabled: tab.disabled, containerBackground: containerBackground }"
            class="nav-item"
            *ngFor="let tab of tabs; index as i"
        >
            <span class="separator" *ngIf="hasSteps && i !== 0">
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="25" viewBox="0 0 23 25" fill="none">
                    <path
                        d="M12.5068 12.0287L8.74295 7.94596C8.58131 7.77063 8.50049 7.57651 8.50049 7.36361C8.50049 7.15071 8.58131 6.95241 8.74295 6.76873C8.90458 6.58505 9.08546 6.49113 9.28559 6.48695C9.48571 6.48278 9.67304 6.57535 9.84758 6.76468L14.1578 11.4401C14.2271 11.5219 14.2829 11.6133 14.3252 11.7143C14.3676 11.8154 14.3887 11.9218 14.3887 12.0337C14.3887 12.1456 14.3676 12.252 14.3252 12.353C14.2829 12.4541 14.2271 12.5421 14.1578 12.6173L9.84758 17.2927C9.67304 17.482 9.48956 17.5704 9.29713 17.5579C9.10471 17.5454 8.92767 17.4473 8.76604 17.2636C8.6044 17.0799 8.52358 16.8816 8.52358 16.6687C8.52358 16.4558 8.6044 16.2617 8.76604 16.0863L12.5068 12.0287Z"
                        fill="#A19F9D"
                    />
                </svg>
            </span>
            <button
                class="tabName"
                (click)="setActiveComponent(tab)"
                [disabled]="tab.disabled"
                tabindex="0"
                [ngClass]="{ row: direction === 'row', column: direction === 'column' }"
            >
                <div class="content">
                    <div>
                        <span *ngIf="tab.icon" class="icon" [innerHTML]="tab.icon"></span>
                        <ng-container *ngIf="tab.headerTemplate">
                            <ng-container *ngTemplateOutlet="tab.headerTemplate; context: { tab: tab }"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="!tab.headerTemplate">
                            <span class="text">{{ tab.tabName }}</span>
                        </ng-container>
                    </div>
                </div>
            </button>
        </div>
        <div class="scroll-ref" *ngIf="paginationConfig" libIntersectionListener (intersectionListener)="goNext()">.</div>
        <div *ngIf="direction === 'row'" class="horizontalLine"></div>
    </div>

    <div class="content" [ngClass]="{ fixedContentHeader: fixedContentHeader }">
        <ng-container *ngIf="!renderAll">
            <ng-container *ngIf="fixedContentHeader">
                <ng-container *ngTemplateOutlet="fixedContentHeader"></ng-container>
            </ng-container>
            <ng-template [ngTemplateOutlet]="getActiveComponent().bodyTemplate"></ng-template>
        </ng-container>

        <ng-container *ngIf="renderAll">
            <ng-container *ngIf="fixedContentHeader">
                <ng-container *ngTemplateOutlet="fixedContentHeader"></ng-container>
            </ng-container>
            <ng-container *ngFor="let tab of tabs">
                <div [hidden]="tab !== getActiveComponent()">
                    <ng-template [ngTemplateOutlet]="tab.bodyTemplate"></ng-template>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
