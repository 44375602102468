import { Component } from "@angular/core";
import { MultipleDropdownValuesEditorComponent } from "../multiple-dropdown-values-editor/multiple-dropdown-values-editor.component";
import { OpportunityStatusService } from "projects/app/src/app/services/http/clients/opportunity-status.service";
import { ReportsService } from "projects/app/src/app/services/reports.service";

@Component({
    selector: "app-reporting-opportunity-status-editor",
    templateUrl: "../multiple-dropdown-values-editor/multiple-dropdown-values-editor.component.html",
    styleUrls: ["../multiple-dropdown-values-editor/multiple-dropdown-values-editor.component.scss"]
})
export class ReportingOpportunityStatusEditorComponent extends MultipleDropdownValuesEditorComponent {
    constructor(opportunityStatusService: OpportunityStatusService, reportsService: ReportsService) {
        super(opportunityStatusService, reportsService);
        this.defaultText = "Select Opportunity Status";
    }
}
