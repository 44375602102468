import { Injectable } from "@angular/core";
import { IBaseEstimateStatusClient, BaseEstimateStatusDto, BaseEstimateStatusClient } from "./reporting-api-proxies";
import { BaseDtoService } from "./base-dto.service";

@Injectable({
    providedIn: "root"
})
export class BaseEstimateStatusService extends BaseDtoService {
    private client: IBaseEstimateStatusClient;
    constructor() {
        super();
        this.client = new BaseEstimateStatusClient();
    }

    async getAll(): Promise<BaseEstimateStatusDto[]> {
        return await this.client.baseEstimateStatusGetAll();
    }

    async getById(id: number): Promise<BaseEstimateStatusDto> {
        return await this.client.baseEstimateStatusGetById(id);
    }

    async getByIds(ids: number[]): Promise<BaseEstimateStatusDto[]> {
        return await this.client.baseEstimateStatusGetByIds(ids);
    }
}
