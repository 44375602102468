import { Injectable } from "@angular/core";
import { IOpportunityStatusClient, OpportunityStatusClient, OpportunityStatusView } from "./api-proxies";
import { BaseDtoService } from "./reporting-app/base-dto.service";

@Injectable({
    providedIn: "root"
})
export class OpportunityStatusService extends BaseDtoService {
    private client: IOpportunityStatusClient;

    constructor() {
        super();
        this.client = new OpportunityStatusClient();
    }

    async getAll(): Promise<OpportunityStatusView[]> {
        return await this.client.opportunityStatusGetAll();
    }

    async getByIds(opportunityIds: number[]): Promise<OpportunityStatusView[]> {
        return await this.client.opportunityStatusGetByIds(opportunityIds);
    }
}
