import { Injectable } from "@angular/core";
import { IChangeOrderStatusClient, ChangeOrderStatusDto, ChangeOrderStatusClient } from "./reporting-api-proxies";

@Injectable({
    providedIn: "root"
})
export class ChangeOrderStatusService {
    private client: IChangeOrderStatusClient;
    constructor() {
        this.client = new ChangeOrderStatusClient();
    }

    async getAll(): Promise<ChangeOrderStatusDto[]> {
        return await this.client.changeOrderStatusGetAll();
    }

    async getById(id: number): Promise<ChangeOrderStatusDto> {
        return await this.client.changeOrderStatusGetById(id);
    }

    async getByIds(ids: number[]): Promise<ChangeOrderStatusDto[]> {
        return await this.client.changeOrderStatusGetByIds(ids);
    }
}
