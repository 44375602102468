import { Component } from "@angular/core";
import { ReportWidgetComponent } from "../report-widget.component";

@Component({
    selector: "app-box-widget",
    templateUrl: "./box-widget.component.html",
})
export class BoxWidgetComponent<TService, TParameters> extends ReportWidgetComponent<TService, TParameters> {
    data: any;

    override setResultData(result: any): void {
        this.data = result;
    }
}
