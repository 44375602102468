import { Component, ComponentRef, ElementRef, Type, ViewChild, ViewContainerRef } from "@angular/core";
import { IBase } from "../../../types/report-config.interface";
import { WidgetType } from "../../../types/report-widget-type.enum";
import { IReportInstance } from "../../../types/report-instance.interface";
import { IWidgetInstance } from "../../../types/widget-instance.interface";
import { IReportWidgetConfiguration } from "../../../types/report-widget-config.interface";
import { GridWidgetComponent } from "./report-section-instance-widgets/grid-widget/grid-widget.component";
import { ChartWidgetComponent } from "./report-section-instance-widgets/chart-widget/chart-widget.component";
import { MetricsWidgetComponent } from "./report-section-instance-widgets/metrics-widget/metrics-widget.component";
import { BoxWidgetComponent } from "./report-section-instance-widgets/box-widget/box-widget.component";
import { ReportsService } from "projects/app/src/app/services/reports.service";

@Component({
    selector: "app-report-instance",
    templateUrl: "./report-instance.component.html",
    styleUrls: ["./report-instance.component.scss"]
})
export class ReportInstanceComponent<TService, TParameters> implements IReportInstance<TService, TParameters> {
    private readonly widgetRegistry = new Map<WidgetType, Type<IWidgetInstance<TService, TParameters>>>([
        [WidgetType.Box, BoxWidgetComponent],
        [WidgetType.Metrics, MetricsWidgetComponent],
        [WidgetType.Chart, ChartWidgetComponent],
        [WidgetType.Grid, GridWidgetComponent]
    ]);
    private widgets: ComponentRef<IWidgetInstance<TService, TParameters>>[];
    private serviceInstance!: TService;
    private config?: IBase<TService>;

    @ViewChild("contentTab", { static: true }) contentTab!: ElementRef;

    constructor(
        private readonly viewContainer: ViewContainerRef,
        private readonly reportsService: ReportsService
    ) {
        this.widgets = [];
    }

    async build(configuration: IBase<TService>, widgetsConfig: IReportWidgetConfiguration<TService>[]): Promise<void> {
        this.config = configuration;
        this.widgets = [];

        if (!this.config?.serviceType) {
            throw new Error("Service type is missing in the configuration.");
        }

        this.serviceInstance = this.reportsService.getService(this.config.serviceType);

        for (const widgetConfig of widgetsConfig) {
            const widgetComponentRef = this.viewContainer.createComponent<IWidgetInstance<TService, TParameters>>(
                this.getComponentFromWidgetType(widgetConfig.type)
            );

            if (!widgetComponentRef) throw new Error("Unable to instantiate the report component.");

            widgetComponentRef.instance.build(widgetConfig);
            this.widgets.push(widgetComponentRef);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const element = (widgetComponentRef.hostView as any).rootNodes[0] as HTMLElement;
            element.style.gridArea = widgetConfig.layoutTemplateArea;
            this.contentTab.nativeElement.appendChild(element);
        }
    }

    async update(parameters: TParameters): Promise<void> {
        if (!this.serviceInstance) throw new Error("Cannot update the report without a configuration.");
        for (const widget of this.widgets) {
            widget.instance.update(this.serviceInstance, parameters);
        }
    }

    private getComponentFromWidgetType(type: WidgetType): Type<IWidgetInstance<TService, TParameters>> {
        const component = this.widgetRegistry.get(type);
        if (!component) throw new Error("Widget type not recognized.");
        return component;
    }
}
