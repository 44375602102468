import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "lib-badge",
    templateUrl: "./badge.component.html",
    styleUrls: ["./badge.component.scss"]
})
export class BadgeComponent<T> {
    @Input()
    label?: string;

    @Input()
    text?: T;

    @Input()
    item?: T;

    @Input()
    key?: keyof T;

    @Input()
    removeButton?: boolean;

    @Input()
    color?: "primary" | "secondary" | "tertiary";

    @Input()
    readonly?: boolean;

    @Input()
    badgeId?: string;

    @Output()
    removeItem: EventEmitter<T> = new EventEmitter<T>();

    constructor() {
        this.removeButton = false;
        this.readonly = false;
        this.color = "primary";
    }

    remove(event: any) {
        event.stopPropagation();
        this.removeItem.emit(this.item);
    }
}
