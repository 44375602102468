<lib-section-header [title]="'Reports'" [iconName]="'reports'"></lib-section-header>

<div class="container">
    <div class="tabGroup">
        <lib-tab-view [(currentTab)]="currentTab" alignment="start" [renderAll]="true">
            <lib-tab-item *ngFor="let tab of tabConfig" [tabName]="tab.name" [bodyTemplate]="reportsTab" [headerTemplate]="headerTemplate">
                <ng-template #reportsTab>
                    <app-reports-list [name]="tab.name" [service]="tab.service" (tabCounterChange)="updateCounters($event, tab.name)"></app-reports-list>
                </ng-template>
            </lib-tab-item>
        </lib-tab-view>

        <ng-template #headerTemplate let-tab="tab">
            <lib-counter-badge [label]="tab.tabName" [count]="tabsCounters[tab.tabName] || 0"></lib-counter-badge>
        </ng-template>
    </div>
</div>
