import { Component, OnDestroy, OnInit } from "@angular/core";
import { BaseDtoService, IBaseDto } from "projects/app/src/app/services/http/clients/reporting-app/base-dto.service";
import { ReportsService } from "projects/app/src/app/services/reports.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { Subscription } from "rxjs";

@Component({
    selector: "app-multiple-dropdown-values-editor",
    templateUrl: "./multiple-dropdown-values-editor.component.html",
    styleUrls: ["./multiple-dropdown-values-editor.component.scss"]
})
export class MultipleDropdownValuesEditorComponent extends EditorComponentBase<number[] | null> implements OnInit, OnDestroy {
    items: IBaseDto[];
    selectedItems?: IBaseDto[];
    defaultText: string;
    subscription?: Subscription;

    constructor(
        private readonly baseDtoService: BaseDtoService,
        private readonly reportsService: ReportsService
    ) {
        super();
        this.items = [];
        this.defaultText = "";
    }

    async ngOnInit(): Promise<void> {
        this.items = await this.baseDtoService.getAll();
        this.subscription = this.reportsService.selectedProperty$.subscribe(async ready => {
            if (ready && this.items?.length && this.bindingProperty !== null && this.bindingProperty?.length && Array.isArray(this.bindingProperty)) {
                this.selectedItems = this.items.filter(obj => this.bindingProperty?.find(id => id === obj["id"]) !== undefined);
            }
        });
    }

    onSelectedItemsChange() {
        if (this.selectedItems?.length) {
            this.bindingProperty = this.selectedItems.map(obj => obj.id);
        } else {
            this.bindingProperty = null;
        }
        this.valueChange.emit();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
