import { AfterViewInit, Component, ComponentRef, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from "@angular/core";
import { IComponentFactory } from "../../interfaces/component-factory.interface";
import { IEditorItemConfig } from "../../interfaces/editor-item-config.interface";
import { EditorComponentBase } from "../../classes/editor-component-base";

@Component({
    selector: "lib-editor-item",
    templateUrl: "./editor-item.component.html",
    styleUrls: ["./editor-item.component.scss"]
})
export class EditorItemComponent implements AfterViewInit {
    @Input()
    configItem?: IEditorItemConfig;

    @Input()
    factory?: IComponentFactory;

    @Input()
    editorItemId?: string;

    @ViewChild("editor", { read: ViewContainerRef })
    editor?: ViewContainerRef;

    @Output()
    valueChange: EventEmitter<boolean>;

    editorInstance?: ComponentRef<EditorComponentBase<unknown>>;

    constructor() {
        this.valueChange = new EventEmitter<boolean>();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.renderComponent();
        }, 0);
    }

    private renderComponent(): void {
        if (!this.factory || !this.configItem) return;
        this.editor?.clear();

        const component = this.factory?.getComponent(this.configItem.componentId);

        this.editorInstance = this.editor?.createComponent(component);
        this.editorInstance?.instance.initialize({
            componentId: this.configItem.componentId,
            bindContext: this.configItem.bindContext,
            fieldName: this.configItem.fieldName,
            additionalInfo: this.configItem.additionalInfo,
            validations: this.configItem.validations,
            label: this.configItem.label,
            entityId: this.configItem.entityId,
            isReadOnly: this.configItem.isReadOnly
        });
        this.editorInstance?.instance.valueChange.subscribe(() => this.valueChange.emit());
    }

    validate(): boolean {
        if (!this.editorInstance) return false;
        return this.editorInstance?.instance.validate();
    }

    clearValidationErrors(): void {
        this.editorInstance?.instance.clearValidationErrors();
    }
}
