<div class="inputContainer" [id]="customInputWithIconId | idGenerator: 'ngx-customInputWithIcon-'">
    <div class="inputWrapper">
        <div *ngIf="svgIcon" class="iconContainer" [innerHTML]="svgIcon" [ngClass]="{ 'disabled': !value }"></div>
        <input
            [type]="type"
            [value]="value ?? null"
            (input)="handleInputValueChange($event)"
            [attr.placeholder]="placeholder"
            [attr.min]="type === 'number' ? min : null"
            [attr.max]="type === 'number' ? max : null"
            [libRestrictNegativeNumbers]="restrictNegativeNumbers"
        />
    </div>
    <div *ngIf="errorMessage" class="errorMessage">{{ value && errorMessage }}</div>
</div>
