<div class="cardsContainer">
    <lib-generic-card [title]="'Main Info'" [highlight]="true" [factory]="factory" [configItems]="mainInfoCardConfig" #mainInfoCard> </lib-generic-card>

    <lib-generic-card [title]="'Main Contact'" [factory]="factory" [configItems]="mainContactCardConfig"> </lib-generic-card>

    <lib-generic-card [title]="'Main Account'" [factory]="factory" [configItems]="mainAccountCardConfig"> </lib-generic-card>

    <lib-generic-card [title]="'Main Office'" [factory]="factory" [configItems]="mainOfficeCardConfig"> </lib-generic-card>

    <lib-generic-card *ngIf="customFieldsConfig && customFieldsConfig.length" [title]="'Custom Info'" [factory]="factory" [configItems]="customFieldsConfig">
    </lib-generic-card>
</div>
