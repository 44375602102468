import { Component, OnDestroy, OnInit } from "@angular/core";
import { BuildingBuildingClassView, BuildingClassView } from "projects/app/src/app/services/http/clients/api-proxies";
import { BuildingClassService } from "projects/app/src/app/services/http/clients/building-class.service";
import { ReportsService } from "projects/app/src/app/services/reports.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { Subscription } from "rxjs";

@Component({
    selector: "app-reporting-building-class-editor",
    templateUrl: "./reporting-building-class-editor.component.html",
    styleUrls: ["./reporting-building-class-editor.component.scss"]
})
export class ReportingBuildingClassEditorComponent extends EditorComponentBase<BuildingBuildingClassView[] | number | null> implements OnInit, OnDestroy {
    buildingClassItems?: BuildingClassView[];
    selectedBuildingClass?: BuildingClassView;
    selectedBuildingClasses?: BuildingClassView[];
    subscription?: Subscription;

    constructor(
        private readonly buildingClassService: BuildingClassService,
        private reportsService: ReportsService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.buildingClassItems = await this.buildingClassService.getAll();

        if (this.bindingProperty !== null) {
            this.subscription = this.reportsService.selectedProperty$.subscribe(async ready => {
                if (ready) {
                    this.setSelectedBuildingClassOnInit();
                }
            });
        }
    }

    setSelectedBuildingClassOnInit() {
        if (this.buildingClassItems?.length) {
            if (Array.isArray(this.bindingProperty) && this.bindingProperty.length) {
                this.selectedBuildingClasses = this.buildingClassItems.filter(
                    x => (this.bindingProperty as BuildingBuildingClassView[])?.some(obj2 => x["id"] === obj2["buildingClassId"])
                );
            } else {
                this.selectedBuildingClass = this.buildingClassItems.find(x => x.id === this.bindingProperty);
            }
        }
    }

    onSelectedProductTypeChange() {
        if (this.selectedBuildingClasses)
            this.bindingProperty = this.selectedBuildingClasses.map(x => ({ buildingClassId: x.id }) as BuildingBuildingClassView);
    }

    onSelectBuildingClass() {
        this.bindingProperty = this.selectedBuildingClass?.id ?? null;
        this.valueChange.emit();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
