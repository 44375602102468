import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { OpportunityUserView } from "projects/app/src/app/services/http/clients/api-proxies";
import { ModalComponentBase } from "projects/ngx-lib/src/lib/classes/modal-component-base";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { OpportunityService } from "projects/app/src/app/services/http/clients/opportunity.service";
import { EditorItemComponent, SnackbarNotificationService, ObjectExtensionsService } from "projects/ngx-lib/src/public-api";
import { ValidatorType } from "projects/ngx-lib/src/lib/enums/validator-type";

interface IModalEditOpportunityUserParameters {
    user: OpportunityUserView;
}

@Component({
    selector: "app-modal-edit-opportunity-team-member",
    templateUrl: "./modal-edit-opportunity-team-member.component.html",
    styleUrls: ["./modal-edit-opportunity-team-member.component.scss"]
})
export class ModalEditOpportunityTeamMemberComponent extends ModalComponentBase<IModalEditOpportunityUserParameters, OpportunityUserView> implements OnInit {
    protected override parameters!: IModalEditOpportunityUserParameters;
    userFullName!: string;

    teamMember!: OpportunityUserView;

    factory: AppEditorFactory;

    configItems: IEditorItemConfig[];

    @ViewChildren("editorItem")
    editors?: QueryList<EditorItemComponent>;

    userInitialState!: OpportunityUserView;

    isLoading: boolean;

    constructor(
        private readonly opportunityService: OpportunityService,
        private readonly awaiter: AwaiterService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly objectExtensionService: ObjectExtensionsService
    ) {
        super();
        this.factory = new AppEditorFactory();
        this.configItems = [];
        this.userFullName = "";
        this.isLoading = false;
    }

    ngOnInit(): void {
        this.userFullName = this.parameters?.user.userFullName ?? "";
        this.teamMember = this.objectExtensionService.clone(this.parameters?.user) as OpportunityUserView;
        this.userInitialState = this.objectExtensionService.clone(this.parameters.user) as OpportunityUserView;
        this.configItems = [
            {
                label: "Email",
                componentId: EditorType.textInput,
                fieldName: "email",
                bindContext: this.teamMember,
                isReadOnly: true
            },
            {
                label: "Opportunity Roles",
                componentId: EditorType.entity,
                fieldName: "opportunityUserUserRoles",
                entityId: "opportunityUserRole",
                bindContext: this.teamMember,
                additionalInfo: {
                    itemDisplayKey: "description"
                }
            },
            {
                label: "Percentage of Commission",
                componentId: EditorType.numberInput,
                fieldName: "commissionPercentage",
                bindContext: this.teamMember,
                validations: [{ type: ValidatorType.number, args: { maxValue: 9999, customMessage: "*Input exceeds the maximum value of 9999%" } }]
            }
        ];
    }

    private validate(): boolean {
        return this.editors?.map(editorItem => editorItem.validate()).every(editorItem => editorItem) ?? false;
    }

    async saveUser(): Promise<void> {
        if (!this.validate()) return;

        await this.awaiter.awaitAction(
            "Saving User...",
            async () => {
                const userSaved = await this.opportunityService.saveUser(this.teamMember);

                if (userSaved) {
                    this.snackbarNotificationService.success("Team member saved successfully.");
                    this.close(userSaved);
                }
            },
            loading => (this.isLoading = loading)
        );
    }

    onClose() {
        this.teamMember = this.userInitialState;
        this.close(this.userInitialState);
    }
}
